<div *ngIf="isBlockedPage" class="atp-preloader-container"
  [class.atp-preloader-container_transparent]="!isPreloaderShow">
  <mat-spinner *ngIf="isPreloaderShow"></mat-spinner>
</div>

<div *ngIf="data"
  [class]="'atp-dynamic-form__container' + (data.settings.baseGroupName || data.type ? ' atp-dynamic-form__container_' + (data.settings.baseGroupName ? data.settings.baseGroupName.toLowerCase() : '') + (data.settings.baseGroupName && data.type ? '-' : '') + (data.type ? data.type.toLowerCase() : '') : '')">

  <form class="atp-dynamic-form__form" [formGroup]="form" (ngSubmit)="submit()" novalidate>
    <atp-title #topSection atpSection *ngIf="!annotations?.notHeader" class="atp-dynamic-form__title"
      [text]="data.info.title" notSetInHeader="true">
    </atp-title>

    <!--[style.maxHeight]="platform.TRIDENT || platform.SAFARI ? 'auto' : contentMaxHeight"-->
    <div class="atp-dynamic-form__content"
      [class.atp-dynamic-form__content_full-height]="fullHeightContent"
    >
      <ng-template [ngTemplateOutlet]="formGroup" [ngTemplateOutletContext]="{item: {items: items}}">
      </ng-template>
    </div>

    <div #bottomSection atpSection class="atp-dynamic-form__actions">
      <button mat-flat-button class="atp-dynamic-form__actions-button atp-dynamic-form__actions-button_submit"
        *ngIf="!data.settings.confirmButtonIsInvisible" color="primary" type="submit">{{!data.model ?
        (data.settings.confirmButtonAddName ? data.settings.confirmButtonAddName :
        'Добавить') : (data.settings.confirmButtonEditName ? data.settings.confirmButtonEditName :
        'Сохранить')}}</button>
      <button mat-stroked-button class="atp-dynamic-form__actions-button atp-dynamic-form__actions-button_close"
        *ngIf="!data.settings.closedButtonIsInvisible" type="button" (click)="close()">{{data.settings.closedButtonName
        ? data.settings.closedButtonName : 'Отменить'}}</button>
    </div>
  </form>
</div>

<ng-template #formGroup let-item="item">
  <ng-container *ngFor="let groupItem of item.items; let i = index">
    <ng-container *ngIf="!groupItem.type; else elseFormGroup">
      <ng-container *ngIf="!groupItem.isHidden">
        <ng-container *ngIf="groupItem.componentBefore && getEntryComponent(groupItem.componentBefore)"
          [atpCustomGroupInjector]="getEntryComponent(groupItem.componentBefore)" [name]="groupItem.name"
          [display]="groupItem.display" [parent]="this">
        </ng-container>

        <ng-template [ngTemplateOutlet]="getTemplateRef({
              Input: inputFormControl,
              Autocomplete: autocompleteFormControl,
              MultipleAutocomplete: multipleAutocompleteFormControl,
              Password: passwordFormControl,
              Textarea: textareaFormControl,
              Select: selectFormControl,
              MultipleSelect: multipleSelectFormControl,
              Boolean: booleanFormControl,
              MultipleFile: multipleFileFormControl,
              Custom: customFormControl,
              RangeDateTime: rangeDateTimeFormControl,
              Editor: editorFormControl,
              Display: displayFormControl,
              Schedule: scheduleFormControll
              },
              groupItem.controlType)" [ngTemplateOutletContext]="{item: groupItem}">
        </ng-template>

        <ng-container *ngIf="groupItem.componentAfter && getEntryComponent(groupItem.componentAfter)"
          [atpCustomGroupInjector]="getEntryComponent(groupItem.componentAfter)" [name]="groupItem.name"
          [display]="groupItem.display" [parent]="this">
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #elseFormGroup>
      <div @OpenClose *ngIf="groupItem.type == 'Group' && groupItem.isVisible"
        [ngClass]="['atp-dynamic-form__group', 'atp-dynamic-form__group_' + groupItem.name.toLowerCase()]"
        [class.atp-dynamic-form__group_no-heading]="!groupItem.display">
        <fieldset class="atp-dynamic-form__group-fieldset"
          [class.atp-dynamic-form__group-fieldset_no-heading]="!groupItem.display">
          <div class="atp-dynamic-form__group-fieldset-container">
            <legend class="atp-dynamic-form__group-fieldset-label" *ngIf="groupItem.display">{{groupItem.display}}
            </legend>
            <ng-template [ngTemplateOutlet]="formGroup" [ngTemplateOutletContext]="{item: groupItem}">
            </ng-template>
          </div>
        </fieldset>
      </div>
      <mat-tab-group *ngIf="groupItem.type == 'Tabs'" class="atp-dynamic-form__tab-group"
        [ngClass]="'atp-dynamic-form__tab-group_' + groupItem.name.toLowerCase()"
        (selectedTabChange)="onMatTabChangeEvent($event)">
        <ng-container *ngFor="let tab of groupItem.items">
          <mat-tab #tabItem *ngIf="tab.type == 'TabItem'" [label]="tab">
            <div class="atp-dynamic-form__tab-item-content"
              [ngClass]="'atp-dynamic-form__tab-item-content_' + tab.name.toLowerCase()">
              <ng-template [ngTemplateOutlet]="formGroup" [ngTemplateOutletContext]="{item: tab}">
              </ng-template>
            </div>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #inputFormControl let-item="item">
  <mat-form-field floatLabel="always" class="atp-dynamic-form__form-field atp-dynamic-form__form-field_input"
    [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <ng-container *ngIf="item.name=='phoneNumber'; else elseBlock">
      <atp-input-phone
        [formControl]="allControls[item.name]"
        [required]="checkItemRequired(item)"
        requred
      ></atp-input-phone>
    </ng-container>
    <ng-template #elseBlock>
      <input *ngIf="masks && masks[item.name]; else masksElseBlock" matInput [required]="checkItemRequired(item)"
        [textMask]="{mask: masks[item.name]}" [formControl]="allControls[item.name]"
        [name]="(data?.baseGroupName ? data.baseGroupName + '-' : '') + data.type + '_' + item.name"
        [type]="item.controlType == 'Number' ? 'number' : 'text'">
      <ng-template #masksElseBlock>
        <input matInput [required]="checkItemRequired(item)" [formControl]="allControls[item.name]"
          [name]="(data?.baseGroupName ? data.baseGroupName + '-' : '') + data.type + '_' + item.name"
          [type]="item.controlType == 'Number' ? 'number' : 'text'">
      </ng-template>
    </ng-template>
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #autocompleteFormControl let-item="item">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_autocomplete"
    [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <input #autocompleteElement matInput [required]="checkItemRequired(item)" [formControl]="allControls[item.name]"
      [name]="(data?.baseGroupName ? data.baseGroupName + '-' : '') + data.type + '_' + item.name"
      [matAutocomplete]="auto" (blur)="autocompleteBlur(item.name)">
    <button *ngIf="autocompleteElement.value" matSuffix mat-icon-button type="button"
      (click)="form.controls[item.name].setValue('')">
      <mat-icon color="warn">close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="autocompletes[item.name] = []">
      <mat-option *ngFor="let option of autocompletes[item.name]" [value]="option">
        {{option.value}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<ng-template #multipleAutocompleteFormControl let-item="item">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_multiple-autocomplete"
    [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <mat-chip-list #chipList [formControl]="allControls[item.name]">
      <mat-chip *ngFor="let option of form.controls[item.name].value" [selectable]="false"
        (removed)="multipleAutocompleteRemove(item.name, option)">
        {{option.value}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input #multipleAutocompleteElement [formControl]="multipleAutocompleteForm.controls[item.name]"
        [name]="(data?.baseGroupName ? data.baseGroupName + '-' : '') + data.type + '_' + item.name"
        [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="[ENTER, COMMA]"
        [matChipInputAddOnBlur]="addOnBlur">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete"
      (optionSelected)="multipleAutocompleteSelected(item.name, $event); multipleAutocompleteElement.value = ''">
      <mat-option *ngFor="let option of autocompletes[item.name]" [value]="option">
        {{option.value}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #passwordFormControl let-item="item">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_password"
    [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <input matInput [required]="checkItemRequired(item)" [formControl]="allControls[item.name]"
      [name]="(data?.baseGroupName ? data.baseGroupName + '-' : '') + data.type + '_' + item.name" type="password">
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #textareaFormControl let-item="item">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_textarea"
    [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <textarea matInput [formControl]="allControls[item.name]"
      [name]="(data?.baseGroupName ? data.baseGroupName + '-' : '') + data.type + '_' + item.name"
      [required]="checkItemRequired(item)"></textarea>
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #editorFormControl let-item="item">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_textarea"
                  [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <atp-editor
      [formControl]="allControls[item.name]"
      [name]="(data?.baseGroupName ? data.baseGroupName + '-' : '') + data.type + '_' + item.name"
      [required]="checkItemRequired(item)"
    ></atp-editor>
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #selectFormControl let-item="item">
  <div
    style="display: flex" >
    <mat-form-field
      class="atp-dynamic-form__form-field atp-dynamic-form__form-field_select"
      [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
      <mat-label>{{item.display}}</mat-label>
      <mat-select [formControl]="allControls[item.name]" [required]="checkItemRequired(item)">
        <mat-option *ngFor="let option of item.selectItems" [value]="option.id">
          {{option.value}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
    </mat-form-field>
    <atp-btn-sections
      [multiple]="false"
      [value]="allControls[item.name].value"
      [tree] = "item.tree"
      [source]="item.selectItems"
      (change)="allControls[item.name].patchValue($event);"
      *ngIf="item.tree"
    ></atp-btn-sections>
  </div>
</ng-template>

<ng-template #multipleSelectFormControl let-item="item">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_multiple-select"
    [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <mat-select [formControl]="allControls[item.name]" [required]="checkItemRequired(item)" multiple>
      <mat-option *ngFor="let option of item.selectItems" [value]="option.id">
        {{option.value}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #booleanFormControl let-item="item">
  <div class="atp-dynamic-form__form-field atp-dynamic-form__form-field_checkbox"
    [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()">
    <mat-checkbox color="primary" [formControl]="allControls[item.name]"
      [name]="(data?.baseGroupName ? data.baseGroupName + '-' : '') + data.type + '_' + item.name">{{item.display}}
    </mat-checkbox>
  </div>
</ng-template>

<ng-template #multipleFileFormControl let-item="item">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_multiple-file"
    [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <atp-upload-file [formControl]="allControls[item.name]" [maxCount]="item.params[2]" [display]="item.display"
      [required]="checkItemRequired(item)" [formatsAllowed]="item.params[0]"
      [multiple]="item.controlType.startsWith('Multiple')" [maxSize]="item.params[1]">
    </atp-upload-file>
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #rangeDateTimeFormControl let-item="item">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_range-date-time"
    [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <atp-range-date-time-picker [type]="item.controlType" [formControl]="allControls[item.name]"
      [required]="checkItemRequired(item)">
    </atp-range-date-time-picker>
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #scheduleFormControll let-item="item">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_range-date-time"
                  [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <atp-schedule
      [formControl]="allControls[item.name]"
      [required]="checkItemRequired(item)">
    </atp-schedule>
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>

<ng-template #customFormControl let-item="item">
  <div class="atp-dynamic-form__form-field"
       [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()">
      <ng-container
        *ngIf="getEntryComponent(item.params[0])"
        [atpCustomGroupInjector]="getEntryComponent(item.params[0])"
        [reactiveFormGroup]="form"
        [reactiveFormControl]="allControls[item.name]"
        [name]="item.name"
        [display]="item.display"
        [formatParams]="item.params.slice(1)"
        [defaultValue]="item.defaultValue"
        [model]="data.model ? data.model[item.name] : null"
        [correctResult]="correctResults[item.name]"
        [handbooks]="item.selectItems?item.selectItems:null"
        [parent]="this">
      </ng-container>
  </div>
</ng-template>

<ng-template #displayFormControl let-item="item">
  <mat-form-field floatLabel="always" class="atp-dynamic-form__form-field atp-dynamic-form__form-field_input"
                  [ngClass]="'atp-dynamic-form__form-field_' + item.name.toLowerCase()" appearance="outline">
    <mat-label>{{item.display}}</mat-label>
    <input matInput [required]="checkItemRequired(item)" [formControl]="allControls[item.name]"
           [name]="(data?.baseGroupName ? data.baseGroupName + '-' : '') + data.type + '_' + item.name"
           [type]="item.controlType == 'Number' ? 'number' : 'text'">
    <mat-error *ngIf="getErrorMessage(item)">{{getErrorMessage(item)}}</mat-error>
  </mat-form-field>
</ng-template>
