import { Component, Input, OnInit, forwardRef, Optional, Self } from '@angular/core';
import { atpAnimations } from 'src/app/atp-core/atp-animations';
import { AtpValidators, IAtpSelectItem } from 'src/app/atp-core/atp-core';
import { AtpDateTimeService } from 'src/app/atp-core/components/atp-range-date-time-picker/atp-date-time.service';
import { AbstractControl, FormControl, FormGroup, NgControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

export interface IWorkingDays {
  daysOfWeek: IAtpSelectItem[];
  weekendIds: number[];
  workTime: Date[];
  exceptionDayTypes: IAtpSelectItem[];
  exceptions: IWorkingException[];
}

export interface IWorkingException {
  day: Date;
  workTime: Date[];
  typeId: number;
  internalId?: number;
}

@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  host: {
    'class': 'working-days',
  },
  animations: [
    atpAnimations.OpenClose
  ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: ScheduleComponent
    }
  ],
})
export class ScheduleComponent implements OnInit {

  constructor(private _dateTimeService: AtpDateTimeService, @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  data: IWorkingDays;
  formGroup: FormGroup;
  exceptionsFormGroup: FormGroup;
  isDisabled: boolean = true;
  onTouched: any = (e) => { };

  onChange: any = (e) => { };

  writeValue(obj: any): void {
    this.data = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  private getExceptionGroup(id: number, model: IWorkingException) {
    const result = new FormGroup({
      typeId: new FormControl(model.typeId ?? this.data?.exceptionDayTypes[0].id),
      day: new FormControl(new Date(model.day), this.exceptionDayValidator(id.toString())),
      workTime: new FormControl(model.workTime.map(x => new Date(x)), AtpValidators.rangeDateTime(this._dateTimeService, 'RangeTime'))
    });

    result.valueChanges.subscribe(() => {
      if (!this._updateDaysFormControl) {
        this.updateDaysFormControl();
      }
    });

    return result;
  }

  ngOnInit(): void {

  }
  initData() {
    const exceptions = {};
    for (let i = 0; i < this.data?.exceptions.length; i++) {
      this.data.exceptions[i].internalId = this._exceptionsCounter;
      exceptions[i] = this.getExceptionGroup(this._exceptionsCounter, this.data.exceptions[i]);
      this._exceptionsCounter++;
    }

    this.exceptionsFormGroup = new FormGroup(exceptions);

    this.formGroup = new FormGroup({
      weekendIds: new FormControl(this.data?.weekendIds, AtpValidators.required),
      workTime: new FormControl(this.data?.workTime.map(x => new Date(x)), AtpValidators.rangeDateTime(this._dateTimeService, 'RangeTime')),
      exceptions: this.exceptionsFormGroup
    });
    this.formGroup.valueChanges.subscribe(e => {
      this.onChange(e);
    })
  }
  ngAfterContentInit() {
    this.initData();
  }
  private _exceptionsCounter = 0;

  add() {
    const newException: IWorkingException = { day: new Date(Date.now()), workTime: [new Date(Date.now()), new Date(Date.now())], typeId: this.data?.exceptionDayTypes[0].id, internalId: this._exceptionsCounter };
    this.exceptionsFormGroup.addControl(newException.internalId.toString(), this.getExceptionGroup(this._exceptionsCounter, newException));
    this.data?.exceptions.push(newException);
    this._exceptionsCounter++;

    this.updateDaysFormControl();
  }

  remove(index: number) {
    this.exceptionsFormGroup.removeControl(this.data?.exceptions[index].internalId.toString());
    this.data?.exceptions.splice(index, 1);

    this._updateDaysFormControl = true;

    this.updateDaysFormControl();
  }

  private _updateDaysFormControl = false;
  updateDaysFormControl() {
    this._updateDaysFormControl = true;
    setTimeout(() => {
      for (const key in this.exceptionsFormGroup.controls) {
        const formGroup = <FormGroup>this.exceptionsFormGroup.controls[key];
        formGroup.controls.day.setValue(formGroup.controls.day.value);
      }

      setTimeout(() => {
        this._updateDaysFormControl = false;
      });
    });
  }

  get errorMessageWeekendIds(): string {
    let result = '';

    if (this.formGroup.controls.weekendIds.errors?.required) {
      result = 'поле обязательно для заполнения';
    }

    return result;
  }

  getErrorMessageWorkTime(id?: string): string {
    let result = '';

    if (id ? (<FormGroup>this.exceptionsFormGroup.controls[id]).controls.workTime.errors?.rangedatetime : this.formGroup.controls.workTime.errors?.rangedatetime) {
      result = 'введено неверно.';
    }

    return result;
  }

  getErrorMessageExceptionDay(id?: string): string {
    let result = '';

    if ((<FormGroup>this.exceptionsFormGroup.controls[id.toString()]).controls.day.errors?.exceptionday) {
      result = 'должна быть уникальной.';
    }

    return result;
  }

  exceptionDayValidator(id: string): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      if (!this.exceptionsFormGroup?.controls[id]) {
        return null;
      }

      const value = this._dateTimeService.dateToFormatString(<Date>(<FormGroup>this.exceptionsFormGroup.controls[id]).controls.day.value, "Date")
      const ids = Object.keys(this.exceptionsFormGroup.controls).filter(x => x != id);

      let result = false;
      for (const i of ids) {
        if (this._dateTimeService.dateToFormatString(<Date>(<FormGroup>this.exceptionsFormGroup.controls[i]).controls.day.value, "Date") == value) {
          result = true;
          break;
        }
      }

      return result ? { exceptionday: true } : null;
    }
  }

}
