import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { AtpTableActionsMenuComponent, AtpTableActionsSubmenuComponent } from './atp-table-actions-menu.component';

@NgModule({
  declarations: [
    AtpTableActionsMenuComponent,
    AtpTableActionsSubmenuComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule
  ],
  exports: [
    AtpTableActionsMenuComponent
  ]
})
export class AtpTableActionsMenuModule { }
