import { animate, state, style, transition, trigger, AnimationTriggerMetadata } from '@angular/animations';

export const atpAnimations: {
  readonly OpenClose: AnimationTriggerMetadata
} = {
  OpenClose: trigger('OpenClose', [
    transition(':enter', [
      style({ height: '0' }),
      animate('.3s')
    ]),
    transition(':leave', [
      animate('.2s ease-out', style({ height: '0' }))
    ])
  ])
};

export const atpDialogAnimations: {
  readonly dialogContainer: AnimationTriggerMetadata;
} = {
  dialogContainer: trigger('dialogContainer', [
    state('void, exit', style({ transform: 'translate(120%, 0)' })),
    state('enter', style({ transform: 'none' })),
    transition('* => enter', animate('400ms cubic-bezier(0, 0, 0.2, 1)',
      style({ transform: 'none' }))),
    transition('* => void, * => exit',
      animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translate(120%, 0)' }))),
  ]),
};