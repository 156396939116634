<ng-container *ngFor="let item of controls">

  <ng-template [ngTemplateOutlet]="getTemplateRef({
        Boolean: booleanFormControl,
        String: stringFormControl,
        DateTime: dateTimeFormControl,
        Select: selectFormControl,
        MultipleSelect: multipleSelectFormControl,
        Autocomplete: autocompleteFormControl,
        NumberRange: numberRangeFormControl
      }, item.type)"
      [ngTemplateOutletContext]="{item: item}">
  </ng-template>

</ng-container>

<ng-template #booleanFormControl let-item="item">
  <mat-form-field [formGroup]="formGroup"
                  [class]="'atp-filters-panel__form-field atp-filters-panel__form-field_' + item.name.toLowerCase()"
                  appearance="outline">
    <mat-label>{{item.display ? item.display : item.name}}</mat-label>
    <mat-select [formControlName]="item.name">
      <mat-option>Все</mat-option>
      <mat-option *ngFor="let option of item.options" [value]="option">
        {{option.value}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #stringFormControl let-item="item">
  <ng-container [formGroup]="formGroup">
    <mat-form-field *ngIf="item.mask; else masksElseBlock"
                    [class]="'atp-filters-panel__form-field atp-filters-panel__form-field_' + item.name.toLowerCase()"
                    appearance="outline">
      <mat-label>{{item.display ? item.display : item.name}}</mat-label>
      <input #stringInputElement matInput [textMask]="{mask: item.mask, guide: false, keepCharPositions: true}"
             [formControlName]="item.name" [name]="item.name">
      <button *ngIf="stringInputElement.value" matSuffix mat-icon-button type="button"
              (click)="formGroup.controls[item.name].setValue('')">
        <mat-icon color="warn">close</mat-icon>
      </button>
    </mat-form-field>
    <ng-template #masksElseBlock>
      <mat-form-field [class]="'atp-filters-panel__form-field atp-filters-panel__form-field_' + item.name.toLowerCase()"
                      appearance="outline">
        <mat-label>{{item.display ? item.display : item.name}}</mat-label>
        <input #stringInputElement matInput [formControlName]="item.name" [name]="item.name">
        <button *ngIf="stringInputElement.value" matSuffix mat-icon-button type="button"
                (click)="formGroup.controls[item.name].setValue('')">
          <mat-icon color="warn">close</mat-icon>
        </button>
      </mat-form-field>
    </ng-template>
  </ng-container>

</ng-template>

<ng-template #dateTimeFormControl let-item="item">
  <mat-form-field [formGroup]="formGroup"
                  [class]="'atp-filters-panel__form-field atp-filters-panel__form-field_datetime atp-filters-panel__form-field_' + item.name.toLowerCase()"
                  appearance="outline">
    <mat-label>{{item.display ? item.display : item.name}}</mat-label>
    <atp-range-date-time-picker type="RangeDateTime" isFilter="true" [formControlName]="item.name">
    </atp-range-date-time-picker>
    <button matSuffix mat-icon-button (click)="formGroup.controls[item.name].setValue([null, null])" type="button">
      <mat-icon color="warn">close</mat-icon>
    </button>
  </mat-form-field>
</ng-template>

<ng-template #selectFormControl let-item="item">
  <mat-form-field [formGroup]="formGroup"
                  [class]="'atp-filters-panel__form-field atp-filters-panel__form-field_' + item.name.toLowerCase()"
                  appearance="outline">
    <mat-label>{{item.display ? item.display : item.name}}</mat-label>
    <mat-select [formControlName]="item.name">
      <mat-option>Все</mat-option>
      <mat-option *ngFor="let option of item.options" [value]="option">
        {{option.value}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #multipleSelectFormControl let-item="item">
  <div
    [formGroup]="formGroup"
    style="display: flex" >
    <mat-form-field
      [class]="'atp-filters-panel__form-field atp-filters-panel__form-field_' + item.name.toLowerCase()"
      appearance="outline"
    >
      <mat-label>{{item.display ? item.display : item.name}}</mat-label>
      <mat-select
        multiple
        [formControlName]="item.name"
      >
        <mat-option *ngFor="let option of item.options" [value]="option">
          {{option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <atp-btn-sections
      [multiple]="true"
      [value]="formGroup.controls[item.name].value"
      [tree] = "item.tree"
      [source]="item.options"
      (change)="formGroup.controls[item.name].patchValue($event);"
      *ngIf="item.tree"
    ></atp-btn-sections>
  </div>
</ng-template>

<ng-template #autocompleteFormControl let-item="item">
  <mat-form-field
    [formGroup]="formGroup"
    [class]="'atp-filters-panel__form-field atp-filters-panel__form-field_' + item.name.toLowerCase()"
    appearance="outline"
  >
    <mat-label>{{item.display ? item.display : item.name}}</mat-label>
    <input
        #autocompleteElement
        matInput
        [formControlName]="item.name"
        [name]="item.name"
        [matAutocomplete]="auto"
        (blur)="autocompleteBlur(item.name)"
    >
    <button
      *ngIf="autocompleteElement.value"
      matSuffix
      mat-icon-button type="button"
      (click)="formGroup.controls[item.name].setValue('')"
    >
      <mat-icon color="warn">close</mat-icon>
    </button>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="autocompletes[item.name] = []">
      <mat-option *ngFor="let option of autocompletes[item.name]" [value]="option">
        {{option.value}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-template>

<ng-template #numberRangeFormControl let-item="item">
  <mat-form-field
    floatLabel="always"
    [formGroup]="formGroup"
    [class]="'atp-filters-panel__form-field atp-filters-panel__form-field_datetime atp-filters-panel__form-field_' + item.name.toLowerCase()"
    appearance="outline"
  >
    <mat-label>
      {{item.display ? item.display : item.name}}
    </mat-label>

    <atp-input-number-range
      [formControlName]="item.name"
    >
    </atp-input-number-range>

    <button matSuffix mat-icon-button (click)="formGroup.controls[item.name].setValue([null, null])" type="button">
      <mat-icon color="warn">close</mat-icon>
    </button>
  </mat-form-field>
</ng-template>
