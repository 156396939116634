import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AtpFiltersPanelItem } from "./models";
import {IAtpSelectItem} from '../../atp-core';

@Component({
  selector: 'atp-filters-panel-controls',
  templateUrl: './atp-filters-panel-controls.component.html',
  host: {
    class: 'atp-filters-panel-controls'
  }
})
export class AtpFiltersPanelControlsComponent implements OnInit {

  constructor() { }

  @Input() controls: AtpFiltersPanelItem<any>[] = [];
  @Input() autocompletes: { [key: string]: IAtpSelectItem[] } = {};
  @Input() formGroup: FormGroup;

  ngOnInit(): void {}

  getTemplateRef(templateRefs: { [key: string]: TemplateRef<any> }, controlType: string): TemplateRef<any> {
    return templateRefs[controlType];
  }

  autocompleteBlur(name: string): void {
    if (typeof (this.formGroup.controls[name].value) === 'string') {
      this.formGroup.controls[name].setValue('');
    }
  }
}
