import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FormatPipe } from '../pipes/format.pipe';
import { DynamicTableColumnTypeDirective, AtpDynamicTableFullCrudTreeLink } from './dynamic-table-column-type.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { AtpUploadFileModule } from '../components/atp-upload-file/atp-upload-file.module';
import { PortalModule } from '@angular/cdk/portal';
import { AtpAddEditPopupDynamicTableFullCrud } from './atp-dynamic-form/atp-add-edit-popup-dynamic-table-full-crud';
import { AtpAddEditDynamicTableFullCrud } from './atp-dynamic-form/atp-add-edit-dynamic-table-full-crud';
import { AtpCustomGroupInjectorDirective } from './atp-dynamic-form/atp-custom-component-injector.directive';
import { AtpMovementTreeComponent } from './atp-movement-tree/atp-movement-tree.component';
import { RouterModule } from '@angular/router';
import { AtpRangeDateTimePickerModule } from '../components/atp-range-date-time-picker/atp-range-date-time-picker.module';
import { AtpActionsPopupModule } from '../components/atp-actions-popup/atp-actions-popup.module';
import { AtpTableActionsMenuModule } from '../components/atp-table-actions-menu/atp-table-actions-menu.module';
import { AtpTitleModule } from '../components/atp-title/atp-title.module';
import { AtpSectionModule } from '../components/atp-section/atp-section.module';
import { PlatformModule } from '@angular/cdk/platform';
import { AtpHistoryComponent } from './atp-history/atp-history.component';
import { AtpHistoryItemDetailComponent } from './atp-history-item-detail/atp-history-item-detail.component';
import { AtpFiltersPanelModule } from './atp-filters-panel/atp-filters-panel.module';
import { AtpEditorModule } from '../components/atp-editor/atp-editor.module';
import { AtpBtnSectionsModule} from '../components/atp-btn-sections/atp-btn-sections.module';
import { AtpInputPhoneModule } from '../components/atp-input-phone/atp-input-phone.module';

import {AtpScheduleModule} from '../components/atp-schedule/atp-schedule.module';
import {AtpInputNumberRangeModule} from '../components/atp-input-number-range/atp-input-number-range.module';

@NgModule({
  declarations: [
    FormatPipe,

    DynamicTableColumnTypeDirective,
    AtpAddEditPopupDynamicTableFullCrud,
    AtpAddEditDynamicTableFullCrud,
    AtpCustomGroupInjectorDirective,
    AtpMovementTreeComponent,
    AtpDynamicTableFullCrudTreeLink,
    AtpHistoryComponent,
    AtpHistoryItemDetailComponent
  ],
  imports: [
    PlatformModule,
    RouterModule,
    PortalModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTreeModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatTableModule,
    MatSliderModule,
    TextMaskModule,
    FormsModule,

    // Компоненты
    AtpTitleModule,
    AtpTableActionsMenuModule,
    AtpActionsPopupModule,
    AtpUploadFileModule,
    AtpRangeDateTimePickerModule,
    AtpSectionModule,
    AtpFiltersPanelModule,
    AtpEditorModule,
    AtpBtnSectionsModule,
    AtpInputPhoneModule,
    AtpScheduleModule,
    AtpInputNumberRangeModule
  ],
  exports: [
    FormatPipe,

    DynamicTableColumnTypeDirective,

    AtpAddEditPopupDynamicTableFullCrud,
    AtpAddEditDynamicTableFullCrud,
    AtpCustomGroupInjectorDirective,
    AtpHistoryComponent,
    AtpHistoryItemDetailComponent,

    // Компоненты
    AtpTitleModule,
    AtpTableActionsMenuModule,
    AtpActionsPopupModule,
    AtpUploadFileModule,
    AtpRangeDateTimePickerModule,
    AtpSectionModule,
    AtpFiltersPanelModule,
    AtpEditorModule,
    AtpBtnSectionsModule,
    AtpInputPhoneModule,
    AtpScheduleModule,
    AtpInputNumberRangeModule
  ]
})
export class AtpDynamicTableFullCrudModule { }
