<div class="atp-actions-popup__title">{{data.title}}</div>
<div class="atp-actions-popup__content">
  <ng-container *ngIf="message">
    <div [innerHTML]="data.message"></div>
  </ng-container>
  <ng-container *ngIf="!message">
    <ul>
      <ng-container *ngIf="files; else elseBlockV">
        <li *ngFor="let file of files">
          <a href="javascript:void(0)" (click)="openFile(file.id)">{{file.name}}</a>
        </li>
      </ng-container>
      <ng-template #elseBlockV>
      <li *ngFor="let mess of messages">{{mess}}</li>
      </ng-template>
    </ul>
  </ng-container>
</div>
<div class="atp-actions-popup__actions">
  <button mat-stroked-button *ngIf="data.cancelBtnTitle" type="button"
    class="atp-actions-popup__actions-button atp-actions-popup__actions-button_cancel"
    (click)="close(false)">{{data.cancelBtnTitle}}</button>
  <button mat-raised-button *ngIf="data.successBtnTitle" type="button" [color]="data.successBtnColor"
    class="atp-actions-popup__actions-button atp-actions-popup__actions-button_confirm" (click)="close(true)"
    cdkFocusInitial>{{data.successBtnTitle}}</button>
</div>
