import { Component, ElementRef, Inject } from '@angular/core';
import { atpAnimations } from '../../atp-animations';
import { AtpDynamicFormComponent } from './atp-dynamic-form.component';
import { AtpAddEditData, IAtpAddEditInfo } from '../../atp-core';
import { AtpDialogRef } from '../../components/atp-dialog/atp-dialog-container.component';
import { AtpHttpService, IAtpUser } from '../../services/atp-http.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ATP_DIALOG_DATA } from '../../components/atp-dialog/atp-dialog.service';
import { AtpHttpErrorsService } from '../../services/atp-http-errors.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'atp-add-edit-popup-dynamic-table-full-crud',
  templateUrl: './atp-dynamic-form.component.html',
  animations: [
    atpAnimations.OpenClose
  ]
})
export class AtpAddEditDynamicTableFullCrud<T extends AtpAddEditDynamicTableFullCrud<T>> extends AtpDynamicFormComponent<AtpAddEditData<any, IAtpAddEditInfo>> {

  constructor(
    api: AtpHttpService<IAtpUser>,
    httpErrors: AtpHttpErrorsService,
    dialog: MatDialog, router: Router,
    @Inject(ATP_DIALOG_DATA) data: AtpAddEditData<any, IAtpAddEditInfo>,
    elementRef: ElementRef<HTMLElement>,
    platform: Platform,
    protected dialogRef: AtpDialogRef<T>
  ) {
    super(data, api, httpErrors, dialog, router, elementRef, platform);
  }

  confirm(result: any) {
    this.dialogRef.close(result);
  }

  close() {
    this.dialogRef.close(false);
  }
}
