import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {FormControl, NgControl} from '@angular/forms';
import {AtpDateTimeType} from '../atp-range-date-time-picker/atp-date-time.service';

@Component({
  selector: 'atp-input-number-range',
  templateUrl: './atp-input-number-range.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AtpInputNumberRangeComponent
    }
  ],
  host: {
    '[id]': 'id'
  }
})
export class AtpInputNumberRangeComponent implements OnInit {

  constructor( @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formControlFrom = new FormControl();
    this.formControlTo = new FormControl();
  }

  formControlFrom: FormControl;
  formControlTo: FormControl;

  onChange = (_: any) => { };
  onTouched = () => { };

  static nextId = 0;
  stateChanges = new Subject<void>();

  id: string = `atp-input-number-range-${AtpInputNumberRangeComponent.nextId++}`;

  ngOnInit(): void {
    this.formControlFrom.valueChanges.subscribe(() => { this.inputChange(); });
    this.formControlTo.valueChanges.subscribe(() => { this.inputChange(); });
  }

  inputChange(): void {
    this.onChange([this.formControlFrom.value, this.formControlTo.value]);
  }

  writeValue(value: [number|null, number|null]): void {
    this.formControlFrom.setValue(value[0] >= 0 ? value[0] : null);
    this.formControlTo.setValue(value[1] >= 0 ? value[1] : null);
  }

  registerOnChange(fn: any): void {
    console.log('123');
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private _placeholder: string;
  @Input() get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  describedBy = '';

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }
}
