import { Directive, OnInit, Input, ViewContainerRef, ComponentFactoryResolver, ReflectiveInjector, OnDestroy, InjectionToken, Component, Inject } from '@angular/core';
import { AtpDynamicTableFullCrudComponent } from './atp-dynamic-table-full-crud.component';
import { IAtpEntity } from '../atp-core';
import { AtpHttpService, IAtpUser } from '../services/atp-http.service';
import { ComponentPortal, CdkPortalOutlet } from '@angular/cdk/portal';
import { FormatPipe } from '../pipes/format.pipe';

@Component({ selector: '', template: '' })
export abstract class AtpDynamicTableColumnEmbeddeableComponent<T extends IAtpEntity> {

  constructor(public data: AtpDynamicTableColumnEmbeddeableData<T>) {
  }

}

export class AtpDynamicTableColumnEmbeddeableData<T extends IAtpEntity> {

  constructor(public name: string, public data: any, public params: any[], public row: T, public parent: AtpDynamicTableFullCrudComponent<T, AtpHttpService<IAtpUser>>) {
  }

}

export const ATP_DYNAMIC_TABLE_COLUMN_EMBEDDEABLE_COMPONENT_INJECTOR_DATA = new InjectionToken<AtpDynamicTableColumnEmbeddeableData<IAtpEntity>>('atp_dynamic_table_column_embeddeable_component_injector_data');

@Component({
  selector: 'atp-dynamic-table-full-crud-tree-link',
  template: `
              <mat-icon class="atp-dynamic-table-full-crud-tree-link__icon">{{data.row.count ? 'folder' : 'folder_open'}}</mat-icon>
              <a class="atp-dynamic-table-full-crud__link" mat-button color="primary" [routerLink]="['/' + data.parent.baseGroupName.toLowerCase(), data.parent.type.toLowerCase(), 1, data.parent.pageInfo.pageSize, data.parent.sortInfo.active, data.parent.sortInfo.direction, data.row.id]" queryParamsHandling="merge">{{data.data}}</a>
            `,
  host: {
    class: 'atp-dynamic-table-full-crud-tree-link'
  }
})
export class AtpDynamicTableFullCrudTreeLink extends AtpDynamicTableColumnEmbeddeableComponent<any> {

  constructor(@Inject(ATP_DYNAMIC_TABLE_COLUMN_EMBEDDEABLE_COMPONENT_INJECTOR_DATA) public data: AtpDynamicTableColumnEmbeddeableData<any>) {
    super(data);
  }

}

@Directive({
  selector: '[dynamicTableColumnType]',
  providers: [FormatPipe]
})
export class DynamicTableColumnTypeDirective implements OnInit, OnDestroy {

  constructor(private _viewContainerRef: ViewContainerRef, private _componentFactoryResolver: ComponentFactoryResolver) {
  }

  @Input() name: string;
  @Input() params: any[];
  @Input() columnType: string;
  @Input() data: any;
  @Input() row: IAtpEntity;
  @Input() parent: AtpDynamicTableFullCrudComponent<IAtpEntity, AtpHttpService<IAtpUser>>;

  protected portal: ComponentPortal<any>;

  ngOnInit() {
    switch (this.columnType) {
      case 'TreeLink':
        this.portal = new ComponentPortal(AtpDynamicTableFullCrudTreeLink, null, ReflectiveInjector.fromResolvedProviders(ReflectiveInjector.resolve([{ provide: ATP_DYNAMIC_TABLE_COLUMN_EMBEDDEABLE_COMPONENT_INJECTOR_DATA, useValue: new AtpDynamicTableColumnEmbeddeableData<IAtpEntity>(this.name, this.data, this.params, this.row, this.parent) }]), this._viewContainerRef.parentInjector));
        this.portal.attach(new CdkPortalOutlet(this._componentFactoryResolver, this._viewContainerRef));
        break;
      case 'Custom':
        {
          const entryComponent = this.parent.annotations.entryComponents[this.params[0].toUpperCase()];
          if (entryComponent) {
            this.portal = new ComponentPortal(entryComponent, null, ReflectiveInjector.fromResolvedProviders(ReflectiveInjector.resolve([{ provide: ATP_DYNAMIC_TABLE_COLUMN_EMBEDDEABLE_COMPONENT_INJECTOR_DATA, useValue: new AtpDynamicTableColumnEmbeddeableData<IAtpEntity>(this.name, this.data, this.params, this.row, this.parent) }]), this._viewContainerRef.parentInjector));
            this.portal.attach(new CdkPortalOutlet(this._componentFactoryResolver, this._viewContainerRef));
          }
        }
        break;
    }
  }

  ngOnDestroy() {
    if (this.portal && this.portal.isAttached) {
      this.portal.detach();
    }
  }

}
