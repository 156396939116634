import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IAtpFiltersPanelPreset {
  id?: number;
  name: string;
  data: any;
  visibleNames: string[];
  isChanging?: boolean;
  order?: number;
}

export interface IAtpFiltersPanelPresetList {
  group: string;
  items: IAtpFiltersPanelPreset[];
}

@Injectable({
  providedIn: 'root'
})
export class AtpFiltersPanelPresetsStorageService {

  constructor() { }

  private sendData<T>(data: T): Observable<T> {
    return new Observable<T>(subscriber => {
      subscriber.next(data);
      subscriber.unsubscribe();
    });
  }

  private _list: IAtpFiltersPanelPresetList;

  private getListFromLocalStorage(group: string) {
    if (!this._list || this._list.group != group || this._list.items == null) {
      this._list = { group: group, items: JSON.parse(localStorage.getItem(group)) };
    }
    return this._list;
  }

  getList(group: string): Observable<IAtpFiltersPanelPreset[]> {
    return this.sendData(this.getListFromLocalStorage(group)?.items?.slice(0));
  }

  add(group: string, preset: IAtpFiltersPanelPreset): Observable<IAtpFiltersPanelPreset> {
    let list = this.getListFromLocalStorage(group);
    if (!list || !list.items) {
      list = { group: group, items: [] };
    }
    preset.id = list.items.length == 0 ? 0 : list.items.sort((x, y) => x.id > y.id ? 1 : x.id < y.id ? -1 : 0)[list.items.length - 1].id++;
    preset.order = list.items.length - 1;
    list.items.push(preset);
    localStorage.setItem(group, JSON.stringify(list.items));

    return this.sendData(preset);
  }

  save(group: string, items: IAtpFiltersPanelPreset[]): Observable<IAtpFiltersPanelPreset[]> {
    items.forEach((x, index) => x.order = index);
    localStorage.setItem(group, JSON.stringify(items));
    this._list = null;

    return this.sendData(this.getListFromLocalStorage(group)?.items.slice(0));
  }
}
