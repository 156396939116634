import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AtpFiltersPanelItem, AtpValidators } from "../../atp-core";
import { AtpFiltersPanelPresetsStorageService, IAtpFiltersPanelPreset } from "./atp-filters-panel-presets-storage.service";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'atp-filters-panel-presets',
  templateUrl: './atp-filters-panel-presets.component.html',
  host: {
    class: 'atp-filters-panel-presets'
  }
})
export class AtpFiltersPanelPresetsComponent implements OnInit {

  constructor(private _storage: AtpFiltersPanelPresetsStorageService) { }

  @Input() name: string;
  @Input() formGroup: FormGroup;
  @Input() controls: AtpFiltersPanelItem<any>[];

  editActiveItem: IAtpFiltersPanelPreset;

  private _beforeEditModeState: any;
  private _beforeEditModeVisibleNames: string[];
  private setBeforeEditModeState() {
    this.controls.forEach(x => {
      if (!(<any>x).isSearch) {
        x.isVisible = !!this._beforeEditModeVisibleNames.find(y => y == x.name);
      }
    });
    this.itemClick.emit(this._beforeEditModeState);
    this._beforeEditModeState = null;
    this._beforeEditModeVisibleNames = null;
    this.editActiveItem = null;
  }

  private _isEditMode: boolean;
  @Input() get isEditMode(): boolean {
    return this._isEditMode;
  }
  set isEditMode(val: boolean) {
    if (!this._isEditMode && val) {
      this._beforeEditModeState = this.formGroup.value;
      this._beforeEditModeVisibleNames = this.getVisibleNames();
    }

    if (this._isEditMode && this.isEditItem) {
      this.saveItem(this.items.find(x => x.isChanging), this.formControl.invalid);
    }

    if (this._isEditMode && !val && val !== false) {
      this.editingSave();
      this.setBeforeEditModeState();
    }
    else if (this._isEditMode && val === false) {
      this.ngOnInit();
      this.setBeforeEditModeState();
    }

    this._isEditMode = !!val;
  }
  @Output() isEditModeChange = new EventEmitter<boolean>();

  private _isAddMode: boolean;
  @Input() get isAddMode(): boolean {
    return this._isAddMode;
  }
  set isAddMode(val: boolean) {
    if (this._isAddMode && !val) {
      this.addingSave();
    }

    this._isAddMode = !!val;
  }
  @Output() isAddModeChange = new EventEmitter<boolean>();

  isEditItem = false;

  items: IAtpFiltersPanelPreset[] = [];
  formControl: FormControl;

  ngOnInit() {
    this._storage.getList(this.name).subscribe((items) => {
      this.items = items ?? [];
    });
  }

  private getVisibleNames(): string[] {
    return this.controls.filter(x => !(<any>x).isSearch && x.isVisible).map(x => x.name);
  }

  addingSave() {
    if (this.formControl.invalid) {
      this.formControl = null;
      this.items.splice(this.items.length - 1);
      return;
    }

    this.items[this.items.length - 1].name = this.formControl.value;
    this.items[this.items.length - 1].isChanging = false;
    this._storage.add(this.name, { data: this.formGroup.value, name: this.formControl.value, visibleNames: this.getVisibleNames() }).subscribe((item) => {
      this.formControl = null;
    });
  }

  editingSave() {
    if (this.editActiveItem) {
      this.editActiveItem.data = this.formGroup.value;
      this.editActiveItem.visibleNames = this.getVisibleNames();
    }

    this._storage.save(this.name, this.items).subscribe((items) => {
      this.items = items;
    });
  }

  addModeClick() {
    this.isAddMode = true;
    this.isAddModeChange.emit(this.isAddMode);
    this.formControl = new FormControl('', AtpValidators.required);
    this.items.push({ data: this.formGroup.value, name: '', isChanging: true, visibleNames: this.getVisibleNames() });
  }

  editModeClick() {
    this.isEditMode = true;
    this.isEditModeChange.emit(this.isEditMode);
  }

  remove(id: number) {
    const index = this.items.findIndex(x => x.id == id);
    if (index < 0) {
      return;
    }
    this.items.splice(index, 1);
  }

  editItem(item: IAtpFiltersPanelPreset) {
    this.isEditItem = true;
    item.isChanging = true;
    this.formControl = new FormControl(item.name, AtpValidators.required);
  }

  saveItem(item: IAtpFiltersPanelPreset, withoutName: boolean = false) {
    this.isEditItem = false;
    item.isChanging = false;
    item.name = withoutName ? item.name : this.formControl.value;
    item.data = this.formGroup.value;
    this.formControl = null;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }

  @Output() itemClick = new EventEmitter<any>();

  itemButtonClick(item: IAtpFiltersPanelPreset) {
    if (this.isAddMode) {
      return;
    }

    if (this.isEditMode) {
      if (this.editActiveItem) {
        this.editActiveItem.data = this.formGroup.value;
        this.editActiveItem.visibleNames = this.getVisibleNames();
      }
      this.editActiveItem = item;
    }

    this.controls.forEach(x => {
      if (!(<any>x).isSearch) {
        x.isVisible = !!item.visibleNames.find(y => y == x.name);
      }
    });

    this.itemClick.emit(item.data);
  }

}
