import { Component, Inject, HostBinding } from '@angular/core';
import { atpAnimations } from '../atp-animations';
import { NOTIFICATION_COMPONENT_INJECTOR_DATA } from './atp-notifications.component';
import { AtpNotificationsService, IAtpNotification, IAtpNotificationData } from './atp-notifications.service';

@Component({
  selector: 'atp-notification',
  templateUrl: './atp-notification.component.html',
  animations: [
    atpAnimations.OpenClose
  ],
  host: {
    class: 'atp-notification'
  }
})
export class AtpNotificationComponent {

  constructor(@Inject(NOTIFICATION_COMPONENT_INJECTOR_DATA) public data: IAtpNotification<IAtpNotificationData>, private _notificationService: AtpNotificationsService) {
    let links = data.data.message.match(/<a.*?<\/a>/ig);
    this.spans.push(data.data.message);
    if (links && links.length) {
      for (let i = 0; i < links.length; i++) {
        const removeIndex = this.spans.length - 1;
        this.spans.push(...this.spans[this.spans.length - 1].split(links[i]));
        this.spans.splice(removeIndex, 1);
      }
      this.links = links.map(x => x.replace(/<a.*?href="/, '').replace(/".*/, ''));
      this.linkNames = links.map(x => x.replace(/.*?>/, '').replace(/<.*/, ''));
    }
  }

  spans: string[] = [];
  links: string[] = [];
  linkNames: string[] = [];

  @HostBinding('@OpenClose') get openClose() {
    return true;
  }

  closeClick() {
    this.data.detach();
  }

  action() {
    this._notificationService.notViewsMessages.splice(this._notificationService.notViewsMessages.findIndex(x => this.data.data.id == x.data.id), 1);
  }

}
