import { Component, InjectionToken, ReflectiveInjector } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { IAtpNotification, AtpNotificationsSettings, IAtpNotificationData } from './atp-notifications-new.service';
import {HttpService} from '../../services/http.service';
import {AtpNotificationNewComponent} from './atp-notification-new.component';
export const NOTIFICATION_COMPONENT_INJECTOR_DATA = new InjectionToken<IAtpNotification<IAtpNotificationData>>('notification_component_injector_data');
import {DatePipe} from '@angular/common';
@Component({
  selector: 'atp-notifications-new',
  template: `<ng-template *ngFor="let portal of portals" [cdkPortalOutlet]="portal"></ng-template>`,
  host: {
    class: 'atp-notifications'
  }
})
export class AtpNotificationsNewComponent {

  constructor(
    public api: HttpService
  ) { }

  portals: ComponentPortal<any>[] = [];

  ngOnInit(): void {
    this.api.getNotifications().subscribe((result) => {

      for(let item of result.messages){

        let newComponentPortal: ComponentPortal<any>;

        const datePipe = new DatePipe('ru_RU');

        let data:IAtpNotification<IAtpNotificationData> = {
          data: {
            id: item.id,
            title: item.title,
            message: item.text,
            date: item.createdDate //datePipe.transform(item.createdDate, 'dd.MM.yyyy h:mm')
          }
        };

        newComponentPortal = new ComponentPortal(AtpNotificationNewComponent, null, ReflectiveInjector.fromResolvedProviders(ReflectiveInjector.resolve([{ provide: NOTIFICATION_COMPONENT_INJECTOR_DATA, useValue: data }])));

        this.portals.push(newComponentPortal);
      }
    });
  }


  addMessage<TData extends IAtpNotificationData>(settings: AtpNotificationsSettings, data: IAtpNotification<TData>) {
    let newComponentPortal: ComponentPortal<any>;
    if (!data.detach) {
      data.detach = () => { newComponentPortal.detach(); this.portals = this.portals.filter(x => x.isAttached); };
    }
    newComponentPortal = new ComponentPortal(settings.messageType, null, ReflectiveInjector.fromResolvedProviders(ReflectiveInjector.resolve([{ provide: NOTIFICATION_COMPONENT_INJECTOR_DATA, useValue: data }])));
    this.portals.push(newComponentPortal);
    if (settings.time != Number.MAX_VALUE) {
      setTimeout(() => {
        if (newComponentPortal.isAttached) {
          data.detach();
        }
      }, settings.time || 5000);
    }
  }

}
