import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtpFiltersPanelComponent } from './atp-filters-panel.component';
import { AtpFiltersPanelPresetsComponent } from './atp-filters-panel-presets.component';
import { AtpFiltersPanelControlsComponent } from './atp-filters-panel-controls.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { AtpRangeDateTimePickerModule } from '../../components/atp-range-date-time-picker/atp-range-date-time-picker.module';
import { AtpFiltersPanelPresetsStorageService } from './atp-filters-panel-presets-storage.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatChipsModule } from '@angular/material/chips';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AtpBtnSectionsModule} from '../../components/atp-btn-sections/atp-btn-sections.module';
import {AtpInputNumberRangeModule} from '../../components/atp-input-number-range/atp-input-number-range.module';

@NgModule({
  declarations: [
    AtpFiltersPanelComponent,
    AtpFiltersPanelPresetsComponent,
    AtpFiltersPanelControlsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatChipsModule,
    MatCheckboxModule,
    AtpRangeDateTimePickerModule,
    DragDropModule,
    OverlayModule,
    MatAutocompleteModule,
    AtpBtnSectionsModule,
    AtpInputNumberRangeModule
  ],
  exports: [
    AtpFiltersPanelComponent
  ],
  providers: [
    AtpFiltersPanelPresetsStorageService
  ]
})
export class AtpFiltersPanelModule { }
