import { Component, InjectionToken, ReflectiveInjector } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { IAtpNotification, AtpNotificationsSettings, IAtpNotificationData } from './atp-notifications.service';

export const NOTIFICATION_COMPONENT_INJECTOR_DATA = new InjectionToken<IAtpNotification<IAtpNotificationData>>('notification_component_injector_data');

@Component({
  selector: 'atp-notifications',
  template: `<ng-template *ngFor="let portal of portals" [cdkPortalOutlet]="portal"></ng-template>`,
  host: {
    class: 'atp-notifications'
  }
})
export class AtpNotificationsComponent {

  constructor() { }

  portals: ComponentPortal<any>[] = [];

  addMessage<TData extends IAtpNotificationData>(settings: AtpNotificationsSettings, data: IAtpNotification<TData>) {
    let newComponentPortal: ComponentPortal<any>;
    if (!data.detach) {
      data.detach = () => { newComponentPortal.detach(); this.portals = this.portals.filter(x => x.isAttached); };
    }
    newComponentPortal = new ComponentPortal(settings.messageType, null, ReflectiveInjector.fromResolvedProviders(ReflectiveInjector.resolve([{ provide: NOTIFICATION_COMPONENT_INJECTOR_DATA, useValue: data }])));
    this.portals.push(newComponentPortal);
    if (settings.time != Number.MAX_VALUE) {
      setTimeout(() => {
        if (newComponentPortal.isAttached) {
          data.detach();
        }
      }, settings.time || 5000);
    }
  }

}