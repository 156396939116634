import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ATP_DIALOG_DATA, AtpDialogService } from '../../components/atp-dialog/atp-dialog.service';
import { AtpHistoryData, IAtpHistory, IAtpPagination, IAtpSelectItem, AtpHistoryDetailData, EventArgs } from '../../atp-core';
import { AtpDialogRef } from '../../components/atp-dialog/atp-dialog-container.component';
import { AtpBasePageComponent } from '../../atp-base-page-component';
import { AtpHttpService, IAtpUser } from '../../services/atp-http.service';
import { AtpHttpErrorsService, AtpHttpErrorHandler } from '../../services/atp-http-errors.service';
import { AtpHistoryItemDetailComponent } from '../atp-history-item-detail/atp-history-item-detail.component';
import { Subscription } from 'rxjs';
import { AtpSectionDirective } from '../../components/atp-section/atp-section.directive';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'atp-history',
  templateUrl: './atp-history.component.html',
  host: {
    class: 'atp-history'
  }
})
export class AtpHistoryComponent<TEntity extends IAtpHistory> extends AtpBasePageComponent implements OnInit {

  constructor(protected api: AtpHttpService<IAtpUser>, protected httpErrors: AtpHttpErrorsService, protected elementRef: ElementRef<HTMLElement>, protected platform: Platform,
    protected dialog: MatDialog, protected router: Router, protected atpDialog: AtpDialogService, @Inject(ATP_DIALOG_DATA) public data?: AtpHistoryData, protected dialogRef?: AtpDialogRef<AtpHistoryComponent<IAtpHistory>>) {
    super();
  }

  tableData: IAtpPagination<IAtpHistory>;

  displayedColumns: string[] = ['action', 'id', 'entityId', 'date', 'userName', 'roleName'];

  isNotData = false;

  pageSizeOptions: number[] = [25, 50, 100];

  dataSource = new MatTableDataSource<IAtpHistory>();

  pageInfo: PageEvent;
  sortInfo: Sort;

  pagination(event: PageEvent): void {
    this.pageInfo = event;
    // this.replaceStateLocation();
    this.getData();
  }

  sort(event: Sort): void {
    this.sortInfo = event;
    // this.replaceStateLocation();
    // this.getData();
  }

  protected navigateSubscription: Subscription;

  private _topSectionSubscription: Subscription;
  private _topSection: AtpSectionDirective;
  @ViewChild('topSection', { read: AtpSectionDirective }) get topSection(): AtpSectionDirective {
    return this._topSection;
  }
  set topSection(value: AtpSectionDirective) {
    if (!this.platform.TRIDENT && !this.platform.SAFARI) {
      if (value) {
        if (this._topSectionSubscription) {
          this._topSectionSubscription.unsubscribe();
        }
        this.tableMaxHeight = (value.height + (this.bottomSection?.height ?? 0)).toString();
        this._topSectionSubscription = value.heightChanged.subscribe(
          (data: EventArgs<AtpSectionDirective, number>) => {
            this.tableMaxHeight = (data.e + (this.bottomSection?.height ?? 0)).toString();
          }
        );

        this.subscriptions.add(this._topSectionSubscription);
      }

      this._topSection = value;
    }
  }

  private _bottomSectionSubscription: Subscription;
  private _bottomSection: AtpSectionDirective;
  @ViewChild('bottomSection', { read: AtpSectionDirective }) get bottomSection(): AtpSectionDirective {
    return this._bottomSection;
  }
  set bottomSection(value: AtpSectionDirective) {
    if (!this.platform.TRIDENT && !this.platform.SAFARI) {
      if (value) {
        if (this._bottomSectionSubscription) {
          this._bottomSectionSubscription.unsubscribe();
        }
        this.tableMaxHeight = (value.height + (this.topSection?.height ?? 0)).toString();
        this._bottomSectionSubscription = value.heightChanged.subscribe(
          (data: EventArgs<AtpSectionDirective, number>) => {
            this.tableMaxHeight = (data.e + (this.topSection?.height ?? 0)).toString();
          }
        );

        this.subscriptions.add(this._bottomSectionSubscription);
      }

      this._bottomSection = value;
    }
  }

  private _baseComponentHeight: number;

  private _tableMaxHeight: string = '0';
  get tableMaxHeight(): string {
    return this._tableMaxHeight;
  }
  set tableMaxHeight(value: string) {
    setTimeout(() => {
      if (+value < window.screen.height / 2) {
        if (this.elementRef) {
          if (!this._baseComponentHeight) {
            this._baseComponentHeight = this.elementRef.nativeElement.offsetHeight;
          }
          this._tableMaxHeight = `calc(${this._baseComponentHeight}px - ${value}px)`;
        }
        else {
          this._tableMaxHeight = `calc(100vh - ${value}px)`;
        }
      }
      else {
        this._tableMaxHeight = `50vh`;
      }
    });
  }

  ngOnInit() {
    if (this.data.isShared) {
      this.displayedColumns.push('type');
    }

    this.tableData = this.data.items;
    this.dataSource.data = this.tableData.items;
    this.isNotData = !this.dataSource.data.length;
  }

  close() {
    this.dialogRef.close();
  }

  getData(): void {
    this.isBlockedPage = true;
    this.subscriptions.add(this.api['history' + this.data.baseGroupName](this.data.type, this.data.entityId, this.pageInfo.pageIndex + 1, this.pageInfo.pageSize, this.sortInfo ? this.sortInfo.active : '', this.sortInfo ? this.sortInfo.direction : '').subscribe(
      (result: IAtpPagination<IAtpHistory>) => {
        let userIdsItems = {};
        for (const item of result.items) {
          if (item.action == "Remove" && !this.data.canOpenRemoveItem) {
            item.haveJson = false;
          }
          if (!item.userId) {
            continue;
          }
          if (!userIdsItems[item.userId]) {
            userIdsItems[item.userId] = [];
          }
          userIdsItems[item.userId].push(item);
        }
        this.getUserNamesByIds(userIdsItems, result);
        this.isBlockedPage = false;
      },
      (err) => {
        this.httpErrors.process(err.status, () => { this.getData(); }, this, [new AtpHttpErrorHandler(400, err.error)], this.data.backUrl);
      }
    ));
  }

  protected getUserNamesByIds(userIdsItems: any, data: IAtpPagination<IAtpHistory>): void {
    this.isBlockedPage = true;
    this.api.getUserNamesByIds(Object.getOwnPropertyNames(userIdsItems)).subscribe(
      (users: IAtpSelectItem[]) => {
        for (const key in userIdsItems) {
          const user = users.find(x => x.id == key);
          // TODO: сделать везде проверку с удаленными !!!!
          for (const item of userIdsItems[key]) {
            if (user) {
              item.userName = user.value;
              item.roleName = (<any>user).roleName;
            }
            else {
              item.userName = 'Удален';
              item.roleName = 'Удален';
            }
          }
        }

        this.tableData = data;
        this.dataSource.data = this.tableData.items;
        this.isNotData = !this.dataSource.data.length;

        this.isBlockedPage = false;
      },
      (err) => {
        this.httpErrors.process(err.status, () => { this.getUserNamesByIds(userIdsItems, data); }, this, [new AtpHttpErrorHandler(400, err.error)], this.data.backUrl);
      }
    );
  }

  protected openRemoveItem(modelId: string, userIdsItems: any, data: IAtpPagination<IAtpHistory>, urlPath: string): void {
    this.isBlockedPage = true;
    this.api.getUserNamesByIds(Object.getOwnPropertyNames(userIdsItems)).subscribe(
      (users: IAtpSelectItem[]) => {
        for (const key in userIdsItems) {
          const user = users.find(x => x.id == key);
          for (const item of userIdsItems[key]) {
            if (user) {
              item.userName = user.value;
              item.roleName = (<any>user).roleName;
            }
            else {
              item.userName = 'Удален';
              item.roleName = 'Удален';
            }
          }
        }

        const arrUrlPath = urlPath.split('/');

        this.atpDialog.open(AtpHistoryComponent, { data: new AtpHistoryData(arrUrlPath[1], arrUrlPath[2], modelId, data, this.data.backUrl, false, false) });

        this.isBlockedPage = false;
      },
      (err) => {
        this.httpErrors.process(err.status, () => { this.getUserNamesByIds(userIdsItems, data); }, this, [new AtpHttpErrorHandler(400, err.error)], this.data.backUrl);
      }
    );
  }

  getItemDetail(model: TEntity): void {
    this.isBlockedPage = true;
    this.api.getHistoryItemDetail(model.urlPath, model.id).subscribe(
      (result: IAtpHistory | IAtpPagination<IAtpHistory>) => {
        if (model.action == "Remove") {
          let userIdsItems = {};
          for (const item of (<IAtpPagination<IAtpHistory>>result).items) {
            if (item.action == "Remove") {
              item.haveJson = false;
            }
            if (!item.userId) {
              continue;
            }
            if (!userIdsItems[item.userId]) {
              userIdsItems[item.userId] = [];
            }
            userIdsItems[item.userId].push(item);
          }

          this.openRemoveItem(model.id, userIdsItems, <IAtpPagination<IAtpHistory>>result, model.urlPath);
        }
        else {
          this.atpDialog.open(AtpHistoryItemDetailComponent, { data: new AtpHistoryDetailData(<IAtpHistory>result, this.data.backUrl) });
        }
        this.isBlockedPage = false;
      },
      (err) => {
        this.httpErrors.process(err.status, () => { this.getItemDetail(model); }, this, [new AtpHttpErrorHandler(400, err.error)], this.data.backUrl);
      }
    );
  }

  exportInCsv() {
    this.isBlockedPage = true;
    this.api['exportHistoryInCsv']().subscribe(
      (result: any) => {
        this.isBlockedPage = false;
      },
      (err) => {
        this.httpErrors.process(401, () => { this.exportInCsv(); }, this);
      }
    );
  }

}
