import { InjectionToken, OnDestroy, Directive, ViewContainerRef, ComponentFactoryResolver, Input, Type, ReflectiveInjector } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { IAtpEntity } from '../../atp-core';
import { ComponentPortal, CdkPortalOutlet } from '@angular/cdk/portal';
import { AtpBasePageComponent } from '../../atp-base-page-component';

export class AtpAddEditEmbeddeableComponent<T extends any> {
  constructor(public data: AtpAddEditEmbeddeableData<T>) {
    this.form = data.formGroup;
  }
  form: FormGroup;
}

export class AtpAddEditEmbeddeableData<T extends any> {
  constructor(public formGroup: FormGroup, public formControl: AbstractControl, public name: string, public display: string, public params: any[], public defaultValue: any, public model: T, public correctResult: { action: (result: any) => void }, public parent: AtpBasePageComponent, public handbooks: any) {
  }
}

export const ATP_ADD_EDIT_EMBEDDEABLE_COMPONENT_INJECTOR_DATA = new InjectionToken<AtpAddEditEmbeddeableData<any>>('atp_add_edit_embeddeable_component_injector_data');

@Directive({ selector: '[atpCustomGroupInjector]' })
export class AtpCustomGroupInjectorDirective implements OnDestroy {
  constructor(private _viewContainerRef: ViewContainerRef, private _componentFactoryResolver: ComponentFactoryResolver) {
  }

  @Input('reactiveFormGroup') formGroup: FormGroup;
  @Input('reactiveFormControl') formControl: FormControl;
  @Input() name: string;
  @Input() display: string;
  @Input('formatParams') params: any[];
  @Input() model: IAtpEntity;
  @Input() correctResult: { action: (result: any) => void };
  @Input() parent: AtpBasePageComponent;
  @Input() defaultValue: any;
  @Input() handbooks: any;

  protected portal: ComponentPortal<AtpAddEditEmbeddeableComponent<IAtpEntity>>;

  @Input('atpCustomGroupInjector') set atpCustomGroupInjector(value: Type<AtpAddEditEmbeddeableComponent<IAtpEntity>>) {
    setTimeout(() => {
      if (this.portal && this.portal.component == value) return;

      this.portal = new ComponentPortal(value, null, ReflectiveInjector.fromResolvedProviders(ReflectiveInjector.resolve([{ provide: ATP_ADD_EDIT_EMBEDDEABLE_COMPONENT_INJECTOR_DATA, useValue: new AtpAddEditEmbeddeableData(this.formGroup, this.formControl, this.name, this.display, this.params, this.defaultValue, this.model, this.correctResult, this.parent, this.handbooks ) }]), this._viewContainerRef.parentInjector));
      this.portal.attach(new CdkPortalOutlet(this._componentFactoryResolver, this._viewContainerRef));
    })
  }

  ngOnDestroy() {
    if (this.portal && this.portal.isAttached) {
      this.portal.detach();
    }
  }
}
