import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtpBtnSectionsComponent} from './atp-btn-sections.component';
import {AtpTreeComponent} from './atp-tree.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  declarations: [
    AtpBtnSectionsComponent,
    AtpTreeComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatCheckboxModule
  ],
  exports: [
    AtpBtnSectionsComponent,
    AtpTreeComponent
  ]
})
export class AtpBtnSectionsModule { }
