<button *ngIf="actionsMenuModel.type == 'Single'" mat-icon-button matTooltip="Действия"
  class="atp-table-actions-menu__open-button atp-table-actions-menu__open-button_single" matTooltipPosition="right"
  [matMenuTriggerFor]="menu" (click)="opened()" [disabled]="actionsMenuModel.isDisabled" [color]="buttonColor">
  <mat-icon>{{icon}}</mat-icon>
</button>
<button class="atp-table-actions-menu__open-button atp-table-actions-menu__open-button_multiple"
  *ngIf="actionsMenuModel.type == 'Multiple'" mat-button [matMenuTriggerFor]="menu" (click)="opened()"
  [disabled]="actionsMenuModel.isDisabled" [color]="buttonColor">
  <mat-icon>{{icon}}</mat-icon>
  <span>Действия</span>
</button>
<button class="atp-table-actions-menu__open-button atp-table-actions-menu__open-button_other"
  *ngIf="actionsMenuModel.type == 'Other'" mat-raised-button [matMenuTriggerFor]="menu" (click)="opened()"
  [disabled]="actionsMenuModel.isDisabled" [color]="buttonColor">
  <mat-icon>{{icon}}</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <ng-container *ngFor="let item of actionsMenuModel.items">
    <ng-container *ngIf="!item.isInvisible">
      <button *ngIf="!item.items" mat-menu-item (click)="item.action(model)">
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.name}}</span>
      </button>
      <atp-table-actions-submenu *ngIf="item.items" [actionMenuItem]="item" [model]="model"></atp-table-actions-submenu>
    </ng-container>
  </ng-container>
</mat-menu>