import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {AtpScheduleComponent} from './atp-schedule.component';
import {MatSelectModule} from '@angular/material/select';
import {AtpRangeDateTimePickerModule} from '../atp-range-date-time-picker/atp-range-date-time-picker.module';

@NgModule({
  declarations: [
    AtpScheduleComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    AngularEditorModule,
    FormsModule,
    MatSelectModule,
    AtpRangeDateTimePickerModule
  ],
  exports: [
    AtpScheduleComponent
  ],
  providers: [
  ]
})
export class AtpScheduleModule { }
