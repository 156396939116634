import { Validators, AbstractControl, FormGroup } from '@angular/forms';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, UrlSegment, UrlMatchResult, DefaultUrlSerializer, UrlTree, Router } from '@angular/router';
import { AtpDateTimeService } from './components/atp-range-date-time-picker/atp-date-time.service';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { Observable, Subject, Subscription } from 'rxjs';
import { AtpHttpService, IAtpUser } from './services/atp-http.service';
import { environment } from 'src/environments/environment';
import { AtpHttpErrorsService } from './services/atp-http-errors.service';
import { ComponentType } from '@angular/cdk/overlay/index';
import * as signalR from '@aspnet/signalr';
import { IInfoOffer, IInfoOfferTableRow } from '../models';

export * from './atp-dynamic-table-full-crud/atp-filters-panel/models';
export let AppInjector: Injector;

export function setAppInjector(injector: Injector) {
  if (AppInjector) {
    console.error('Programming error: AppInjector was already set');
  }
  else {
    AppInjector = injector;
  }
}

export class AtpValidators extends Validators {
  static positiveNumber(fieldControl: AbstractControl): { [key: string]: boolean } {
    return +fieldControl.value > 0 ? null : { positivenumber: true };
  }

  static checkPassLanguage(fieldControl: AbstractControl): { [key: string]: boolean } {
    return (<string>fieldControl.value).search(/^[a-zA-Z0-9]+$/) != -1 ? null : { checkpasslanguage: true };
  }

  static stringLength(maxLength: number, minLength: number): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      return <string>fieldControl.value && ((<string>fieldControl.value).length > maxLength || (<string>fieldControl.value).length < minLength) ? { stringlength: true } : null;
    }
  }

  static minValue(minValue: number): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      return !minValue || (!isNaN(+fieldControl.value) && +fieldControl.value >= minValue) ? null : { minvalue: true };
    }
  }

  static maxValue(maxValue: number): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      return !maxValue || (!isNaN(+fieldControl.value) && +fieldControl.value <= maxValue) ? null : { maxvalue: true };
    }
  }

  static regularExpression(pattern: string | RegExp, name: string): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    const reg = pattern instanceof RegExp ? pattern : new RegExp(pattern);
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      return !<string>fieldControl.value || reg.test(<string>fieldControl.value) ? null : { [name.toLowerCase()]: true };
    }
  }

  static compare(form: FormGroup, otherProperty: string): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    let subscription: Subscription;
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      if (!subscription) {
        subscription = form.controls[otherProperty].valueChanges.subscribe(
          (value) => {
            fieldControl.setValue(fieldControl.value);
            fieldControl.markAsTouched();
          }
        );
      }
      return form.controls[otherProperty] && (<string>form.controls[otherProperty].value) == (<string>fieldControl.value) ? null : { compare: true };
    }
  }

  static checkFiles(): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      return fieldControl.value != 'Invalid' ? null : { checkFiles: true };
    }
  }

  private static checkDigit(inn: number, coefficients: number[]) {
    let n = 0;

    for (var i in coefficients) {
      n += coefficients[i] * inn[i];
    }

    return n % 11 % 10;
  }

  static inn(type: 'ip' | 'org' | 'any'): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      let value = fieldControl.value;

      const regexpNumber = type == 'ip' ? /^[+0-9]{12}$/ : type == 'org' ? /^[+0-9]{10}$/ : /^[+0-9]{10}$|^[+0-9]{12}$/;

      if (regexpNumber.test(value)) {
        if (value.length == 10) {
          var n10 = AtpValidators.checkDigit(value, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
          if (n10 === parseInt(value[9])) {
            return null;
          }
        }
        else {
          var n11 = AtpValidators.checkDigit(value, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
          var n12 = AtpValidators.checkDigit(value, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
          if ((n11 === parseInt(value[10])) && (n12 === parseInt(value[11]))) {
            return null;
          }
        }
      }

      return { inn: true };
    }
  }

  static ogrn(type: 'ip' | 'org' | 'any'): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      let value = fieldControl.value;

      const regexpNumber = type == 'ip' ? /^[+0-9]{15}$/ : type == 'org' ? /^[+0-9]{13}$/ : /^[+0-9]{13}$|^[+0-9]{15}$/;

      if (regexpNumber.test(value)) {
        if (value.Length == 13) {
          let num12 = Math.floor((value / 10) % 11);
          let dgt13 = num12 == 10 ? 0 : num12;
          return value.substr(12, 1) == dgt13 ? null : { ogrn: true };
        }
        else {
          let num14 = Math.floor((value / 10) % 13);
          let dgt15 = num14 % 10;
          return value.substr(14, 1) == dgt15 ? null : { ogrn: true };
        }
      }

      return { ogrn: true };
    }
  }

  static emailAddress(fieldControl: AbstractControl): { [key: string]: boolean } {
    let value = fieldControl.value;
    return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(value) ? null : { emailaddress: true };
  }

  static kpp(fieldControl: AbstractControl): { [key: string]: boolean } {
    let value = fieldControl.value;
    return /^\d{4}[\dA-Z][\dA-Z]\d{3}$/.test(value) ? null : { kpp: true };
  }

  static okpo(fieldControl: AbstractControl): { [key: string]: boolean } {
    let value = fieldControl.value;

    if (!/^[+0-9]{8}$|^[+0-9]{10}$/.test(value)) {
      return { okpo: true };
    }

    const expectedValue = Number(value.slice(-1));
    const getWeight = (index: number): number => {
      if (index < 10) {
        return index + 1;
      }
      else {
        return index % 10 + 1;
      }
    }
    const testingValue = value.slice(0, -1);
    let summ = 0;
    for (const i in testingValue.split('')) {
      summ += Number(testingValue[i]) * getWeight(Number(i));
    }
    let del11 = summ % 11;
    if (del11 === 10) {
      summ = 0;
      for (const i in testingValue.split('')) {
        summ += Number(testingValue[i]) * (getWeight(Number(i)) + 2);
      }
      del11 = (del11 === 10) ? 0 : del11;
    }

    return del11 === expectedValue ? null : { okpo: true };
  }

  //
  static bik(fieldControl: AbstractControl): { [key: string]: boolean } {
    let value = fieldControl.value;

    if (!/^\d{9}$/.test(value)) {
      return { bik: true };
    }

    const thirdPart = value.slice(-3);
    if (+thirdPart === 0 || +thirdPart === 1 || +thirdPart === 2) {
      return null;
    }
    return +thirdPart >= 50 && +thirdPart < 1000 ? null : { bik: true };
  }

  // export const checkPaymetAccount = (value: string, bik: string): boolean => {
  //   const valueToString = value ? value.toString() : '';
  //   if (checkBIK(bik)) {
  //     if (!/[^0-9]/.test(valueToString) && valueToString.length === 20) {
  //       const bikRs = bik.toString().slice(-3) + valueToString;
  //       let checkSum = 0;
  //       const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
  //       for (var i in coefficients) {
  //         checkSum += coefficients[i] * (Number(bikRs[i]) % 10);
  //       }
  //       return checkSum % 10 === 0;
  //     }
  //   }
  //   return false;
  // };
  // export const checkCorrespondentAccount = (value: string, bik: string) : boolean => {
  //   const valueToString = value ? value.toString() : ''
  //   if(checkBIK(bik)){
  //     if(!/[^0-9]/.test(valueToString) && valueToString.length === 20){
  //       const bikKs = '0' + bik.slice(4, 6) + valueToString;
  //       let checkSum = 0
  //       const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1]
  //       for (var i in coefficients) {
  //         checkSum += coefficients[i] * (Number(bikKs[i]) % 10);
  //       }
  //       return (checkSum % 10 === 0)
  //     }
  //   }
  //   return false
  // }

  static okato(fieldControl: AbstractControl): { [key: string]: boolean } {
    let value = fieldControl.value;

    if (!value) {
      return { okato: true };
    }

    const length = value.length
    if (length < 3) {
      return { okato: true };
    }

    const getWeight = (index: number): number => {
      if (index < 10) return index + 1
      else return index % 10 + 1
    }

    const getExpectedValue = () => {
      if (length < 4) return value.slice(-1)
      if (length >= 4 && length < 6) return value.substr(0, 3).slice(-1)
      if (length >= 6 && length < 9) return value.substr(0, 6).slice(-1)
      else return value.substr(0, 9).slice(-1)
    }

    const expectedValue = Number(getExpectedValue())
    const getTestingString = () => {
      if (length < 3) return value
      if (length >= 3 && length < 5) return value.substr(0, 2)
      if (length >= 5 && length < 8) return value.substr(0, 5)
      else return value.substr(0, 8)
    }

    const valueStr = getTestingString()
    let summ = 0
    for (const i in valueStr.split('')) {
      summ += Number(valueStr[i]) * getWeight(Number(i))
    }

    const del11 = summ % 11
    const check = (del11 === 10) ? 0 : del11
    if (length > 9 && (check === del11)) {
      return null;
    }
    if (check === expectedValue) {
      return null;
    }

    return { okato: true };
  }

  //

  static multipleAutocompleteRequired(multipleAutocompleteValues: { [key: string]: Array<IAtpSelectItem> }, propertyName: string): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      return multipleAutocompleteValues[propertyName] && multipleAutocompleteValues[propertyName].length ? null : { multipleautocompleterequired: true };
    }
  }

  // TODO: рефакторинг
  static rangeDateTime(dateTimeService: AtpDateTimeService, type: string): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      if (fieldControl.value == 'INVALID') {
        return { rangedatetime: true };
      }

      return !fieldControl.value || (fieldControl.value.length == 2 && fieldControl.value[0] <= fieldControl.value[1]) ? null : { rangedatetime: true };
    }
  }

  static checkSamePriceOffer(
    bestPrice: number,
    isBestSuggestion: boolean,
    ndsControll: AbstractControl,
    currencies: any
  ): (fieldControl: AbstractControl) => { [key: string]: boolean } {

    let subscription: Subscription;

    return (fieldControl: AbstractControl): { [s: string]: boolean } => {

      if (isBestSuggestion)
        return null;

      if (!subscription) {
        subscription = ndsControll.valueChanges.subscribe(
          (value) => {
            fieldControl.setValue(fieldControl.value);
            fieldControl.markAsTouched();
          }
        );
      }

      let currency = currencies.find(cur => cur.id === ndsControll.value.id);

      if (currency) {
        let value = fieldControl.value * currency.curs;

        if (bestPrice <= 0)
          return null;

        if (bestPrice == value) {
          return { samePriceOffer: true };
        }
      }

      return null;
      // return +fieldControl.value > 0 ? null : { positivenumber: true };
    };
  }


  static maxVal(currentPrice: Array<number>, i): (fieldControl: AbstractControl) => { [key: string]: boolean } {

    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      if (currentPrice[i] > 0 && fieldControl.value > currentPrice[i]) {
        return { maxvalue: true };
      }
      return null;
    };
  }
}

@Injectable()
export class AtpLowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    let urlParts = url.split('?');
    return super.parse(urlParts[0].toLowerCase() + (urlParts[1] ? '?' + urlParts[1] : ''));
  }
}

export interface IAtpMergeParamsAndQueryParamsResolverResult {
  params: any;
  queryParams: any;
  queryParamsString: string;
  backUrl: string;
}

@Injectable()
export class AtpMergeParamsAndQueryParamsResolver implements Resolve<IAtpMergeParamsAndQueryParamsResolverResult> {
  constructor(private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): IAtpMergeParamsAndQueryParamsResolverResult {
    return { params: route.params, queryParams: route.queryParams, queryParamsString: state.url.split('?')[1], backUrl: this.router.url };
  }
}

export function optionalRouteHandler(path: string, urlSegments: UrlSegment[]): UrlMatchResult {
  let parts = path.split('/');
  if (parts.length < urlSegments.length) return null;

  let posParams: { [name: string]: UrlSegment; } = {};
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].substr(0, 1) == ':' && parts[i].substr(-1, 1) == '?') {
      if (urlSegments[i]) {
        posParams[parts[i].substr(1, parts[i].length - 2)] = urlSegments[i];
      }
    } else if (parts[i].substr(0, 1) == ':') {
      if (!urlSegments[i]) {
        return null;
      }
      posParams[parts[i].substr(1, parts[i].length - 1)] = urlSegments[i];
    } else {
      if (!urlSegments[i] || urlSegments[i].path != parts[i]) {
        return null;
      }
    }
  }
  return {
    consumed: urlSegments,
    posParams: posParams
  };
}

// История

export interface IAtpHistory {
  id: string;
  action: string;
  date: string;
  userId: string;
  userName?: string;
  entityId: string;
  urlPath: string;
  json: any;
  haveJson: boolean;
}

export class AtpHistoryData {
  constructor(public baseGroupName: string, public type: string, public entityId: string, public items: IAtpPagination<IAtpHistory>, public backUrl: string, public isShared: boolean = false, public canOpenRemoveItem: boolean = true) { }
}

export class AtpHistoryDetailData {
  constructor(public model: IAtpHistory, public backUrl: string) { }
}

// DynamicTableFullCrud

export interface IAtpTableInfo {
  title: string;
  active: string;
  direction: string;
  isTree: boolean;
}

export interface IAtpPropertyInfo {
  name: string;
  display: string;
  order: number;
  format: string;
  isNotSort: boolean;
  isHidden: boolean;
  columnType: string;
  params: any[];
  filtersInfo: IAtpFilterInfo[];
}

export interface IAtpFilterInfo {
  name: string;
  display: string;
  groupName: string;
  groupDisplay: string;
  icon: string;
  defaultValue: any;
  type: string;
  selectItems?: IAtpSelectItem[];
  isSearch?: boolean;
  isVisibleDefault: boolean;
  tree?: Array<object>;
}

export interface IAtpAddEditInfo {
  title: string;
  icon: string;
  properties: IAtpAddEditPropertyInfo[];
  groups: IAtpAddEditGroupPropertyInfo[];
  model: any;
}

export interface IAtpAddEditGroupPropertyInfo {
  name: string;
  display: string;
  type: string;
  params: any[];
  order: string;
  groupName: string;
  bind: string;
  isVisible: boolean;
  items: Array<IAtpAddEditGroupPropertyInfo | IAtpAddEditPropertyInfo>;
  form: FormGroup;
  isGroup: boolean;
}

export interface IAtpAddEditPropertyInfo {
  name: string;
  display: string;
  controlType: string;
  params: any[];
  order: number;
  isHidden: boolean;
  defaultValue: any;
  validationsInfo: IAtpValidationInfo[];
  selectItems: any[];
  groupName: string;
  componentAfter: string;
  componentBefore: string;
  isDisabled: boolean;
}

export interface IAtpValidationInfo {
  name: string;
  errorMessage: string;
  params: any[];
}

export interface IAtpSelectItem {
  id: any;
  value: string;
}

export interface IAtpPagination<T> {
  allCount: number;
  pageSize: number;
  pagesCount: number;
  pageNumber: number;
  items: T[]
}

export interface IAtpPaginationDynamicTable<T> extends IAtpPagination<T> {
  tableInfo: IAtpTableInfo;
  propertiesInfo: IAtpPropertyInfo[];
}

export interface IAtpPaginationDynamicTreeTable<T> extends IAtpPaginationDynamicTable<T> {
  breadCrumbs: IAtpSelectItem[];
}

export interface IAtpEntity {
  id: any;
  name: string;
}

export interface IAtpAnnotations {
  name?: string;
  baseGroupName: string;
  isOneType?: boolean;
  entryComponents?: { [key: string]: ComponentType<{}> };
  access?: IAtpAccess;
  rootBackUrl?: string;
  isPopup?: boolean;
  addButtonName?: string;
  notHeader?: boolean;
}

export interface IAtpAccess {
  parentName: string;
}

export function AtpDynamicTableFullCrud(annotations: IAtpAnnotations): any {
  return (target: any): any => {
    if (annotations.rootBackUrl && annotations.rootBackUrl.startsWith('/')) {
      annotations.rootBackUrl = annotations.rootBackUrl.substr(1);
    }

    let entryComponents = {};
    for (const key in annotations.entryComponents) {
      if (annotations.entryComponents.hasOwnProperty(key)) {
        const element = annotations.entryComponents[key];
        entryComponents[key.toUpperCase()] = element;
      }
    }

    annotations.entryComponents = entryComponents;
    target.__atpannotations__ = annotations;
    if (annotations.addButtonName === undefined) {
      annotations.addButtonName = 'Добавить';
    }
    return target;
  }
}

export class AtpAddEditData<TModel, TInfo> {
  constructor(
    public type: string,
    public model: TModel,
    public info: TInfo,
    public settings?: IAtpAddEditDataSettings,
    public params?: any
  ) {
    if (!this.settings) {
      this.settings = {};
    }

    this.settings.actionAddIcon = this.settings.actionAddIcon || 'add';
    this.settings.actionEditIcon = this.settings.actionEditIcon || 'mode_edit';
  }
}

/**
 * Настройки добавления/редактирования
 */
export interface IAtpAddEditDataSettings {
  isTree?: boolean;
  baseGroupName?: string;
  isNotResultPopup?: boolean;
  addApiName?: string;
  editApiName?: string;
  confirmButtonAddName?: string;
  confirmButtonEditName?: string;
  closedButtonName?: string;
  confirmButtonIsInvisible?: boolean;
  closedButtonIsInvisible?: boolean;
  actionAddIcon?: string;
  actionEditIcon?: string;
}

export class AtpAutocompleteItem implements IAtpSelectItem {
  id: any;
  value: string;
  constructor();
  constructor(obj: IAtpSelectItem);
  constructor(id: any, value: string);
  constructor(obj?: any, value?: string) {
    if (obj === undefined && value === undefined) {
      this.id = null;
      this.value = '';
    }
    else if (this.instanceOfIAtpSelectItem(obj)) {
      this.id = obj.id;
      this.value = !obj.value ? '' : value;
    }
    else {
      this.value = !value ? '' : value;
    }
  }

  private instanceOfIAtpSelectItem(object: any): object is IAtpSelectItem {
    return 'id' in object && 'value' in object;
  }

  toString(): string {
    return this.value;
  }
}

export class EventArgs<T, D> {
  constructor(public sender?: T, public e?: D) { }
}

export interface IAtpTableSortInfo {
  Active: string;
  Direction: string;
}

export class AtpSignalRHubEvent<T> extends Subject<T> {
  constructor(public name: string, public register?: (data: any) => T, public unregister?: () => void) {
    super();
  }
}

export class AtpSignalRHubConnection {
  protected connection: HubConnection;

  constructor(public name: string, protected api: AtpHttpService<IAtpUser>, protected httpErrors: AtpHttpErrorsService, protected events: AtpSignalRHubEvent<any>[] = [], public serverReadyDelayMs: number = 1000) { }

  getEvent<T>(name: string): Observable<T> {
    const result = this.events.find(x => x.name == name);
    return result ? (<Observable<T>>result.asObservable()) : null;
  }

  unsubscribeAllEvents() {
    for (const event of this.events) {
      event.observers.splice(0, event.observers.length);
    }
  }

  protected create(): void {
    this.connection = new HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.None)
      .withUrl((<any>this.api).baseAddress + 'Hubs/' + this.name, {
        accessTokenFactory: () => this.api.token
      })
      .build();
  }

  protected registerEvents(): void {
    for (const event of this.events) {
      this.connection.on(event.name, (data: string) => {
        if (event.register) {
          event.register(data);
        }
        event.next(data);
      });
    }
  }

  protected unregisterEvents(): void {
    for (const event of this.events) {
      if (event.unregister) {
        event.unregister();
      }

      this.connection.off(event.name);
    }
  }

  isConnection: boolean = false;

  public start(initName?: string, ...args: any[]): void {
    if (this.connection) {
      this.stop();
    }
    this.create();
    this.registerEvents();
    setTimeout(() => {
      this.connection.start().then(() => {
        this.isConnection = true;
        if (!environment.production) {
          console.log('Hub ' + this.name + ' connection started');
        }

        if (initName) {
          this.connection.invoke(initName, ...args);
        }

        // Событие сброса подключения
        this.connection.onclose((err) => {
          this.stop();
          if (err) {
            if (this.api.token) {
              this.start();
            }
          }
        });
      }).catch((err) => {
        if (('' + err).replace('Error: ', '') == "Unauthorized") {
          this.httpErrors.reauth(() => { this.start(initName, args); }, null);
          if (!environment.production) {
            console.log('Hub ' + this.name + ' ' + err);
          }
          return;
        }
        if (!environment.production) {
          console.log('Hub ' + this.name + ' ' + err);
        }
        this.connection = null;
      });
    }, this.serverReadyDelayMs);
  }

  public stop(): void {
    if (this.connection) {
      this.unregisterEvents();
      this.connection.stop();
      this.connection = null;
      if (!environment.production) {
        console.log('Hub ' + this.name + ' connection stoped');
      }
    }
  }
}

export class Guid {
  constructor(value: string) {
    value = value.toUpperCase();
    let reg = RegExp('[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}');
    if (reg.exec(value)) {
      this._value = value;
    }
  }

  private _value: string;

  public toString(): string {
    return this._value;
  }

  static newGuid(): Guid {
    var result: string;
    var i: string;
    var j: number;

    result = "";
    for (j = 0; j < 32; j++) {
      if (j == 8 || j == 12 || j == 16 || j == 20) {
        result = result + '-';
      }
      i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
      result = result + i;
    }
    return new Guid(result);
  }
}

export interface IAtpHandbook {
  name: string;
  code: string;
  groupId: number;
}

export interface IAtpHandbookGroup {
  id: number;
  name: string;
}
