import { Pipe, PipeTransform } from '@angular/core';
import { AtpDateTimeService } from '../components/atp-range-date-time-picker/atp-date-time.service';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  constructor(private _dateTimeService: AtpDateTimeService) { }

  transform(value: any, format: string): any {
    if (!format) return value;
    let arrFormat = format.split(': ');
    let type = arrFormat[0];
    switch (type) {
      case 'Array':
        {
          let result = '';
          for (let i = 0; i < value.length; i++) {
            result += i;
            if (i != value.length - 1) {
              result += ', ';
            }
          }
          return result;
        }
      case 'Date':
      case 'DateTime':
      case 'DateFullTime':
      case 'Time':
      case 'FullTime':
        {
          if (value && typeof (value) == 'string') {
            value = new Date(value);
            return this._dateTimeService.dateToFormatString(value, type);
          }
          else if (value instanceof Date) {
            return this._dateTimeService.dateToFormatString(value, type);
          }
        }
        break;
      case 'Boolean':
        {
          let values = arrFormat[1].split('} {').map(
            (value: string, index: number) => {
              if (index == 0) {
                return value.substr(1, value.length - 1);
              }
              return value.substr(0, value.length - 1);
            });
          return (<boolean>value) ? values[0] : values[1];
        }
      default:
        return this.customTransform(value, format);
    }

    return null;
  }

  customTransform(value: any, format: string): any {
    return 'Формат неизвестен';
  }

}
