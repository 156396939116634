import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtpInputNumberRangeComponent } from './atp-input-number-range.component';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [AtpInputNumberRangeComponent],
  exports: [
    AtpInputNumberRangeComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class AtpInputNumberRangeModule { }
