<input #fileInput type="file" style="display: none;" (change)="onValueChange($event)" [multiple]="multiple" />
<div class="atp-upload-file__container">
  <div class="atp-upload-file__dragndrop-box" *ngIf="!disabled"
    (click)="maxCount > selectedFiles.length && !disabled ? fileInput.click() : ''" (drop)="drop($event)"
    (dragover)="allowDrop($event)">

    <div class="atp-upload-file__dragndrop-box-info">
      <div class="atp-upload-file__dragndrop-box-image">
        <svg width="74" height="51" viewBox="0 0 74 51" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M59.6625 19.4567C57.5658 8.81921 48.2233 0.833374 37 0.833374C28.0892 0.833374 20.35 5.89004 16.4958 13.29C7.215 14.2767 0 22.1392 0 31.6667C0 41.8725 8.29417 50.1667 18.5 50.1667H58.5833C67.0933 50.1667 74 43.26 74 34.75C74 26.61 67.6792 20.0117 59.6625 19.4567ZM43.1667 28.5834V40.9167H30.8333V28.5834H21.5833L37 13.1667L52.4167 28.5834H43.1667Z"
            fill="#D3D9DE" />
        </svg>
      </div>
      <div class="atp-upload-file__dragndrop-box-description">
        <div class="atp-upload-file__dragndrop-box-description-title">
          {{display ? display : 'Загрузить документ' + (maxCount == 1 ? '' : 'ы')}}
        </div>
        <div class="atp-upload-file__dragndrop-box-description-text"
          [class.atp-upload-file__dragndrop-box-description-text_error]="haveSizeError">Максимальный размер файла:
          {{maxSize}} МБ</div>
        <div *ngIf="maxCount > 1" class="atp-upload-file__dragndrop-box-description-text"
          [class.atp-upload-file__dragndrop-box-description-text_selected]="maxCount == selectedFiles.length">
          Максимальное количество файлов: {{maxCount}}
        </div>
        <div *ngIf="formatsAllowed != '*'" class="atp-upload-file__dragndrop-box-description-text"
          [class.atp-upload-file__dragndrop-box-description-text_error]="haveFormatError">
          Допустимый формат файлов: {{formatsAllowed}}
        </div>
      </div>
    </div>

  </div>
  <div class="atp-upload-file__items">
    <atp-upload-file-item *ngFor="let selectedFile of selectedFiles; let i = index" [data]="selectedFile"
      (dataChange)="itemChange()" [disabled]="disabled" (remove)="removeFile(i)"></atp-upload-file-item>
  </div>
</div>