import { Component, ElementRef, Inject } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { HttpErrorsService } from 'src/app/services/http-errors.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ATP_DIALOG_DATA } from 'src/app/atp-core/components/atp-dialog/atp-dialog.service';
import { AtpAddEditData, IAtpAddEditInfo, AtpDynamicTableFullCrud } from 'src/app/atp-core/atp-core';
import { atpAnimations } from 'src/app/atp-core/atp-animations';
import { AtpDynamicFormComponent } from '../../atp-dynamic-table-full-crud/atp-dynamic-form/atp-dynamic-form.component';
import { Platform } from '@angular/cdk/platform';
import { ScheduleComponent } from 'src/app/settings/schedule/schedule.component';
@AtpDynamicTableFullCrud({
  name: 'ScheduleComponent',
  baseGroupName: 'ScheduleComponent',
  notHeader: true,
  entryComponents: {
    'ScheduleComponent': ScheduleComponent
  }
})
@Component({
  selector: 'atp-settings',
  templateUrl: '../../atp-dynamic-table-full-crud/atp-dynamic-form/atp-dynamic-form.component.html',
  styles: [
    `
    :host {
      display: block;
      position: relative;
      height: 100%;
    }
    `
  ],
  animations: [
    atpAnimations.OpenClose
  ]
})
export class AtpSettingsComponent extends AtpDynamicFormComponent<AtpAddEditData<any, IAtpAddEditInfo>> {

  constructor(
    public api: HttpService,
    httpErrors: HttpErrorsService,
    dialog: MatDialog,
    router: Router,
    // @Inject(ATP_DIALOG_DATA) data: AtpAddEditData<any, IAtpAddEditInfo>,
    elementRef: ElementRef<HTMLElement>,
    platform: Platform
  ) {
    super(null, api, httpErrors, dialog, router, elementRef, platform);
  }

  ngOnInit() {
    this.api.getSettingsV2().subscribe(result => {
      this.data = new AtpAddEditData<any, IAtpAddEditInfo>('SettingsV2', result.model, result);
      this.fillFormGroup();
      this.correctPropertiesInfo(this.data.info);
    });
  }

  confirm(result: any) {
  }

  close() {
  }
}
