import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AtpHttpService, IAtpUser } from '../services/atp-http.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

@Injectable()
export class AtpAuthGuard implements CanActivate {

  constructor(private _router: Router, private _api: AtpHttpService<IAtpUser>, private _location: Location) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this._api.user && this._api.token) {
      return true;
    }

    this._router.navigate(['/login/authorization']);
    return false;
  }

}