import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtpUploadFileComponent, AtpUploadFileErrorsService, AtpUploadFileItemComponent } from './atp-upload-file.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    AtpUploadFileComponent,
    AtpUploadFileItemComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule
  ],
  exports: [
    AtpUploadFileComponent
  ],
  providers: [
    AtpUploadFileErrorsService
  ]
})
export class AtpUploadFileModule { }
