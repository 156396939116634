import { environment } from 'src/environments/environment';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

export interface IAtpBasePageComponent {
  isBlockedPage: boolean;
}

@Component({ selector: '', template: '' })
export class AtpBasePageComponent implements OnDestroy, IAtpBasePageComponent {
  protected subscriptions: Subscription = new Subscription();

  private _isPreloaderShow = false;
  get isPreloaderShow(): boolean {
    return this._isPreloaderShow;
  }
  set isPreloaderShow(value: boolean) {
    this._isPreloaderShow = value;
  }

  private _countStarted: number = 0;

  private _blockTimer: any;
  protected _isBlockedPage = false;
  get isBlockedPage(): boolean {
    return this._isBlockedPage;
  }
  set isBlockedPage(value: boolean) {
    this.setIsBlockedPage(value);
  }

  protected setIsBlockedPage(value: boolean) {
    if (value) {
      if (this._countStarted == 0) {
        this._blockTimer = setTimeout(() => {
          this.isPreloaderShow = true;
        }, 500);
        this._isBlockedPage = value;
      }
      this._countStarted++;
    }
    else {
      this._countStarted--;
      if (this._countStarted == 0) {
        clearTimeout(this._blockTimer);
        this.isPreloaderShow = false;
        this._isBlockedPage = value;
      }
    }
  }

  openFile(url: string) {
    if (!url) return;
    window.open(environment.host + url, '_blank');
  }

  ngOnDestroy() {
    if (this.isBlockedPage) {
      this.isBlockedPage = false;
    }

    this.subscriptions.unsubscribe();
  }
}