import { Component, Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AtpHttpService, IAtpUser } from 'src/app/atp-core/services/atp-http.service';
import { AtpHttpErrorsService, AtpHttpErrorHandler } from 'src/app/atp-core/services/atp-http-errors.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, Observable, merge } from 'rxjs';
import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { AtpBasePageComponent } from 'src/app/atp-core/atp-base-page-component';
import { ATP_DIALOG_DATA } from '../../components/atp-dialog/atp-dialog.service';
import { AtpDialogRef } from '../../components/atp-dialog/atp-dialog-container.component';

export class AtpNodeTree {
  constructor(
    public id: string,
    public name: string,
    public parentId: any,
    public isParent: boolean,
    public level: number = 1,
    public isLoading: boolean = false,
    public isSelected: boolean = false,
    public isFolder = true
  ) { }
}

export class AtpMovementTreeData {
  constructor(
    public title: string,
    public id: number | string,
    public sections: Array<number>,
    public elements: Array<number>,
    public baseGroupName: string,
    public type: string,
    public rootName: string,
    public movingFolder: boolean
  ) { }
}

@Injectable()
export class AtpDynamicTreeDataSource {

  dataChange = new BehaviorSubject<AtpNodeTree[]>([]);

  get data(): AtpNodeTree[] { return this.dataChange.value; }
  set data(value: AtpNodeTree[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private treeControl: FlatTreeControl<AtpNodeTree>, private getNode: (node: AtpNodeTree, selectedNodeId: any, nodeLoading: NodeJS.Timer, func: (children: AtpNodeTree[]) => void) => void) { }

  public selectedNodeId: any = undefined;

  connect(collectionViewer: CollectionViewer): Observable<AtpNodeTree[]> {
    this.treeControl.expansionModel.changed!.subscribe(change => {
      if ((change as SelectionChange<AtpNodeTree>).added || (change as SelectionChange<AtpNodeTree>).removed) {
        this.handleTreeControl(change as SelectionChange<AtpNodeTree>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  handleTreeControl(change: SelectionChange<AtpNodeTree>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  toggleNode(node: AtpNodeTree, expand: boolean) {
    const index = this.data.indexOf(node);
    if (index < 0) {
      return;
    }

    if (expand) {
      let nodeLoading = setTimeout(() => {
        node.isLoading = true;
      }, 500);

      this.getNode(node, this.selectedNodeId, nodeLoading, ((children: AtpNodeTree[]) => {
        if (!children) {
          return;
        }

        children.forEach(element => {
          element.level = node.level + 1;
        });

        this.data.splice(index + 1, 0, ...children);

        this.dataChange.next(this.data);
      }).bind(this));
    }
    else {
      let count = 0;

      for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) { }

      this.data.splice(index + 1, count);

      this.dataChange.next(this.data);
    }
  }
}

@Component({
  selector: 'atp-movement-tree',
  templateUrl: './atp-movement-tree.component.html',
  host: {
    class: 'atp-movement-tree'
  }
})
export class AtpMovementTreeComponent extends AtpBasePageComponent {

  constructor(private _dialogRef: AtpDialogRef<AtpMovementTreeComponent>, @Inject(ATP_DIALOG_DATA) public data: AtpMovementTreeData,
    private _api: AtpHttpService<IAtpUser>, private _httpErrors: AtpHttpErrorsService) {
    super();
  }

  treeControl: FlatTreeControl<AtpNodeTree>;

  dataSource: AtpDynamicTreeDataSource;

  getLevel = (node: AtpNodeTree) => node.level;

  isExpandable = (node: AtpNodeTree) => node.isParent;

  hasChild = (_: number, _nodeData: AtpNodeTree) => _nodeData.isParent;

  ngOnInit() {
    this.treeControl = new FlatTreeControl<AtpNodeTree>(this.getLevel, this.isExpandable);
    this.dataSource = new AtpDynamicTreeDataSource(this.treeControl, this.getNode.bind(this));

    this.getRootNode();
  }

  private getRootNode() {
    this.isBlockedPage = true;
    this._api['get' + this.data.baseGroupName + 'NodeTree'](this.data.type, null).subscribe(
      (children: AtpNodeTree[]) => {
        //filter(x => this.data.movingFolder ? x.id != this.data.id : true)
        this.dataSource.data = children.map(x => {
          x.isParent = !!(<any>x).children.length;
          x.level = 0;
          return x;
        });
        this.isBlockedPage = false;
      },
      err => {
        this._httpErrors.process(err.status, () => { this.getRootNode(); }, this, [new AtpHttpErrorHandler(400, err.error)]);
      }
    );
  }

  getNode(node: AtpNodeTree, selectedNodeId: any, nodeLoading: NodeJS.Timer, func: (children: AtpNodeTree[]) => void) {
    this._api['get' + this.data.baseGroupName + 'NodeTree'](this.data.type, node.id).subscribe(
      (children: AtpNodeTree[]) => {
        //
        children = children
          // .filter(x => x.id != this.data.id)
          .map(x => {
            (<any>x).children = (<any>x).children;//.filter(x => x.id != this.data.id);
            x.isParent = !!(<any>x).children.length;
            return x;
          });

        if (selectedNodeId) {
          let selectedNode = children.find(x => x.id == selectedNodeId);
          if (selectedNode) selectedNode.isSelected = true;
        }

        func(children);

        clearTimeout(nodeLoading);
        node.isLoading = false;
      },
      err => {
        // clearTimeout(nodeLoading);
        // node.isLoading = false;

        this._httpErrors.process(err.status, () => { this.getNode(node, selectedNodeId, nodeLoading, func); }, this, [new AtpHttpErrorHandler(400, err.error)]);
      }
    );
  }

  nodeSelectClick(nodeId: number | string) {
    let selectedNode = this.dataSource.data.find(x => x.isSelected == true);
    if (selectedNode) selectedNode.isSelected = false;
    if (nodeId != null) {
      selectedNode = this.dataSource.data.find(x => x.id == nodeId);
      if (selectedNode) {
        selectedNode.isSelected = true;
      }
    }

    this.dataSource.selectedNodeId = nodeId;
  }

  submit() {
    this.isBlockedPage = true;

    let d = {
      'sections': this.data.sections,
      'elements': this.data.elements,
      'sectionId': this.dataSource.selectedNodeId
    };

    //this._api['movement' + this.data.baseGroupName + 'Tree'](this.data.type, this.data.id, this.dataSource.selectedNodeId).subscribe(
    this._api['movement' + this.data.baseGroupName + 'Tree'](this.data.type, d).subscribe(
      (result: string) => {
        this.isBlockedPage = false;
        this._dialogRef.close(true);
      },
      err => {
        this._httpErrors.process(err.status, () => { this.submit(); }, this, [new AtpHttpErrorHandler(400, err.error)]);
      }
    );
  }

  close() {
    this._dialogRef.close(false);
  }

}
