import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AtpTitleService {

  constructor(private _titleService: Title) {
  }

  private _title: string;

  setTitle(title: string, withoutPage: boolean = false) {
    this._title = title;
    if (!withoutPage) {
      this._titleService.setTitle(title);
    }
  }

  getTitle() {
    return this._title;
  }

}
