import { NgModule } from '@angular/core';
import { AtpNotificationsNewComponent } from './atp-notifications-new.component';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { AtpNotificationsNewService } from './atp-notifications-new.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {AtpNotificationNewComponent} from '../atp-notifications-new/atp-notification-new.component';
import {AtpDynamicTableFullCrudModule} from '../atp-dynamic-table-full-crud/atp-dynamic-table-full-crud.module';

@NgModule({
  declarations: [
    AtpNotificationsNewComponent,
    AtpNotificationNewComponent
  ],
  imports: [
    CommonModule,
    PortalModule,
    OverlayModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    AtpDynamicTableFullCrudModule
  ],
  exports: [
    AtpNotificationsNewComponent,
    AtpNotificationNewComponent
  ],
  providers: [
    AtpNotificationsNewService
  ]
})
export class AtpNotificationsNewModule {
}
