<input
  type="number"
  placeholder="От"
  [formControl]="formControlFrom"
>
<input
  matInput
  type="number"
  placeholder="До"
  [formControl]="formControlTo"
>
