import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type AtpTableActionsMenuType = 'Single' | 'Multiple' | 'Other';

export class AtpTableActionsMenu {
  constructor(public type: AtpTableActionsMenuType, public items?: IAtpTableActionsMenuItem[]) { }
  isDisabled = false;
}

export interface IAtpTableActionsMenuItem {
  name: string;
  icon: string;
  action: (model?: any) => void;
  items: IAtpTableActionsMenuItem[];
  needModel: boolean;
}

export class AtpTableActionsMenuItem implements IAtpTableActionsMenuItem {
  constructor(public name: string, public icon: string, public action: (model?: any) => void, public needModel: boolean = true, items?: IAtpTableActionsMenuItem[]) {
    this.items = items;
  }
  items: IAtpTableActionsMenuItem[];
}

export class AtpCustomTableActionsMenuItem<T> implements IAtpTableActionsMenuItem {
  constructor(action: (value: T, model?: any) => void, public field: string, public icon: string, public needModel: boolean = true) {
    this.action = function (model?: any): void {
      action(this.value, model);
    };
  }

  name: string;
  items: IAtpTableActionsMenuItem[];
  isInvisible: boolean = false;

  protected _value: T;
  get value(): T {
    return this._value;
  }
  set value(value: T) {
    this._value = value;
  }

  action: (model?: any) => void;
}

export class AtpEnabledTableActionsMenuItem extends AtpCustomTableActionsMenuItem<boolean> {
  constructor(action: (value: boolean, model?: any) => void, public activeName?: string, public deactiveName?: string, public activeIcon: string = 'toggle_on', public deactiveIcon: string = 'toggle_off') {
    super(action, 'isDisabled', null);
  }

  get value(): boolean {
    return this._value;
  }
  set value(value: boolean) {
    if (value) {
      this.name = this.activeName || 'Активировать';
      this.icon = this.activeIcon;
    }
    else {
      this.name = this.deactiveName || 'Деактивировать';
      this.icon = this.deactiveIcon;
    }
    this._value = value;
  }
}

@Component({
  selector: 'atp-table-actions-menu',
  templateUrl: './atp-table-actions-menu.component.html',
  host: {
    class: 'atp-table-actions-menu'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtpTableActionsMenuComponent {
  constructor() { }

  @Input() actionsMenuModel: AtpTableActionsMenu;
  @Input() model: any;
  @Input() icon: string = 'menu';
  @Input() buttonColor: 'basic' | 'primary' | 'accent' | 'warn' = 'basic';

  opened() {
    if (!this.model || !this.actionsMenuModel.items || this.actionsMenuModel.items.length == 0) return;

    let customItems = this.actionsMenuModel.items.filter(x => x instanceof AtpCustomTableActionsMenuItem);
    for (const customItem of customItems) {
      (<AtpCustomTableActionsMenuItem<any>>customItem).value = this.model[(<AtpCustomTableActionsMenuItem<any>>customItem).field];
    }
  }
}

@Component({
  selector: 'atp-table-actions-submenu',
  template: `<button mat-menu-item [matMenuTriggerFor]="submenu">
              <mat-icon>{{actionMenuItem.icon}}</mat-icon>
              <span>{{actionMenuItem.name}}</span>
            </button>
            <mat-menu #submenu="matMenu">
              <button *ngFor="let subitem of actionMenuItem.items" mat-menu-item (click)="subitem.action(model)">
                <mat-icon>{{subitem.icon}}</mat-icon>
                <span>{{subitem.name}}</span>
              </button>
            </mat-menu>`,
  host: {
    class: 'atp-table-actions-submenu'
  },
})

export class AtpTableActionsSubmenuComponent {
  constructor() { }

  @Input() actionMenuItem: AtpTableActionsMenuItem;
  @Input() model: any;
}
