import { OnInit, ViewChildren, QueryList, TemplateRef, HostBinding, Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { AtpBasePageComponent } from '../../atp-base-page-component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTab } from '@angular/material/tabs';
import { IAtpAddEditPropertyInfo, AtpAddEditData, AtpValidators, IAtpAddEditInfo, IAtpSelectItem, AppInjector, IAtpAddEditGroupPropertyInfo, IAtpAnnotations, EventArgs } from '../../atp-core';
import { Router } from '@angular/router';
import { FormGroup, ValidatorFn, FormControl, AbstractControl } from '@angular/forms';
import { AtpActionsPopup, AtpActionsPopupData } from '../../components/atp-actions-popup/atp-actions-popup.component';
import { AtpHttpService, IAtpUser } from '../../services/atp-http.service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AtpDateTimeService } from '../../components/atp-range-date-time-picker/atp-date-time.service';
import { AtpHttpErrorsService, AtpHttpErrorHandler } from '../../services/atp-http-errors.service';
import { ATP_DIALOG_DATA } from '../../components/atp-dialog/atp-dialog.service';
import { AtpSectionDirective } from '../../components/atp-section/atp-section.directive';
import { Platform } from '@angular/cdk/platform';

import { AngularEditorConfig } from '@kolkov/angular-editor';

interface IInvisibleGroup {
  name: string;
  groupName: string;
  items: IInvisibleControl[];
}

interface IInvisibleControl {
  name: string;
  controlType: string;
  params: any[];
  control: AbstractControl;
  group?: IAtpAddEditGroupPropertyInfo;
}

@Component({ selector: '', template: '' })
export abstract class AtpDynamicFormComponent<T extends AtpAddEditData<any, IAtpAddEditInfo>> extends AtpBasePageComponent implements OnInit {
  protected dateTimeService: AtpDateTimeService = AppInjector.get(AtpDateTimeService);

  constructor(@Inject(ATP_DIALOG_DATA) data: T, protected api: AtpHttpService<IAtpUser>, protected httpErrors: AtpHttpErrorsService, protected dialog: MatDialog, protected router: Router, protected elementRef: ElementRef<HTMLElement>,
    protected platform: Platform) {
    super();
    this.data = data;
  }

  @HostBinding('@.disabled') private _animationDisabled = true;

  private _data: T;
  get data(): T {
    return this._data;
  }
  set data(value: T) {
    if (value) {
      // Для правильной отработки на странице
      this._animationDisabled = true;
      setTimeout(() => {
        this._animationDisabled = false;
      }, 0);
    }
    this._data = value;
  }

  // private _oldTitle: string;

  fullHeightContent = false;

  getTemplateRef(templateRefs: { [key: string]: TemplateRef<any> }, controlType: string): TemplateRef<any> {
    if (controlType == 'File') {
      controlType = 'MultipleFile';
    }
    else if (controlType.includes('Date') || controlType.includes('Time')) {
      controlType = 'RangeDateTime';
    }
    else if (controlType == 'Number') {
      controlType = 'Input';
    }
    return templateRefs[controlType];
  }

  get annotations(): IAtpAnnotations {
    return (<any>this.constructor).__atpannotations__;
  }

  form: FormGroup;
  allControls: { [key: string]: AbstractControl } = {};

  protected invisibleControls: IInvisibleGroup[] = [];

  items: Array<IAtpAddEditPropertyInfo | IAtpAddEditGroupPropertyInfo>;

  masks: { [key: string]: Array<string | RegExp> } = {};

  autocompletes: { [key: string]: IAtpSelectItem[] } = {};
  multipleAutocompleteForm = new FormGroup({});

  multipleAutocompleteRemove(name: string, item: any) {
    this.form.controls[name].value.splice(this.form.controls[name].value.findIndex(x => x.id == item.id), 1);
    this.form.controls[name].setValue(this.form.controls[name].value);
  }

  multipleAutocompleteSelected(name: string, event: MatAutocompleteSelectedEvent) {
    this.autocompletes[name] = [];
    this.form.controls[name].setValue(this.form.controls[name].value ? [...this.form.controls[name].value, event.option.value] : [event.option.value]);
  }

  protected autocomplete(name: string, value: string, isMultiple = false) {
    let excludedIds: any[] = null;
    if (isMultiple && this.form.controls[name].value) {
      excludedIds = this.form.controls[name].value.map(x => x.id);
    }
    const subscription = (<Observable<IAtpSelectItem[]>>this.api[`autocomplete${name.substr(0, 1).toUpperCase()}${name.substr(1)}`](value, excludedIds)).subscribe(
      (data: IAtpSelectItem[]) => {
        for (const item of data) {
          (<any>item).toString = (): string => { return item.value };
        };
        this.autocompletes[name] = data;
        subscription.unsubscribe();
      },
      (err) => {
        this.httpErrors.process(err.status, () => { this.autocomplete(name, value, isMultiple); }, null, [new AtpHttpErrorHandler(400, err.error)]);
        subscription.unsubscribe();
      }
    );

    this.subscriptions.add(subscription);
  }

  protected autocompleteBlur(name: string) {
    if (typeof (this.form.controls[name].value) == 'string') {
      this.form.controls[name].setValue('');
    }
  }

  protected getItemsFormControlsForInvisibleControls(items: Array<IAtpAddEditPropertyInfo | IAtpAddEditGroupPropertyInfo>, group?: IAtpAddEditGroupPropertyInfo): IInvisibleControl[] {
    let result = [];
    for (const item of items) {
      if ((<any>item).items) {
        result.push(...this.getItemsFormControlsForInvisibleControls((<any>item).items, (<IAtpAddEditGroupPropertyInfo>item).bind ? <IAtpAddEditGroupPropertyInfo>item : null));
      }
      else {
        let newItem: IInvisibleControl;

        const name = item.name;
        if (!this.form.controls[name] && group && (<IAtpAddEditPropertyInfo>item).controlType != 'Custom') {
          newItem = this.invisibleControls.find(x => x.name == group.name)?.items.find(x => x.name == name);
        }
        else {
          newItem = {
            name: item.name,
            controlType: (<IAtpAddEditPropertyInfo>item).controlType,
            params: (<IAtpAddEditPropertyInfo>item).params,
            control: this.form.controls[name],
            group: group
          };
        }

        if (newItem.controlType != 'Custom' && !newItem.control) {
          throw 'DynamicForms getItemsFormControlsForInvisibleControls control is undefined';
        }
        result.push(newItem);
      }
    }
    return result;
  }

  protected getGroupByName(name: string, groups?: IAtpAddEditGroupPropertyInfo[]): IAtpAddEditGroupPropertyInfo {
    if (!groups) {
      groups = this.data.info.groups;
    }

    let result: IAtpAddEditGroupPropertyInfo;
    for (const group of groups) {
      if (group.name == name) {
        result = group;
        break;
      }
      else {
        if (group.items && group.items) {
          const nextGroups = <IAtpAddEditGroupPropertyInfo[]>group.items.filter(x => (<IAtpAddEditGroupPropertyInfo>x).items);
          if (nextGroups.length > 0) {
            result = this.getGroupByName(name, nextGroups);
            if (result) {
              break;
            }
          }
        }
      }
    }
    return result;
  }

  @ViewChildren('tabItem') set tabItems(value: QueryList<MatTab>) {
    setTimeout(() => {
      for (const group of value.toArray()) {
        const info: IAtpAddEditGroupPropertyInfo = <any>group.textLabel;
        const parentInfo: IAtpAddEditGroupPropertyInfo = this.getGroupByName(info.groupName);

        if (!parentInfo?.params?.length || (parentInfo.params[0] != 'OnlyActive' && this.invisibleControls.find(x => x.name == info.name))) {
          continue;
        }

        let invisibleControl = this.invisibleControls.find(x => x.name == info.name);

        if (!invisibleControl) {
          invisibleControl = {
            name: info.name,
            groupName: info.groupName,
            items: this.getItemsFormControlsForInvisibleControls(info.items, info)
          };
          this.invisibleControls.push(invisibleControl);
        }

        if (!group.isActive) {
          info.isVisible = false;

          this.removeInvisibleControls(invisibleControl.items);
        }
        else {
          info.isVisible = true;
        }
      }
    });
  }

  protected addInvisibleControls(items: IInvisibleControl[]) {
    for (const item of items) {
      if ((!item.group || item.group.isVisible) && item.control) {
        this.form.addControl(item.name, item.control);
      }
    }
  }

  protected removeInvisibleControls(items: IInvisibleControl[]) {
    for (const item of items) {
      this.form.removeControl(item.name);
    }
  }

  protected resetBindingGroupInTabItem(groups: IAtpAddEditGroupPropertyInfo[]) {
    for (const group of groups) {
      (group).isVisible = false;
      setTimeout(() => {
        (group).isVisible = true;
      });
    }
  }

  protected onMatTabChangeEvent(event: MatTabChangeEvent) {
    const info = <any>event.tab.textLabel;
    info.isVisible = true;
    const groups = this.invisibleControls.filter(x => x.groupName == info.groupName && x.name != info.name);
    if (!groups || !groups.length) return;
    for (const group of groups) {
      this.getGroupByName(group.name).isVisible = false;
      this.removeInvisibleControls(group.items);
    }

    this.addInvisibleControls(this.invisibleControls.find(x => x.name == info.name).items);

    this.resetBindingGroupInTabItem(info.items.filter(x => x.bind && x.isVisible));
  }

  /**
   * Заполнение групп, корректировка значений
   * @param info
   */
  protected correctPropertiesInfo(info: IAtpAddEditInfo) {
    for (const group of info.groups) {
      if (group.type == 'Tabs' && group.name == 'Root') {
        this.fullHeightContent = true;
      }

      group.items = info.properties.filter(x => x.groupName == group.name);
      if (group.type == 'TabItem') {
        group.toString = () => group.display;
        group.form = this.form;
      }
    }

    for (const group of info.groups) {
      if (group.groupName) {
        const parent = info.groups.find(x => x.name == group.groupName);
        parent.items = parent.items.concat([group]);
      }
      if (group.bind) {
        const arrBind = (group.bind.substr(0, 1).toLowerCase() + group.bind.substr(1)).split(': ');

        let invisibleItems = this.getItemsFormControlsForInvisibleControls(group.items, group);
        this.invisibleControls.push({ name: group.name, groupName: group.groupName, items: invisibleItems });

        let name = arrBind[0][0] == '!' ? arrBind[0].substr(1) : arrBind[0];
        name = name.substr(0, 1).toLowerCase() + name.substr(1);

        const getSelectsPropertyName: () => string = () => {
          const selectsPropertyName = arrBind[1].substr(0, arrBind[1].indexOf('{') - 1);
          return selectsPropertyName.substr(0, 1).toLowerCase() + selectsPropertyName.substr(1);
        };

        const getSelectsPropertyValue: () => string = () => {
          const value = arrBind[1].substr(arrBind[1].indexOf('{'));
          return value.substr(1, value.length - 2);
        };

        let formControl = this.form.controls[name] || this.invisibleControls.find(x => !!x.items.find(y => y.name == name)).items.find(x => x.name == name).control;

        let bindPropertyInfo = info.properties.find(x => x.name == name);
        let isVisible = arrBind.length == 1 ? !!formControl.value : false;
        if (arrBind.length > 1) {
          if (bindPropertyInfo.isHidden) {
            const firstIndex = group.bind.indexOf('{');
            const lastIndex = group.bind.lastIndexOf('}');
            if (firstIndex > -1 && lastIndex > -1) {
              const value = group.bind.substr(firstIndex + 1, lastIndex - firstIndex - 1);
              isVisible = value && formControl.value && formControl.value == value;
            }
          }
          else {
            switch (bindPropertyInfo.controlType) {
              case 'MultipleSelect':
                {
                  let baseItem = bindPropertyInfo.selectItems.find(x => x[getSelectsPropertyName()] == getSelectsPropertyValue());
                  isVisible = baseItem && formControl.value && formControl.value.find(x => x == baseItem.id);
                }
                break;
              case 'Select':
                {
                  let baseItem = bindPropertyInfo.selectItems.find(x => x[getSelectsPropertyName()] == getSelectsPropertyValue());
                  isVisible = baseItem && formControl.value && formControl.value == baseItem.id;
                }
                break;
              case 'MultipleAutocomplete':
                isVisible = formControl.value && formControl.value.find(x => x[getSelectsPropertyName()] == getSelectsPropertyValue());
                break;
              case 'Autocomplete':
                isVisible = formControl.value && formControl.value[getSelectsPropertyName()] == getSelectsPropertyValue();
                break;
            }
          }
        }

        const visibleAction: () => void = () => {
          if (group.isVisible) this.addInvisibleControls(invisibleItems);
          else this.removeInvisibleControls(invisibleItems);
        };

        group.isVisible = arrBind[0][0] == '!' ? !isVisible : isVisible;
        visibleAction();

        formControl.valueChanges.subscribe(
          (value: any) => {
            if (arrBind.length == 1) {
              group.isVisible = arrBind[0][0] == '!' ? !value : value;
            }
            else {
              switch (bindPropertyInfo.controlType) {
                case 'MultipleSelect':
                  {
                    let baseItem = bindPropertyInfo.selectItems.find(x => x[getSelectsPropertyName()] == getSelectsPropertyValue());
                    if (baseItem) {
                      group.isVisible = value.find(x => x == baseItem.id);
                    }
                  }
                  break;
                case 'Select':
                  {
                    let baseItem = bindPropertyInfo.selectItems.find(x => x[getSelectsPropertyName()] == getSelectsPropertyValue());
                    if (baseItem) {
                      group.isVisible = value == baseItem.id;
                    }
                  }
                  break;
                case 'MultipleAutocomplete':
                  {
                    group.isVisible = !!value.find(x => x[getSelectsPropertyName()] == getSelectsPropertyValue());
                  }
                  break;
                case 'Autocomplete':
                  {
                    group.isVisible = value[getSelectsPropertyName()] == getSelectsPropertyValue();
                  }
                  break;
              }
            }
            visibleAction();
          }
        );
      }
      else {
        group.isVisible = true;
      }
    }

    for (const group of info.groups) {
      group.items.sort((x, y) => x.order > y.order ? 1 : x.order < y.order ? -1 : 0);
    }

    this.items = [].concat(this.data.info.properties, this.data.info.groups)
      .filter(x => !x.groupName)
      .sort((x, y) => x.order > y.order ? 1 : x.order < y.order ? -1 : 0);
  }

  /**
   * Заполнение form
   */
  protected fillFormGroup() {
    this.form = new FormGroup({});

    for (let i = 0; i < this.data.info.properties.length; i++) {
      const info = this.data.info.properties[i];
      let regularExpressionMask = info.validationsInfo.find(x => x.name.split('_')[0] == 'regularExpression' && x.params.length > 1);
      if (regularExpressionMask != null) {
        this.masks[info.name] = regularExpressionMask.params[1].map((x: string) => x.length > 1 ? new RegExp(x.replace(/\//g, '')) : x);
      }
      let validations: ValidatorFn[] = info.validationsInfo.map(x => this.getValidator(x.name, x.params, info.controlType));

      switch (info.controlType) {
        case 'Input':
          {
            this.form.addControl(info.name, new FormControl(!this.data.model ? info.defaultValue : this.data.model[info.name], info.display ? info.validationsInfo.map(x => this.getValidator(x.name, x.params, info.controlType)) : []));

            if (info.isDisabled) {
              this.form.controls[info.name].disable();
            }
          }
          break;
        case 'DateTime':
        case 'Date':
        case 'Time':
        case 'RangeDateTime':
        case 'RangeDate':
        case 'RangeTime':
          {
            let value: Date | [Date, Date];
            let defaultValue: Date | [Date, Date];

            switch (info.controlType) {
              case 'Time':
              case 'Date':
              case 'DateTime':
                if (this.data.model) {
                  value = new Date(this.data.model[info.name]);
                }
                if (info.defaultValue) {
                  defaultValue = new Date(info.defaultValue);
                }
                break;
              case 'RangeTime':
              case 'RangeDate':
              case 'RangeDateTime':
                if (this.data.model) {
                  if (this.data.model[info.name] && this.data.model[info.name].length) {
                    value = [new Date(this.data.model[info.name][0]), new Date(this.data.model[info.name][1])];
                  }
                }
                if (info.defaultValue && info.defaultValue.length) {
                  defaultValue = [null, null];
                  if (info.defaultValue[0]) {
                    defaultValue[0] = new Date(info.defaultValue[0]);
                  }
                  if (info.defaultValue[1]) {
                    defaultValue[1] = new Date(info.defaultValue[1]);
                  }
                }
                break;
            }

            let name = info.controlType.substr(0, 1).toLowerCase() + info.controlType.substr(1);
            if (name.substr(0, 5) == 'range') {
              validations.push()
            }

            this.form.addControl(info.name, new FormControl(!this.data.model ? defaultValue : value, validations));

            if (info.isDisabled) {
              this.form.controls[info.name].disable();
            }
          }
          break;
        case 'Autocomplete':
          {
            if (this.data.model && this.data.model[info.name]) {
              this.data.model[info.name].toString = () => this.data.model[info.name].value;
            }
            if (info.defaultValue) {
              info.defaultValue.toString = () => info.defaultValue.value;
            }
            this.form.addControl(info.name, new FormControl(!this.data.model ? info.defaultValue : this.data.model[info.name], validations));

            if (info.isDisabled) {
              this.form.controls[info.name].disable();
            }

            this.form.controls[info.name].valueChanges.pipe(debounceTime(500)).subscribe(
              (value: any) => {
                if (!value) {
                  this.autocompletes[info.name] = [];
                }
                else if (typeof (value) === 'string') {
                  this.autocomplete(info.name, value);
                }
              }
            );
          }
          break;
        case 'MultipleAutocomplete':
          {
            this.form.addControl(info.name, new FormControl('', validations));

            if (info.isDisabled) {
              this.form.controls[info.name].disable();
            }

            if (this.data.model && this.data.model[info.name]) {
              for (const item of this.data.model[info.name]) {
                item.toString = () => item.value;
              }
              this.form.controls[info.name].setValue(this.data.model[info.name]);
            }
            if (info.defaultValue) {
              for (const item of info.defaultValue) {
                item.toString = () => item.value;
              }
              if (!this.data.model) {
                this.form.controls[info.name].setValue(info.defaultValue);
              }
            }
            this.multipleAutocompleteForm.addControl(info.name, new FormControl(''));
            this.multipleAutocompleteForm.controls[info.name].valueChanges.pipe(debounceTime(500)).subscribe(
              (value: any) => {
                if (!value) {
                  this.autocompletes[info.name] = [];
                }
                else if (typeof (value) === 'string') {
                  this.autocomplete(info.name, value, true);
                }
              }
            );
          }
          break;
        case 'MultipleFile':
        case 'File':
          {
            validations.push(AtpValidators.checkFiles());
          }
        case 'Number':
        case 'Select':
        case 'MultipleSelect':
        case 'Password':
        case 'Boolean':
        case 'Textarea':
        case 'Editor':
        case 'MultipleFile':
        case 'Schedule':
        case 'File':
          {
            this.form.addControl(info.name, new FormControl(!this.data.model ? info.defaultValue : this.data.model[info.name], validations));

            if (info.isDisabled) {
              this.form.controls[info.name].disable();
            }
          }
          break;
        case 'Custom':
          {
            this.correctResults[info.name] = { action: (result: any) => { } };
          }
          break;
        case 'Display':
          {
            this.form.addControl(info.name, new FormControl(!this.data.model ? info.defaultValue : this.data.model[info.name], info.display ? info.validationsInfo.map(x => this.getValidator(x.name, x.params, info.controlType)) : []));

            // if (info.isDisabled) {
              this.form.controls[info.name].disable();
            // }
          }
          break;
      }
    }

    for (const key in this.form.controls) {
      this.allControls[key] = this.form.controls[key];
    }
  }

  ngOnInit() {
    //console.log(this.data);
    this.fillFormGroup();
    this.correctPropertiesInfo(this.data.info);
  }

  protected getValidator(name: string, params: any[], type: string): ValidatorFn {
    switch (name.split('_')[0]) {
      case 'required':
        return AtpValidators.required;
      case 'emailAddress':
        return AtpValidators.emailAddress;
      case 'maxLength':
        return AtpValidators.maxLength(params[0]);
      case 'minLength':
        return AtpValidators.minLength(params[0]);
      case 'stringLength':
        return AtpValidators.stringLength(params[0], params[1]);
      case 'regularExpression':
        return AtpValidators.regularExpression(params[0], name);
      case 'compare':
        return AtpValidators.compare(this.form, params[0]);
      case 'inn':
        return AtpValidators.inn(params[0]);
      case 'ogrn':
        return AtpValidators.ogrn(params[0]);
      case 'kpp':
        return AtpValidators.kpp;
      case 'okpo':
        return AtpValidators.okpo;
      case 'rangeDateTime':
        return AtpValidators.rangeDateTime(this.dateTimeService, type);
    }
  }

  checkItemRequired(fieldInfo: IAtpAddEditPropertyInfo): boolean {
    return fieldInfo.validationsInfo.find(x => x.name == 'required') ? true : false;
  }

  getErrorMessage(fieldInfo: IAtpAddEditPropertyInfo): string {
    if (!this.form || !this.form.controls[fieldInfo.name] || !this.form.controls[fieldInfo.name].invalid) {
      return null;
    }

    for (const key in this.form.controls[fieldInfo.name].errors) {
      if (this.form.controls[fieldInfo.name].errors[key.toLowerCase()]) {
        let error = fieldInfo.validationsInfo.find(x => x.name.toLowerCase() == key);
        if (error) {
          return error.errorMessage;
        }
      }
    }

    return null;
  }

  submit(): void {
    console.log(this.form);
    console.log(this.form.getRawValue());
    console.log(this.correctResultValues(this.form.getRawValue()));
    this.validation(this.form);

    if (this.form.invalid) return;

    this.sendData(this.correctResultValues(this.form.getRawValue()));
  }

  protected correctResultValues(object: any): any {
    let result = {};
    for (const group of this.data.info.groups) {
      if (group.type == 'Group' && group.bind) {
        result['isVisible' + group.name] = group.isVisible ? true : false;
      }
      else if (group.type == 'Tabs' && group.params && group.params.length && group.params[0] == 'OnlyActive') {
        result['active' + group.name + 'TabItem'] = group.items.find(x => x.groupName == group.name && (<any>x).isVisible).name;
      }
    }
    for (const item of this.data.info.properties) {
      if (this.form.controls[item.name]) {
        switch (item.controlType) {
          case 'Date':
          case 'Time':
          case 'DateTime':
            result[item.name] = object[item.name] ? (<Date>object[item.name]).toUTCString() : null;
            break;
          case 'RangeDate':
          case 'RangeTime':
          case 'RangeDateTime':
            if (!object[item.name] || object[item.name].length < 2) {
              break;
            }
            result[item.name] = object[item.name][0] && object[item.name][1] ? [object[item.name][0].toUTCString(), object[item.name][1].toUTCString()] : null;
            break;
          case 'Autocomplete':
            result[item.name] = object[item.name] ? { id: object[item.name].id } : null;
            break;
          case 'MultipleAutocomplete':
            result[item.name] = object[item.name] ? object[item.name].map(x => { return { id: x.id.toString() } }) : null;
            break;
          case 'Boolean':
            result[item.name] = object[item.name] ? object[item.name] : false;
            break;
          case 'Input':
          case 'Number':
          case 'Select':
          case 'MultipleSelect':
          case 'Password':
          case 'Textarea':
          case 'Editor':
          case 'MultipleFile':
          case 'File':
          case 'Custom':
          case 'Schedule':
            if (object[item.name]) {
              result[item.name] = object[item.name];
            }
            break;
        }
      }
    }

    if (this.data.settings && this.data.settings.isTree) {
      result['parentId'] = this.data.model ? this.data.model.parentId : this.data.params ? this.data.params.parentId : null;
    }

    for (const key in this.correctResults) {
      if (this.correctResults.hasOwnProperty(key)) {
        const element = this.correctResults[key];
        element.action(result);
      }
    }

    return result;
  }

  protected correctResults: { [key: string]: { action: (result: any) => void } } = {};

  protected validation(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
      if (formGroup.controls[key] instanceof FormControl) {
        formGroup.controls[key].markAsTouched();
      }
      else if (formGroup.controls[key] instanceof FormGroup) {
        this.validation(<FormGroup>formGroup.controls[key]);
      }
    }
  }

  /**
   * Отправка данных
   * @param formData
   */
  protected sendData(data: any) {
    this.isBlockedPage = true;
    let apiName = !this.data.settings ? null : !this.data.model ? this.data.settings.addApiName : this.data.settings.editApiName;
    if (!apiName) {
      apiName = (!this.data.model ? 'add' : 'edit') + (this.data.settings && this.data.settings.baseGroupName ? this.data.settings.baseGroupName : this.data.type[0].toUpperCase() + this.data.type.substr(1));
    }

    console.log(apiName);

    this.subscriptions.add(this.api[apiName](this.data.type, data).subscribe(
      (result: string) => {
        this.isBlockedPage = false;
        if (this.data.settings && this.data.settings.isNotResultPopup) {
          this.confirm(result);
        }
        else {
          let data = new AtpActionsPopupData(this.data.info.title, result, 'Закрыть', null);
          const dialogRef = this.dialog.open(AtpActionsPopup, {
            width: '450px',
            data: data,
          });

          this.subscriptions.add(dialogRef.afterClosed().subscribe(resultDialog => {
            this.confirm(resultDialog);
          }));
        }
      },
      (err) => {
        this.httpErrors.process(err.status, () => { this.sendData(data); }, this, [new AtpHttpErrorHandler(400, err.error)]);
      }
    ));
  }

  abstract confirm(result: string): void;
  abstract close(): void;

  protected getEntryComponent(name: string) {
    if (this.annotations && this.annotations.entryComponents) {
      if (this.annotations.entryComponents[name.toUpperCase()]) {
        return this.annotations.entryComponents[name.toUpperCase()];
      }
    }
    console.error(name + ' компонент не помещен в entryComponents');
    return null;
  }

  getTemplateName(type: string) {
    return 'inputFormControl';
  }

  private _topSectionSubscription: Subscription;
  private _topSection: AtpSectionDirective;
  @ViewChild('topSection', { read: AtpSectionDirective }) get topSection(): AtpSectionDirective {
    return this._topSection;
  }
  set topSection(value: AtpSectionDirective) {
    if (!this.platform.TRIDENT && !this.platform.SAFARI) {
      if (value) {
        if (this._topSectionSubscription) {
          this._topSectionSubscription.unsubscribe();
        }
        this.contentMaxHeight = (value.height + (this.bottomSection?.height ?? 0)).toString();
        this._topSectionSubscription = value.heightChanged.subscribe(
          (data: EventArgs<AtpSectionDirective, number>) => {
            this.contentMaxHeight = (data.e + (this.bottomSection?.height ?? 0)).toString();
          }
        );

        this.subscriptions.add(this._topSectionSubscription);
      }

      this._topSection = value;
    }
  }

  private _bottomSectionSubscription: Subscription;
  private _bottomSection: AtpSectionDirective;
  @ViewChild('bottomSection', { read: AtpSectionDirective }) get bottomSection(): AtpSectionDirective {
    return this._bottomSection;
  }
  set bottomSection(value: AtpSectionDirective) {
    if (!this.platform.TRIDENT && !this.platform.SAFARI) {
      if (value) {
        if (this._bottomSectionSubscription) {
          this._bottomSectionSubscription.unsubscribe();
        }
        this.contentMaxHeight = (value.height + (this.topSection?.height ?? 0)).toString();
        this._bottomSectionSubscription = value.heightChanged.subscribe(
          (data: EventArgs<AtpSectionDirective, number>) => {
            this.contentMaxHeight = (data.e + (this.topSection?.height ?? 0)).toString();
          }
        );

        this.subscriptions.add(this._bottomSectionSubscription);
      }

      this._bottomSection = value;
    }
  }

  private _baseComponentHeight: number;

  private _contentMaxHeight: string = '0';
  get contentMaxHeight(): string {
    return this._contentMaxHeight;
  }
  set contentMaxHeight(value: string) {
    setTimeout(() => {
      if (+value < window.screen.height / 2) {
        if (this.elementRef) {
          if (!this._baseComponentHeight) {
            this._baseComponentHeight = this.elementRef.nativeElement.offsetHeight;
          }
          this._contentMaxHeight = `calc(${this._baseComponentHeight}px - ${value}px)`;
        }
        else {
          this._contentMaxHeight = `calc(100vh - ${value}px)`;
        }
      }
      else {
        this._contentMaxHeight = `50vh`;
      }
    });
  }
}
