import { Injectable } from '@angular/core';
import { AtpHttpErrorsService } from '../atp-core/services/atp-http-errors.service';
import { HttpService } from './http.service';
import { Location } from '@angular/common';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorsService extends AtpHttpErrorsService {

  constructor(api: HttpService, location: Location, private _router: Router) {
    super(api, location)
  }

  protected dialogActionData: MatDialogConfig<any> = { width: '450px' };

  moveToAuth(): void {
    this._router.navigate(['/login/authorization']);
  }
}
