<div class="atp-dynamic-form__shit" (click)="close()">
  Закрыть
  <mat-icon>close</mat-icon>
</div>

<div *ngIf="data" class="atp-history-item-detail__container">
  <atp-title class="atp-history-item-detail__title"
    text="№{{data.model.entityId}} от {{data.model.date | format : 'DateFullTime'}}"
    [beforeIcon]="data.model.actionIcon" notSetInHeader="true">
  </atp-title>

  <div class="atp-history-item-detail__content">
    <table class="atp-history-item-detail-table">
      <tbody>
        <ng-container *ngIf="data.model.json?.properties?.length">
          <tr *ngFor="let property of data.model.json?.properties"
            class="atp-history-item-detail-table__row atp-history-item-detail-table__row_property">
            <td
              class="atp-history-item-detail-table__cell atp-history-item-detail-table__cell_property atp-history-item-detail-table__cell_first">
              {{property.description || property.name}}</td>
            <td
              class="atp-history-item-detail-table__cell atp-history-item-detail-table__cell_property atp-history-item-detail-table__cell_second">
              {{property.value}}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="data.model.json?.links?.length">
          <tr *ngFor="let link of data.model.json?.links"
            class="atp-history-item-detail-table__row atp-history-item-detail-table__row_link">
            <td
              class="atp-history-item-detail-table__cell atp-history-item-detail-table__cell_link atp-history-item-detail-table__cell_first">
              {{link.description || link.name}}</td>
            <td
              class="atp-history-item-detail-table__cell atp-history-item-detail-table__cell_link atp-history-item-detail-table__cell_second">
              {{link.linkId}}</td>
          </tr>
        </ng-container>
        <!-- Правки названий классов -->
        <ng-container *ngIf="data.model.json?.listLinks?.length">
          <tr *ngFor="let listLink of data.model.json?.listLinks"
            class="atp-history-item-detail-table__row atp-history-item-detail-table__row_link">
            <td
              class="atp-history-item-detail-table__cell atp-history-item-detail-table__cell_link atp-history-item-detail-table__cell_first">
              {{listLink.description || listLink.name}}</td>
            <td
              class="atp-history-item-detail-table__cell atp-history-item-detail-table__cell_link atp-history-item-detail-table__cell_second">
              <span *ngFor="let linkId of listLink.linkIds; let i = index">{{linkId}}{{i != listLink.linkIds.length - 1
                ? ', ' : ''}}</span>
            </td>
          </tr>
        </ng-container>

        <ng-template #partialLinkTemplate let-partialLink="partialLink">
          <td
            class="atp-history-item-detail-table__cell atp-history-item-detail-table__cell_partial-link atp-history-item-detail-table__cell_first">
            <mat-icon>{{partialLink.actionIcon}}</mat-icon>
          </td>
          <td
            class="atp-history-item-detail-table__cell atp-history-item-detail-table__cell_partial-link atp-history-item-detail-table__cell_second">
            <table class="atp-history-item-detail-partial-link">
              <thead>
                <tr class="atp-history-item-detail-partial-link__header-row">
                  <th colspan="2" class="atp-history-item-detail-partial-link__header-cell">{{partialLink.name}}</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="partialLink.properties?.length">
                  <tr *ngFor="let property of partialLink.properties"
                    class="atp-history-item-detail-partial-link__row atp-history-item-detail-partial-link__row_property">
                    <td
                      class="atp-history-item-detail-partial-link__cell atp-history-item-detail-partial-link__cell_property atp-history-item-detail-partial-link__cell_first">
                      {{property.description || property.name}}</td>
                    <td
                      class="atp-history-item-detail-partial-link__cell atp-history-item-detail-partial-link__cell_property atp-history-item-detail-partial-link__cell_second">
                      {{property.value}}</td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="partialLink.links?.length">
                  <tr *ngFor="let link of partialLink.links"
                    class="atp-history-item-detail-partial-link__row atp-history-item-detail-partial-link__row_link">
                    <td
                      class="atp-history-item-detail-partial-link__cell atp-history-item-detail-partial-link__cell_link atp-history-item-detail-partial-link__cell_first">
                      {{link.description || link.name}}</td>
                    <td
                      class="atp-history-item-detail-partial-link__cell atp-history-item-detail-partial-link__cell_link atp-history-item-detail-partial-link__cell_second">
                      {{link.linkId}}</td>
                  </tr>
                </ng-container>
                <!-- Правки названий классов -->
                <ng-container *ngIf="partialLink.listLinks?.length">
                  <tr *ngFor="let listLink of partialLink.listLinks"
                    class="atp-history-item-detail-partial-link__row atp-history-item-detail-partial-link__row_link">
                    <td
                      class="atp-history-item-detail-partial-link__cell atp-history-item-detail-partial-link__cell_link atp-history-item-detail-partial-link__cell_first">
                      {{listLink.description || listLink.name}}</td>
                    <td
                      class="atp-history-item-detail-partial-link__cell atp-history-item-detail-partial-link__cell_link atp-history-item-detail-partial-link__cell_second">
                      <span *ngFor="let linkId of listLink.linkIds; let i = index">{{linkId}}{{i !=
                        listLink.linkIds.length - 1 ? ', ' : ''}}</span>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="partialLink.partialLinks?.length">
                  <tr *ngFor="let partLink of partialLink.partialLinks"
                    class="atp-history-item-detail-partial-link__row atp-history-item-detail-partial-link__row_partial-link">
                    <ng-template [ngTemplateOutlet]="partialLinkTemplate"
                      [ngTemplateOutletContext]="{partialLink: partLink}"></ng-template>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </td>
        </ng-template>

        <ng-container *ngIf="data.model.json?.partialLinks?.length">
          <tr *ngFor="let partialLink of data.model.json?.partialLinks"
            class="atp-history-item-detail-table__row atp-history-item-detail-table__row_partial-link">
            <ng-template [ngTemplateOutlet]="partialLinkTemplate"
              [ngTemplateOutletContext]="{partialLink: partialLink}">
            </ng-template>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

</div>
