import { Component, OnInit, Inject } from '@angular/core';
import { IAtpHistory, AtpHistoryDetailData } from '../../atp-core';
import { AtpBasePageComponent } from '../../atp-base-page-component';
import { AtpHttpService, IAtpUser } from '../../services/atp-http.service';
import { AtpHttpErrorsService } from '../../services/atp-http-errors.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ATP_DIALOG_DATA, AtpDialogService } from '../../components/atp-dialog/atp-dialog.service';
import { AtpDialogRef } from '../../components/atp-dialog/atp-dialog-container.component';
import { AtpHistoryComponent } from '../atp-history/atp-history.component';

@Component({
  selector: 'atp-history-item-detail',
  templateUrl: './atp-history-item-detail.component.html',
  host: {
    class: 'atp-history-item-detail'
  }
})
export class AtpHistoryItemDetailComponent<TEntity extends IAtpHistory> extends AtpBasePageComponent implements OnInit {

  constructor(protected api: AtpHttpService<IAtpUser>, protected httpErrors: AtpHttpErrorsService, protected dialog: MatDialog, protected router: Router,
    @Inject(ATP_DIALOG_DATA) public data: AtpHistoryDetailData, protected dialogRef: AtpDialogRef<AtpHistoryComponent<IAtpHistory>>, protected atpDialog: AtpDialogService) {
    super();
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
