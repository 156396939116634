import { BrowserModule } from '@angular/platform-browser';
import {NgModule, Injector, APP_INITIALIZER} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Error404Component } from './error404/error404.component';
import { SharedModulesModule } from './shared-modules/shared-modules.module';
import { HttpClientModule } from '@angular/common/http';
import { AtpMergeParamsAndQueryParamsResolver, setAppInjector, AtpLowerCaseUrlSerializer } from './atp-core/atp-core';
import { UrlSerializer } from '@angular/router';
import { HttpService } from './services/http.service';
import { HttpErrorsService } from './services/http-errors.service';
import { AtpHttpService } from './atp-core/services/atp-http.service';
import { AtpHttpErrorsService } from './atp-core/services/atp-http-errors.service';
import { MatNativeDateModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { SignalrService } from './services/signalr.service';
import { AtpAuthGuard } from './atp-core/guards/atp-auth.guard';
import { AtpAnonimusGuard } from './atp-core/guards/atp-anonimus.guard';
import { AtpNotificationsModule } from './atp-core/atp-notifications/atp-notifications.module';
import { AtpNotificationsNewModule } from './atp-core/atp-notifications-new/atp-notifications-new.module';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { DevService } from './services/dev.service';
import { MainComponent } from './main/main.component';
import { NotificationsPopupComponent } from './main/notifications-popup/notifications-popup.component';
import { AtpTitleService } from './atp-core/services/atp-title.service';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { DecimalPipe } from 'src/app/pipes/decimal.pipe';
import {isModuleWithProvidersNotGeneric} from '@angular/core/schematics/migrations/module-with-providers/util';
import { AtpSettingsComponent } from './atp-core/components/atp-settings/atp-settings.component';
import {InitService} from './services/init.service';
import { HttpClient } from '@angular/common/http';
import {Subscribable, Subscription} from 'rxjs';

export function loadConfigurations(configService: InitService): any  {
  return () => configService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
    NavMenuComponent,
    MainComponent,
    NotificationsPopupComponent,
    AtpSettingsComponent,
  ],
  imports: [
    AngularEditorModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModulesModule,
    HttpClientModule,
    MatNativeDateModule,
    AtpNotificationsModule,
    AtpNotificationsNewModule
  ],
  providers: [
    AtpAuthGuard,
    AtpAnonimusGuard,
    { provide: UrlSerializer, useClass: AtpLowerCaseUrlSerializer },
    { provide: AtpHttpService, useClass: HttpService },
    { provide: AtpHttpErrorsService, useClass: HttpErrorsService },
    AtpMergeParamsAndQueryParamsResolver,

    SignalrService,
    DevService,
    AtpTitleService,
    DecimalPipe,
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigurations,
      deps: [InitService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
