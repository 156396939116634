<div class="atp-dynamic-form__container">
  <atp-title class="atp-dynamic-form__title" [text]="data.title" notSetInHeader="false">
  </atp-title>

  <div mat-dialog-content class="atp-dynamic-form__content">
    <button mat-button class="atp-movement-tree__button atp-movement-tree__button_root"
      [color]="dataSource.selectedNodeId === null ? 'primary' : 'basic'" (click)="nodeSelectClick(null)">
      <mat-icon class="atp-movement-tree__button-internal-icon">folder</mat-icon>{{data.rootName}}
    </button>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node class="atp-movement-tree__node" *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <button mat-button class="atp-movement-tree__button atp-movement-tree__button_folder"
          [color]="node.isSelected ? 'primary' : 'basic'" (click)="nodeSelectClick(node.id)">
          <mat-icon>folder</mat-icon>
          {{node.name}}
        </button>
      </mat-tree-node>
      <mat-tree-node class="atp-movement-tree__node" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button class="atp-movement-tree__button" [attr.aria-label]="'toggle ' + node.filename"
          matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <button mat-button class="atp-movement-tree__button atp-movement-tree__button_folder"
          [color]="node.isSelected ? 'primary' : 'basic'" (click)="nodeSelectClick(node.id)">
          <mat-icon>folder</mat-icon>
          {{node.name}}
        </button>
        <mat-progress-bar *ngIf="node.isLoading" class="atp-movement-tree__progress-bar" mode="indeterminate">
        </mat-progress-bar>
      </mat-tree-node>
    </mat-tree>
  </div>

  <div mat-dialog-actions class="atp-dynamic-form__actions">
    <button mat-raised-button class="atp-dynamic-form__actions-button atp-dynamic-form__actions-button_submit"
      color="primary" [disabled]="dataSource.selectedNodeId === undefined" (click)="submit()">Подтвердить</button>
    <button mat-stroked-button class="atp-dynamic-form__actions-button atp-dynamic-form__actions-button_close"
      (click)="close()">Отменить</button>
  </div>
</div>