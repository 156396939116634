import { Injectable, InjectionToken } from '@angular/core';

export type AtpDateTimeType = 'DateTime' | 'DateFullTime' | 'Date' | 'Time' | 'FullTime';
export type AtpRangeDateTimeType = 'RangeDateTime' | 'RangeDateFullTime' | 'RangeDate' | 'RangeTime' | 'RangeFullTime';

export interface AtpDateTimePickerCalendarInitData {
  startDate: Date;
  firstDayOfWeek: number;
  maxValue: Date;
  minValue: Date;
}

export const ATP_DATE_TIME_PICKER_CALENDAR_INIT_DATA = new InjectionToken<AtpDateTimePickerCalendarInitData>('atp_date_time_picker_calendar_init_data');

@Injectable({
  providedIn: 'root'
})
export class AtpDateTimeService {
  toAnyDigit(value: number, count: number): string {
    let result = '';
    for (let i = 0; i < count; i++) {
      result += '0';
    }
    result += value;
    return result.substr(result.length - count, count);
  }

  dateToFormatString(value: Date, type: AtpDateTimeType): string {
    if (!value) return null;
    switch (type) {
      case 'Date':
        return `${this.toAnyDigit(value.getDate(), 2)}.${this.toAnyDigit(value.getMonth() + 1, 2)}.${this.toAnyDigit(value.getFullYear(), 4)}`;
      case 'Time':
        return `${this.toAnyDigit(value.getHours(), 2)}:${this.toAnyDigit(value.getMinutes(), 2)}`;
      case 'FullTime':
        return `${this.toAnyDigit(value.getHours(), 2)}:${this.toAnyDigit(value.getMinutes(), 2)}:${this.toAnyDigit(value.getSeconds(), 2)}`;
      case 'DateTime':
        return `${this.dateToFormatString(value, 'Date')} ${this.dateToFormatString(value, 'Time')}`;
      case 'DateFullTime':
        return `${this.dateToFormatString(value, 'Date')} ${this.dateToFormatString(value, 'FullTime')}`;
    }
  }

  datesRangeFormatString(valueFrom: Date, valueTo: Date, type: AtpRangeDateTimeType): string {
    if (!valueFrom || !valueTo) return null;
    return `${this.dateToFormatString(valueFrom, <AtpDateTimeType>type.replace('Range', ''))}${this.getSplitDateTimeRange()}${this.dateToFormatString(valueTo, <AtpDateTimeType>type.replace('Range', ''))}`;
  }

  getSplitDateTimeRange() {
    return ' — ';
  }

  formatStringToDate(value: string, type: AtpDateTimeType): Date {
    if (!value) return null;
    switch (type) {
      case 'Date':
        {
          let arrDate = value.split('.');
          return new Date(+arrDate[2], (+arrDate[1] - 1), +arrDate[0]);
        }
      case 'Time':
        {
          let arrTime = value.split(':');
          return new Date(2000, 0, 1, +arrTime[0], +arrTime[1], 0, 0);
        }
      case 'FullTime':
        {
          let arrTime = value.split(':');
          return new Date(0, 0, 1, +arrTime[0], +arrTime[1], +arrTime[2], 0);
        }
      case 'DateTime':
        {
          let arrDateTime = value.split(' ');
          if (!arrDateTime[1]) {
            return null;
          }
          let arrDate = arrDateTime[0].split('.');
          let arrTime = arrDateTime[1].split(':');
          return new Date(+arrDate[2], (+arrDate[1] - 1), +arrDate[0], +arrTime[0], +arrTime[1], 0, 0);
        }
      case 'DateFullTime':
        {
          let arrDateTime = value.split(' ');
          if (!arrDateTime[1]) {
            return null;
          }
          let arrDate = arrDateTime[0].split('.');
          let arrTime = arrDateTime[1].split(':');
          return new Date(+arrDate[2], (+arrDate[1] - 1), +arrDate[0], +arrTime[0], +arrTime[1], +arrTime[2], 0);
        }
    }
  }

  formatStringToDatesRange(value: string, type: AtpRangeDateTimeType): [Date, Date] {
    if (!value) return null;
    console.log(['formatStringToDatesRange', value]);
    const arr = value.split(this.getSplitDateTimeRange());
    const resultFrom: Date = this.formatStringToDate(arr[0], <AtpDateTimeType>type.replace('Range', ''));
    const resultTo: Date = this.formatStringToDate(arr[1], <AtpDateTimeType>type.replace('Range', ''));
    return resultFrom && resultTo ? [resultFrom, resultTo] : null;
  }

  protected monthNames: string[] = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
  protected dayShortNames: string[] = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

  getMonthName(date: Date): string {
    return this.monthNames[date.getMonth()];
  }

  getDayShortName(number: number): string {
    return this.dayShortNames[number];
  }
}
