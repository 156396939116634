import {
  Component,
  Input,
  Optional,
  Self,
  ElementRef,
  ChangeDetectionStrategy,
  OnDestroy,
  Output,
  EventEmitter,
  Injectable,
  DoCheck,
  HostBinding
} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NgControl} from '@angular/forms';
import { AtpHttpService, IAtpUser } from '../../services/atp-http.service';
import { HttpResponse } from '@angular/common/http';
import { AtpHttpErrorsService } from '../../services/atp-http-errors.service';
import { AtpDateTimeService } from '../atp-range-date-time-picker/atp-date-time.service';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Observable, Subject, Subscription } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {IAtpSelectedFile} from '../atp-upload-file/atp-upload-file.component';
import {AngularEditorConfig} from '@kolkov/angular-editor';

@Component({
  selector: 'atp-editor',
  template: `<angular-editor [(ngModel)]="value" [config]="config"></angular-editor>`,
  providers: [{provide: MatFormFieldControl, useExisting: AtpEditorComponent}],
  host: {
    'class': 'atp-editor',
    '[attr.aria-describedby]': 'describedBy'
  },
})

export class AtpEditorComponent implements OnDestroy{

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    sanitize: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold'],
      ['insertImage', 'insertVideo', 'toggleEditorMode']
    ]
  };

  stateChanges = new Subject<void>();

  private _value:string;
  @Input()
  get value(): string | null {
    return this._value;
  }
  set value(value: string | null) {
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
    this.stateChanges.next();
  }

  constructor(@Optional() @Self() public ngControl: NgControl){

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  static nextId = 0;
  @HostBinding() id = `atp-editor-${AtpEditorComponent.nextId++}`;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  writeValue(value: any): void {
    this.value = value;
  }

  onChange = (_: any) => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  describedBy = '';
  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  get errorState(): boolean {
    return this.ngControl.invalid && this.ngControl.touched;
  }

  controlType = 'Editor';

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
