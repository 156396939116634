import {IAtpFilterInfo, IAtpSelectItem, IAtpPropertyInfo, AtpAutocompleteItem} from '../../atp-core';

export abstract class AtpFiltersPanelItem<T> {
  constructor(public type: string) {
  }

  name: string;
  value: T;
  defaultValue: T;
  display?: string;
  isVisible?: boolean;
  isVisibleDefault?: boolean;
}

export class AtpFiltersPanelInput extends AtpFiltersPanelItem<string | number> {
  constructor(info: IAtpFilterInfo, params: any);
  constructor(name: string);
  constructor(name: string, display: string);
  constructor(name: string, display: string);
  constructor(name: string, display: string, defaultValue: string | number);
  constructor(name: string, display: string, defaultValue: string | number, type: 'String' | 'Number');
  constructor(name: string, display: string, defaultValue: string | number, type: 'String' | 'Number', mask: (string | RegExp)[]);
  constructor(name: string, display: string, defaultValue: string | number, type: 'String' | 'Number', mask: (string | RegExp)[], isSearch: boolean);
  constructor(name: string, display: string, defaultValue: string | number, type: 'String' | 'Number', mask: (string | RegExp)[], isSearch: boolean, isVisibleDefault: boolean);
  constructor(param1: any, param2?: any, defaultValue: string | number = '', type: 'String' | 'Number' = 'String', mask?: (string | RegExp)[], isSearch: boolean = false, isVisibleDefault: boolean = false) {
    super(type);
    if (typeof (param1) == 'string') {
      this.name = param1;
      this.display = param2;
      this.defaultValue = defaultValue;
      this.isSearch = isSearch;
      this.isVisibleDefault = isVisibleDefault;
      this.mask = mask ? mask.map(x => {
        if (typeof (x) == 'string') {
          if (x.length > 1) {
            x = new RegExp(x.replace(/\//g, ''));
          }
        }
        return x;
      }) : null;
    }
    else {
      this.name = (<IAtpFilterInfo>param1).name;
      this.display = (<IAtpFilterInfo>param1).display;
      this.defaultValue = (<IAtpFilterInfo>param1).defaultValue;
      this.isSearch = (<IAtpFilterInfo>param1).isSearch;
      this.isVisibleDefault = (<IAtpFilterInfo>param1).isVisibleDefault;
      this.mask = param1 && param1.mask ? param1.mask.map(x => {
        if (typeof (x) == 'string') {
          if (x.length > 1) {
            x = new RegExp(x.replace(/\//g, ''));
          }
        }
        return x;
      }) : null;
    }
    this.value = this.defaultValue;
  }

  mask: (string | RegExp)[];
  isSearch: boolean;
  isVisibleDefault: boolean;
}

export class AtpFiltersPanelSelect extends AtpFiltersPanelItem<IAtpSelectItem> {
  constructor(info: IAtpFilterInfo, params: any);
  constructor(name: string);
  constructor(name: string, options: IAtpSelectItem[]);
  constructor(name: string, options: IAtpSelectItem[], display: string);
  constructor(name: string, options: IAtpSelectItem[], display: string, defaultValue: IAtpSelectItem);
  constructor(name: string, options: IAtpSelectItem[], display: string, defaultValue: IAtpSelectItem, isVisibleDefault: boolean);
  constructor(param1: any, param2?: any, display?: string, defaultValue: IAtpSelectItem = undefined, isVisibleDefault: boolean = false) {
    super('Select');

    if (typeof (param1) == 'string') {
      this.name = param1;
      this.options = param2;
      this.display = display;
      this.defaultValue = defaultValue;
      this.isVisibleDefault = isVisibleDefault;
    }
    else {
      this.type = (<IAtpFilterInfo>param1).type;
      this.name = (<IAtpFilterInfo>param1).name;
      this.options = (<IAtpFilterInfo>param1).selectItems;
      this.display = (<IAtpFilterInfo>param1).display;
      this.defaultValue = (<IAtpFilterInfo>param1).defaultValue;
      this.isVisibleDefault = (<IAtpFilterInfo>param1).isVisibleDefault;
      this.tree = (<IAtpFilterInfo>param1).tree;
    }
    this.value = this.defaultValue;
  }

  options: IAtpSelectItem[];
  tree: Array<object>;
}

export class AtpFiltersPanelBoolean extends AtpFiltersPanelSelect {
}

export class AtpFiltersPanelDateTime extends AtpFiltersPanelItem<string[]> {
  constructor(info: IAtpFilterInfo, params: any);
  constructor(name: string);
  constructor(name: string, display: string);
  constructor(name: string, display: string, defaultValue: string[]);
  constructor(name: string, display: string, defaultValue: string[], isVisibleDefault: boolean);
  constructor(param1: any, param2?: any, public defaultValue: string[] = null, isVisibleDefault: boolean = false) {
    super('DateTime')

    if (typeof (param1) == 'string') {
      this.name = param1;
      this.display = param2;
      this.defaultValue = defaultValue;
      this.isVisibleDefault = isVisibleDefault;
    }
    else {
      this.name = (<IAtpFilterInfo>param1).name;
      this.display = (<IAtpFilterInfo>param1).display;
      this.defaultValue = (<IAtpFilterInfo>param1).defaultValue;
      this.isVisibleDefault = (<IAtpFilterInfo>param1).isVisibleDefault;
    }
    this.value = this.defaultValue;
  }
}

export class AtpFiltersPanelAutocomplete extends AtpFiltersPanelItem<AtpAutocompleteItem> {
  constructor(
    public name: string,
    public autocompleteEventHandler: (event: any) => void,
    public display?: string,
    public icon?: string,
    public defaultValue: AtpAutocompleteItem = new AtpAutocompleteItem(),
    public passTime: number = 200) {
    super('Autocomplete');
    this.value = new AtpAutocompleteItem(this.defaultValue);
  }
  readonly type: string = 'Autocomplete';
}

export class AtpFiltersPanelNumberRange extends AtpFiltersPanelItem<string[]> {
  constructor(info: IAtpFilterInfo, params: any);
  constructor(name: string);
  constructor(name: string, display: string);
  constructor(name: string, display: string, defaultValue: string[]);
  constructor(name: string, display: string, defaultValue: string[], isVisibleDefault: boolean);
  constructor(param1: any, param2?: any, public defaultValue: string[] = null, isVisibleDefault: boolean = false) {
    super('NumberRange');
    if (typeof (param1) == 'number') {
      this.name = param1.toString();
      this.display = param2;
      this.defaultValue = defaultValue;
      this.isVisibleDefault = isVisibleDefault;
    }
    else {
      this.name = (<IAtpFilterInfo>param1).name;
      this.display = (<IAtpFilterInfo>param1).display;
      this.defaultValue = (<IAtpFilterInfo>param1).defaultValue;
      this.isVisibleDefault = (<IAtpFilterInfo>param1).isVisibleDefault;
    }
    this.value = this.defaultValue;
  }
}

function getFilterItem(filterInfo: IAtpFilterInfo, flatFiltersInfo: IAtpFilterInfo[], component: any): AtpFiltersPanelItem<any> {
  let result: AtpFiltersPanelItem<any>;
  switch (filterInfo.type) {
    case 'Boolean':
      result = new AtpFiltersPanelBoolean(filterInfo, { flatFiltersInfo, component });
      break;
    case 'Number':
    case 'String':
      result = new AtpFiltersPanelInput(filterInfo, { flatFiltersInfo, component });
      break;
    case 'Select':
      result = new AtpFiltersPanelSelect(filterInfo, { flatFiltersInfo, component });
      break;
    case 'MultipleSelect':
      result = new AtpFiltersPanelSelect(filterInfo, { flatFiltersInfo, component });
      break;
    case 'DateTime':
      result = new AtpFiltersPanelDateTime(filterInfo, { flatFiltersInfo, component });
      break;
    case 'Autocomplete':
      result = new AtpFiltersPanelAutocomplete(
        filterInfo.name,
        (<(event: any) => void>component[filterInfo.name + 'AutocompleteEventHandler']).bind(component),
        filterInfo.display,
        filterInfo.icon,
        filterInfo.defaultValue ? new AtpAutocompleteItem(filterInfo.defaultValue[0], filterInfo.defaultValue[1]) : new AtpAutocompleteItem()
      );
      break;
    case 'NumberRange':
      result = new AtpFiltersPanelNumberRange(filterInfo, { flatFiltersInfo, component });
      break;

  }
  return result;
}

export function getFiltersPanelItems(propertiesInfo: IAtpPropertyInfo[], component: any): AtpFiltersPanelItem<any>[] {
  let flatFilterItems: IAtpFilterInfo[] = [];

  for (const property of propertiesInfo) {
    flatFilterItems = flatFilterItems.concat(property.filtersInfo);
  }

  let result: AtpFiltersPanelItem<any>[] = [];
  for (const filter of flatFilterItems) {
    if (!filter.groupName) {
      const f = getFilterItem(filter, flatFilterItems, component);
      result.push(f);
    }
  }
  return result;
}
