import { ViewContainerRef } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { ScrollStrategy } from '@angular/cdk/overlay';

export type DialogRole = 'dialog' | 'alertdialog';

export interface IDialogPosition {
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

export class AtpDialogConfig<D = any> {
  viewContainerRef?: ViewContainerRef;
  id?: string;
  role?: DialogRole = 'dialog';
  panelClass?: string | string[] = '';
  hasBackdrop?: boolean = true;
  backdropClass?: string = '';
  disableClose?: boolean = false;
  width?: string = '';
  height?: string = '100%';
  minWidth?: number | string;
  minHeight?: number | string = '100%';
  maxWidth?: number | string = '90vw';
  maxHeight?: number | string;
  position?: IDialogPosition = { right: '0', top: '0' };
  data?: D | null = null;
  direction?: Direction;
  ariaDescribedBy?: string | null = null;
  ariaLabelledBy?: string | null = null;
  ariaLabel?: string | null = null;
  autoFocus?: boolean = true;
  restoreFocus?: boolean = true;
  scrollStrategy?: ScrollStrategy;
  closeOnNavigation?: boolean = true;
}