import {Component, HostBinding, Input, OnInit, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {AbstractControl, FormControl, NgControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Subject, Subscription} from 'rxjs';

@Component({
  selector: 'atp-input-phone',
  templateUrl: './atp-input-phone.component.html',
  styleUrls: ['./atp-input-phone.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: AtpInputPhoneComponent}],
})
export class AtpInputPhoneComponent implements OnInit {

  masks = [
    {
      label: 'Россия',
      code: 'RU',
      mask: ['+', '7', ' ', '(' , /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
      icon: 'assets/flags/ru.png',
      pattern: '\\+7 \\(\\d{3}\\) \\d{3} \\d{2} \\d{2}',
      phoneCode: '+7'
    },
    {
      label: 'Беларусь',
      code: 'BY',
      mask: ['+', '3', '7', '5', ' ', '(' , /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
      icon: 'assets/flags/by.png',
      pattern: '\\+375 \\(\\d{2}\\) \\d{3} \\d{2} \\d{2}',
      phoneCode: '+375'
    },
    {
      label: 'Казахстан',
      code: 'KZ',
      mask: ['+', '7', ' ', '(' , /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
      icon: 'assets/flags/kz.png',
      pattern: '\\+7 \\(\\d{3}\\) \\d{3} \\d{2} \\d{2}',
      phoneCode: '+7'
    },
    {
      label: 'Китай',
      code: 'CH',
      mask: ['+', '8', '6', ' ', '(' , /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
      icon: 'assets/flags/ch.png',
      pattern: '\\+86 \\(\\d{3}\\) \\d{4} \\d{4}',
      phoneCode: '+86'
    }
  ];

  selectedMask;

  public formControl = new FormControl('', []);
  stateChanges = new Subject<void>();
  private sub = new Subscription();
  public disabled = false;

  private _value:string;
  @Input()
  get value(): string | null {
    return this._value;
  }

  set value(value: string | null) {
    this._value = value;
    this.onChange(this._value);
    this.onTouched();
    this.stateChanges.next();
  }

  constructor(@Optional() @Self() public ngControl: NgControl){

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.selectedMask = this.masks[0];
  }

  validator(): ValidatorFn | null{
    return (ctrl: AbstractControl): ValidationErrors | null  =>  {
      const pattern = this.selectedMask.pattern;
      const reg = pattern instanceof RegExp ? pattern : new RegExp(pattern);
      if (!reg.test(ctrl.value)){
        return { phone : true };
      }
      return null;
    };
  }

  @Input()
  get placeholder() {
    return this._placeholder ? this._placeholder : '';
  }
  set placeholder(plh) {
    this._placeholder = plh ? plh : '';
    this.stateChanges.next();
  }
  private _placeholder: string;

  ngOnInit(): void {
    this.sub = this.linkForm();
    this.ngControl.control.setValidators(this.validator());
  }

  private linkForm(): Subscription {
    return this.formControl.valueChanges.subscribe(value => {
      this.onTouched();
      this.onChange(value);
    });
  }

  writeValue(value: any): void {
    if (value) {
      this.onTouched();
    }

    if (value && value[0] !== '+'){
      value = '+' + value;
      for (let mask of this.masks){
        if(value.includes(mask.phoneCode)){
          this.selectedMask = mask;
          break;
        }
      }
    }

    this.formControl.setValue(value, { emitEvent: false });
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    disabled
      ? this.formControl.disable()
      : this.formControl.enable();
  }

  onChange = (_: any) => { };
  onTouched: any = () => { };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  describedBy = '';
  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  get errorState(): boolean {
    if  (this.ngControl) {
      return this.ngControl.invalid && this.ngControl.touched;
    }
    return false;
  }

  controlType = 'Editor';

  ngOnDestroy() {
    this.stateChanges.complete();
    this.sub.unsubscribe();
  }

  changeCountry(e){
    this.formControl.setValue('');
    this.onTouched();
    this.onChange(this.formControl.value);
  }
}
