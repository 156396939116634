import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AtpNotificationComponent } from '../atp-core/atp-notifications/atp-notification.component';
import { AtpNotificationsService, AtpNotificationsSettings, IAtpNotificationData } from '../atp-core/atp-notifications/atp-notifications.service';
import { DevService } from '../services/dev.service';
import { HttpService } from '../services/http.service';
import { SignalrService } from '../services/signalr.service';

// TODO: Вынести в базовый класс вместе с main
@Component({
  selector: 'error404',
  templateUrl: './error404.component.html',
  host: {
    class: 'error404'
  }
})
export class Error404Component implements OnInit, OnDestroy {

  constructor(public api: HttpService, private _signalr: SignalrService, private _notifications: AtpNotificationsService, private _devService: DevService, breakpointObserver: BreakpointObserver) {
    const layoutChanges = breakpointObserver.observe([
      '(orientation: portrait)',
      '(orientation: landscape)',
    ]);

    this.isNotDesktop = breakpointObserver.isMatched('(max-width: 1024px)');

    this._layoutChangesSubscription = layoutChanges.subscribe(result => {
      this.isNotDesktop = !breakpointObserver.isMatched('(max-width: 1024px)');
    });
  }

  isNotDesktop: boolean;

  private _layoutChangesSubscription = Subscription.EMPTY;
  private _signalrMessagesSubscription = Subscription.EMPTY;
  ngOnInit() {
    const settings = new AtpNotificationsSettings(AtpNotificationComponent);
    if (this.api.user) {
      if (!this._devService.isDevMode || this._devService.isSocketsOn) {
        this._signalr.messages.start();
        this._signalrMessagesSubscription = this._signalr.messages.getEvent('SubscriptionMessage').subscribe(
          (data) => {
            this._notifications.push(settings, { data: <IAtpNotificationData>data });
          }
        );
      }
    }
  }

  ngOnDestroy(): void {
    if (!this._devService.isDevMode || this._devService.isSocketsOn) {
      this._signalr.messages.stop();
    }

    this._layoutChangesSubscription.unsubscribe();
    this._signalrMessagesSubscription.unsubscribe();
  }

}
