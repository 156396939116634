import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AtpHttpService, IAtpUser } from '../services/atp-http.service';

@Injectable()
export class AtpAnonimusGuard implements CanActivate {

  constructor(private _router: Router, private _api: AtpHttpService<IAtpUser>) { }

  canActivate() {
    if (this._api.token) {
      return false;
    }

    return true;
  }
}