import { ComponentPortal } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AtpNotificationComponent } from 'src/app/atp-core/atp-notifications/atp-notification.component';
import { AtpNotificationsComponent } from 'src/app/atp-core/atp-notifications/atp-notifications.component';
import { IAtpNotificationData, AtpNotificationsService, AtpNotificationsSettings } from 'src/app/atp-core/atp-notifications/atp-notifications.service';
import { AtpDialogRef } from 'src/app/atp-core/components/atp-dialog/atp-dialog-container.component';
import { SignalrService } from 'src/app/services/signalr.service';

import { AtpNotificationsNewComponent } from 'src/app/atp-core/atp-notifications-new/atp-notifications-new.component';

@Component({
  selector: 'notifications-popup',
  templateUrl: './notifications-popup.component.html',
  host: {
    class: 'notifications-popup'
  }
})
export class NotificationsPopupComponent implements OnInit, OnDestroy {

  constructor(
    private _notifications: AtpNotificationsService,
    private _signalr: SignalrService,
    private _dialogRef: AtpDialogRef<NotificationsPopupComponent>
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  /*
  private _container: AtpNotificationsComponent;
  @ViewChild('container', { read: AtpNotificationsComponent }) get container(): AtpNotificationsComponent {
    return this._container;
  }
  set container(val: AtpNotificationsComponent) {
    this._container = val;
  }

  notificationsComponent: ComponentPortal<AtpNotificationsComponent>;

  private _signalrMessagesSubscription = Subscription.EMPTY;
  ngOnInit(): void {
    this._signalrMessagesSubscription = this._signalr.messages.getEvent('SubscriptionMessage').subscribe(
      (data) => {
        this.container.addMessage(settings, { data: <IAtpNotificationData>data });
      }
    );

    const settings = new AtpNotificationsSettings(AtpNotificationComponent, Number.MAX_VALUE);

    setTimeout(() => {
      this._notifications.notViewsMessages.forEach(x => {
        this.container.addMessage(settings, { data: x.data });
      });
    });

    this._dialogRef.afterClosed().subscribe(() => {
      this._notifications.notViewsMessages = [];
    });
  }

  close() {
    this._dialogRef.close(false);
  }

  ngOnDestroy() {
    this._signalrMessagesSubscription.unsubscribe();
  }
*/
}
