import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAtpSelectItem, IAtpHistory } from '../atp-core';

export interface IAtpUnitAccess {
  id: any;
  internalName: string;
  parentId: any;
  canAdd: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canDisabled: boolean;
  canReadHistory: boolean;
  isParent: boolean;
}

export interface IAtpUser {
  login: string;
  token: string;
  tokenLifeTime: Date;
  refreshToken: string;
  refreshTokenLifeTime: Date;
  accesses: IAtpUnitAccess[];
}

export abstract class AtpHttpService<T extends IAtpUser> {

  constructor(protected http: HttpClient) { }

  protected abstract baseAddress: string;

  get user(): T {
    return JSON.parse(window.localStorage.getItem('user'));
  }
  set user(value: T) {
    window.localStorage.setItem("user", JSON.stringify(value));
  }

  get token(): string {
    const user = this.user;
    return user ? user.token : null;
  }

  get tokenLifeTime(): Date {
    const user = this.user;
    return user ? user.tokenLifeTime : null;
  }

  get refreshToken(): string {
    const user = this.user;
    return user ? user.refreshToken : null;
  }

  getAccess(name: string): IAtpUnitAccess {
    name = name.toUpperCase();
    if (!this.user || !this.user.accesses) return null;
    let result: IAtpUnitAccess = null;
    let names = name.split('.');
    if (names.length > 1) {
      let accesses = this.user.accesses.filter(x => names.find(y => y == x.internalName.toUpperCase()));
      if (accesses.length == names.length) {
        result = accesses.find(x => x.internalName.toUpperCase() == names[names.length - 1]);
      }
      else {
        result = null;
      }
    }
    else {
      result = this.user.accesses.find(x => x.internalName.toUpperCase() == name.toUpperCase());
    }
    return result;
  }

  logout() {
    window.localStorage.removeItem('user');
  }

  protected abstract get authHttpHeaders(): HttpHeaders;

  abstract auth(login: string, password: string, formData: FormData): Observable<T>;

  abstract reauth(): Observable<T>;

  abstract uploadFile(file: File): Observable<HttpEvent<{}>>;

  abstract getFile(id: string): Observable<any>;

  abstract getUserNamesByIds(ids: any[]): Observable<IAtpSelectItem[]>;

  abstract getHistoryItemDetail(urlPath: string, id: string): Observable<IAtpHistory>;
}
