import { Component } from '@angular/core';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  host: {
    class: 'app'
  }
})
export class AppComponent {

  constructor(public title: Title, private _matIconRegistry: MatIconRegistry, private _domSanitazer: DomSanitizer) {
    title.setTitle('Главная');
  }

  ngOnInit() {
    this._matIconRegistry.addSvgIcon('product', this._domSanitazer.bypassSecurityTrustResourceUrl('assets/svg/product.svg'));
    this._matIconRegistry.addSvgIcon('nds', this._domSanitazer.bypassSecurityTrustResourceUrl('assets/svg/nds.svg'));
    this._matIconRegistry.addSvgIcon('handbook', this._domSanitazer.bypassSecurityTrustResourceUrl('assets/svg/handbook.svg'));
    this._matIconRegistry.addSvgIcon('units', this._domSanitazer.bypassSecurityTrustResourceUrl('assets/svg/units.svg'));
  }

}
