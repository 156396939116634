<mat-drawer-container class="main__body">
  <mat-drawer #drawer [mode]="isNotDesktop ? 'side' : 'over'" [opened]="isNotDesktop" class="main__menu-panel">
    <nav-menu #navmenu [parent]="this" [isNotDesktop]="isNotDesktop" (closeEvent)="drawer.toggle()"></nav-menu>
  </mat-drawer>
  <mat-drawer-content class="main__content" [class.main__content_transition]="navmenu"
    [style.marginLeft]="!isNotDesktop ? '0px' : (navmenu?.isFullPanel ? '192px' : '337px')">

    <div class="main__content-container">
      <button mat-stroked-button color="primary" class="main__open-menu-button" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <header class="main__header">
        <h2 class="main__header-text">{{title.getTitle()}}</h2>

        <div class="main__header-info">

          <div class="main__header-messages">
            <button mat-icon-button color="primary" class="main__header-messages-button"
              [matBadge]="notifications?.notViewsMessages?.length || ''" (click)="openMessages()">
              <mat-icon>notifications</mat-icon>
            </button>
          </div>

          <div class="main__header-person">
            <div class="main__header-person-fio">{{api.fio}}</div>
            <div class="main__header-person-email">{{api.email}}</div>
          </div>

          <button mat-icon-button class="main__header-logout" (click)="logout()">
            <mat-icon matSuffix>exit_to_app</mat-icon>
          </button>

        </div>
      </header>

      <div class="main__router">
        <router-outlet></router-outlet>
      </div>
    </div>

  </mat-drawer-content>
</mat-drawer-container>