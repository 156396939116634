import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      let result = (<string>value.toString()).replace('.', ',');
      if (!result) {
        return null;
      }

      if (result.split(',').length > 1) {
        result = result.split(',')[0] + ',' + (result.split(',')[1] + '00').substr(0, 2);
      }
      else {
        result = result + ',00';
      }
      
      return result;
    }

    return null;
  }

}
