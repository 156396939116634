<mat-drawer-container class="main__body">
  <mat-drawer #drawer [mode]="isNotDesktop ? 'side' : 'over'" [opened]="isNotDesktop" class="main__menu-panel">
    <nav-menu #navmenu [parent]="this" [isNotDesktop]="isNotDesktop" (closeEvent)="drawer.toggle()"></nav-menu>
  </mat-drawer>
  <mat-drawer-content class="main__content" [class.main__content_transition]="navmenu"
    [style.marginLeft]="!isNotDesktop ? '0px' : (navmenu?.isFullPanel ? '192px' : '337px')">

    <div class="main__content-container">
      <button mat-stroked-button color="primary" class="main__open-menu-button" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>

      <div class="main__router">

        <div class="error404__container">
          <div class="error404__content">
            <div class="error404__title">404</div>
            <div class="error404__subtitle">Запрашиваемой страницы не существует</div>
            <div class="error404__text">Страница, на которую вы попали, не существует<br> или была удалена.</div>
            <div class="error404__actions">
              <button mat-raised-button color="primary" routerLink="/lots">Главная страница</button>
            </div>
          </div>
        </div>

      </div>
    </div>

  </mat-drawer-content>
</mat-drawer-container>