import { Component, OnInit, Input, ReflectiveInjector, ViewContainerRef, ViewChild, EventEmitter, OnDestroy, Inject, ElementRef, Optional, Self, TemplateRef } from '@angular/core';
import { ComponentPortal, CdkPortalOutlet, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { EventArgs } from '../../atp-core';
import { ATP_DATE_TIME_PICKER_CALENDAR_INIT_DATA, AtpDateTimePickerCalendarInitData, AtpDateTimeService, AtpRangeDateTimeType, AtpDateTimeType } from './atp-date-time.service';
import { FormGroup, FormControl, NgControl } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'atp-range-date-time-picker',
  templateUrl: './atp-range-date-time-picker.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AtpRangeDateTimePickerComponent
    }
  ],
  host: {
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
    '[class]': "'atp-range-date-time-picker_' + type.toLowerCase()"
  }
})
export class AtpRangeDateTimePickerComponent implements OnInit, OnDestroy {

  constructor(public overlay: Overlay, public dateTimeService: AtpDateTimeService, private _focusMonitor: FocusMonitor, private _elementRef: ElementRef<HTMLElement>, @Optional() @Self() public ngControl: NgControl) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      setTimeout(() => {
        if (this.focused && !origin) {
          this.onTouched();
          this.stateChanges.next();
        }
        this.focused = !!origin;
      }, 0);
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input() isFilter: boolean;
  @Input() firstDayOfWeek: number = 1;

  private _type: AtpDateTimeType | AtpRangeDateTimeType = 'DateTime';
  @Input() get type(): AtpDateTimeType | AtpRangeDateTimeType {
    return this._type;
  }
  set type(value: AtpDateTimeType | AtpRangeDateTimeType) {
    this._type = value;
  }

  private _dateStart: Date;
  @Input() get dateStart(): Date {
    return this._dateStart;
  }
  set dateStart(value: Date) {
    if (value) {
      this._dateStart = value;
    }
  }

  formControlFrom: FormControl;
  formControlTo: FormControl;

  ngOnInit(): void {
    this.formControlFrom = new FormControl();

    if (this.type.startsWith('Range')) {
      this.formControlFrom.setValue(this.value ? this.dateTimeService.dateToFormatString(this.value[0], <AtpDateTimeType>this.type.replace('Range', '')) : '');
      this.formControlTo = new FormControl(this.value ? this.dateTimeService.dateToFormatString(this.value[1], <AtpDateTimeType>this.type.replace('Range', '')) : '');
    }
    else {
      this.formControlFrom.setValue(this.value ? this.dateTimeService.dateToFormatString(<Date>this.value, <AtpDateTimeType>this.type.replace('Range', '')) : '');
    }

    setTimeout(() => {
      this.stateChanges.subscribe(
        data => {
          if (this.ngControl && this.ngControl.control) {
            this.errorState = !!this.ngControl.errors;
          }
        }
      );
    }, 0);
  }

  static nextId = 0;
  stateChanges = new Subject<void>();
  id: string = `atp-range-date-time-picker-${AtpRangeDateTimePickerComponent.nextId++}`;

  onChange = (_: any) => { };
  onTouched = () => { };

  private _placeholder: string;
  @Input() get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  focused: boolean;
  empty: boolean;

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _required = false;
  @Input() get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input() get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  errorState: boolean;
  controlType: string = 'atp-range-date-time-picker';
  autofilled?: boolean;
  describedBy = '';

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    // if ((event.target as Element).tagName.toLowerCase() != 'input') {
    //   this._elementRef.nativeElement.querySelector('input')!.focus();
    // }
  }

  writeValue(value: Date | [Date, Date]): void {
    setTimeout(() => {
      if (value) {
        if (value instanceof Date) {
          this.formControlFrom.setValue(isNaN(+value) ? null : this.dateTimeService.dateToFormatString(value, <AtpDateTimeType>this.type.replace('Range', '')));
        }
        else {
          this.formControlFrom.setValue(isNaN(+value[0]) ? null : this.dateTimeService.dateToFormatString(value[0], <AtpDateTimeType>this.type.replace('Range', '')));
          this.formControlTo.setValue(isNaN(+value[1]) ? null : this.dateTimeService.dateToFormatString(value[1], <AtpDateTimeType>this.type.replace('Range', '')));
        }
      }
      this.value = value;
    }, 0);
  }

  private _value: Date | [Date, Date] | string;
  get value(): Date | [Date, Date] | string {
    return this._value;
  }
  set value(val: Date | [Date, Date] | string) {
    if (!this.isFilter && val) {
      if (val instanceof Date) {
        if (isNaN(+val)) {
          this._value = !this.formControlFrom.value ? null : 'INVALID';
          this.onChange(this._value);
          this.onTouched();
          this.stateChanges.next();
          return;
        }
      }
      else {
        if (isNaN(+val[0]) || isNaN(+val[1])) {
          this._value = !this.formControlFrom.value && !this.formControlTo.value ? null : 'INVALID';
          this.onChange(this._value);
          this.onTouched();
          this.stateChanges.next();
          return;
        }
      }
    }
    this._value = val;

    this.onChange(this._value);
    this.onTouched();
    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private _overlayRef: OverlayRef;

  private getNowForDateStart(): Date {
    let result = new Date(Date.now());
    if (!this.type.includes('Date') && this.type.endsWith('Time')) {
      result.setFullYear(0, 0, 1);
      if (!this.type.endsWith('FullTime')) {
        result.setMilliseconds(0);
      }
    }

    if (!this.type.includes('Time')) {
      result.setHours(0, 0, 0);
    }

    return result;
  }

  focus(htmlElement: HTMLElement, formControl: FormControl) {
    this._overlayRef = this.overlay.create({
      positionStrategy:
        this.overlay.position()
          .flexibleConnectedTo(htmlElement)
          .withPositions([
            { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
            { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
            { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
            { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' }
          ])
          .withPush(false),
      scrollStrategy: this.overlay.scrollStrategies.block()
    });
    const portal = new ComponentPortal(AtpDateTimePickerPopup);
    const componentRef = this._overlayRef.attach(portal);
    componentRef.instance.firstDayOfWeek = this.firstDayOfWeek;
    componentRef.instance.type = <AtpDateTimeType>this.type.replace('Range', '');
    componentRef.instance.value = this.dateTimeService.formatStringToDate(formControl.value, <AtpDateTimeType>this.type.replace('Range', '')) || this.dateStart || this.getNowForDateStart();
    componentRef.instance.valueChange.subscribe(
      (value: Date) => {
        formControl.setValue(this.dateTimeService.dateToFormatString(value, <AtpDateTimeType>this.type.replace('Range', '')));
        if (this.type.startsWith('Range')) {
          if (!this.isFilter && this.formControlFrom.value && this.formControlTo.value) {
            this.value = this.dateTimeService.formatStringToDatesRange(this.formControlFrom.value + this.dateTimeService.getSplitDateTimeRange() + this.formControlTo.value, <AtpRangeDateTimeType>this.type);
          }
          else if (this.isFilter) {
            this.value = [this.dateTimeService.formatStringToDate(this.formControlFrom.value, <AtpDateTimeType>this.type.replace('Range', '')), this.dateTimeService.formatStringToDate(this.formControlTo.value, <AtpDateTimeType>this.type.replace('Range', ''))];
          }
        }
        else {
          this.value = value;
        }
      }
    );

    componentRef.instance.setClose(this._overlayRef.detach.bind(this._overlayRef));
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

}

@Component({
  selector: 'atp-date-time-picker-popup',
  templateUrl: './atp-date-time-picker-popup.component.html'
})
export class AtpDateTimePickerPopup implements OnInit {

  constructor(public dateTimeService: AtpDateTimeService, private _viewContainerRef: ViewContainerRef) { }

  close: () => any;

  setClose(fn: () => any): void {
    this.close = fn;
  }

  masks = { hours: [/[0-2]/, /[0-9]/], minutes: [/[0-5]/, /[0-9]/], seconds: [/[0-5]/, /[0-9]/] };
  timeForm: FormGroup;

  private _startCalendar: 'Day' | 'Month' | 'Year' = 'Day';
  get startCalendar(): 'Day' | 'Month' | 'Year' {
    return this._startCalendar;
  }
  set startCalendar(value: 'Day' | 'Month' | 'Year') {
    this._startCalendar = value;
  }

  firstDayOfWeek: number = 1;
  type: AtpDateTimeType;

  private _calendarTypes: ComponentType<AtpDateTimePickerCalendar>[] = [AtpDateTimePickerDayCalendar, AtpDateTimePickerMonthCalendar, AtpDateTimePickerYearCalendar];

  @ViewChild('headerTemplate', { static: true }) headerTemplate: TemplateRef<any>;
  @ViewChild('timePickerTemplate', { static: true }) timePickerTemplate: TemplateRef<any>;
  headerPortal: TemplatePortal;
  timePickerPortal: TemplatePortal;

  @ViewChild('calendarPortalOutlet', { read: CdkPortalOutlet, static: true }) calendarPortalOutlet: CdkPortalOutlet;
  calendarPortal: ComponentPortal<AtpDateTimePickerCalendar>;
  calendarComponent: AtpDateTimePickerCalendar;

  value: Date;
  valueChange = new EventEmitter<Date>();

  titleClick() {
    this.calendarPortal.detach();
    if (this.calendarComponent.typeIndex >= this._calendarTypes.length - 1) {
      this.changeCalendar(this._calendarTypes[0]);
    }
    else {
      this.changeCalendar(this._calendarTypes[this.calendarComponent.typeIndex + 1]);
    }
  }

  private changeCalendar(type: ComponentType<AtpDateTimePickerCalendar>) {
    this.calendarPortal = new ComponentPortal(type, null, ReflectiveInjector.fromResolvedProviders(ReflectiveInjector.resolve([{ provide: ATP_DATE_TIME_PICKER_CALENDAR_INIT_DATA, useValue: { startDate: this.value, firstDayOfWeek: this.firstDayOfWeek } }]), this._viewContainerRef.parentInjector));
    this.calendarComponent = this.calendarPortal.attach(this.calendarPortalOutlet).instance;
    this.calendarComponent.selectedEvent.subscribe(
      (data: EventArgs<AtpDateTimePickerCalendar, Date>) => {
        this.value = data.e;
        this.value.setHours(+this.timeForm.controls.hours.value, +this.timeForm.controls.minutes.value, +this.timeForm.controls.seconds.value);
        this.valueChange.next(this.value);

        this.calendarPortal.detach();
        if (data.sender.typeIndex >= this._calendarTypes.length) {
          this.changeCalendar(this._calendarTypes[0]);
        }
        else if (data.sender.typeIndex == 0) {
          this.changeCalendar(this._calendarTypes[0]);
        }
        else {
          this.changeCalendar(this._calendarTypes[data.sender.typeIndex - 1]);
        }
      }
    );
  }

  ngOnInit() {
    this.timeForm = new FormGroup({
      hours: new FormControl(this.dateTimeService.toAnyDigit(this.value.getHours(), 2)),
      minutes: new FormControl(this.dateTimeService.toAnyDigit(this.value.getMinutes(), 2)),
      seconds: new FormControl(this.dateTimeService.toAnyDigit(this.value.getSeconds(), 2))
    });

    this.timeForm.controls.hours.valueChanges.subscribe(
      (value) => {
        if (typeof (value) == 'string' && value.includes('_')) {
          return
        }
        if (+value > 23) {
          this.timeForm.controls.hours.setValue('23');
        }
        else if (+value < 0) {
          this.timeForm.controls.hours.setValue('00');
        }
        else if (value.toString().length < 2) {
          this.timeForm.controls.hours.setValue(this.dateTimeService.toAnyDigit(+value, 2));
        }
        this.valueChange.next(new Date(this.value.setHours(value)));
      }
    );

    this.timeForm.controls.minutes.valueChanges.subscribe(
      (value) => {
        if (typeof (value) == 'string' && value.includes('_')) {
          return
        }
        if (+value > 59) {
          this.timeForm.controls.minutes.setValue('59');
        }
        else if (+value < 0) {
          this.timeForm.controls.minutes.setValue('00');
        }
        else if (value.toString().length < 2) {
          this.timeForm.controls.minutes.setValue(this.dateTimeService.toAnyDigit(+value, 2));
        }
        this.valueChange.next(new Date(this.value.setMinutes(value)));
      }
    );

    this.timeForm.controls.seconds.valueChanges.subscribe(
      (value) => {
        if (typeof (value) == 'string' && value.includes('_')) {
          return
        }
        if (+value > 59) {
          this.timeForm.controls.seconds.setValue('59');
        }
        else if (+value < 0) {
          this.timeForm.controls.seconds.setValue('00');
        }
        else if (value.toString().length < 2) {
          this.timeForm.controls.seconds.setValue(this.dateTimeService.toAnyDigit(+value, 2));
        }
        this.valueChange.next(new Date(this.value.setSeconds(value)));
      }
    );

    if (this.type.includes('Time')) {
      this.timePickerPortal = new TemplatePortal(this.timePickerTemplate, this._viewContainerRef);
    }

    if (this.type.includes('Date')) {
      switch (this.startCalendar) {
        case 'Day':
          this.changeCalendar(AtpDateTimePickerDayCalendar);
          break;
        case 'Month':
          this.changeCalendar(AtpDateTimePickerMonthCalendar);
          break;
        case 'Year':
          this.changeCalendar(AtpDateTimePickerYearCalendar);
          break;
      }

      this.headerPortal = new TemplatePortal(this.headerTemplate, this._viewContainerRef);
    }
  }

}

class AtpDateTimePickerButtonViewModel {

  constructor(public value: Date, public type: 'Day' | 'Month' | 'Year', public state: 'Prev' | 'Next' | 'Current' | 'Active' | 'Now') {
  }

  public get color(): string {
    switch (this.state) {
      case 'Prev':
      case 'Next':
        return 'basic';
      case 'Current':
        return 'primary';
      case 'Active':
        return 'warn';
      case 'Now':
        return 'accent';
    }
  }
}

interface AtpDateTimePickerCalendar {
  typeIndex: number;
  title: string;
  back(): void;
  next(): void;
  date: Date;
  buttons: AtpDateTimePickerButtonViewModel[];
  selectedEvent: EventEmitter<EventArgs<AtpDateTimePickerCalendar, Date>>;
}

@Component({
  selector: 'atp-date-time-picker-day-calendar',
  template: `
    <button mat-stroked-button disabled *ngFor="let buttonName of dayOfWeekButtonNames">{{buttonName}}</button>

    <button mat-stroked-button *ngFor="let button of buttons; let i = index" [color]="button.color" type="button"
      (click)="buttonClick(button)">{{button.value.getDate()}}</button>
  `,
  host: {
    class: 'atp-date-time-picker__calendar'
  }
})
export class AtpDateTimePickerDayCalendar implements AtpDateTimePickerCalendar {

  constructor(public dateTimeService: AtpDateTimeService, @Inject(ATP_DATE_TIME_PICKER_CALENDAR_INIT_DATA) public initData: AtpDateTimePickerCalendarInitData) {
    this.date = initData.startDate;
    this.setButtons();
  }

  get typeIndex(): number {
    return 0;
  }

  get title(): string {
    return this.dateTimeService.getMonthName(this.date);
  }

  back(): void {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate() >= this.date.getDate() ?
      new Date(this.date.getFullYear(), this.date.getMonth() - 1, this.date.getDate()) : new Date(this.date.getFullYear(), this.date.getMonth(), 0);
    this.setButtons();
  }

  next(): void {
    this.date = new Date(this.date.getFullYear(), this.date.getMonth() + 2, 0).getDate() >= this.date.getDate() ?
      new Date(this.date.getFullYear(), this.date.getMonth() + 1, this.date.getDate()) : new Date(this.date.getFullYear(), this.date.getMonth() + 2, 0);
    this.setButtons();
  }

  date: Date = new Date(Date.now());
  buttons: AtpDateTimePickerButtonViewModel[] = [];
  selectedEvent = new EventEmitter<EventArgs<AtpDateTimePickerCalendar, Date>>();

  buttonClick(button: AtpDateTimePickerButtonViewModel) {
    this.selectedEvent.next(new EventArgs<AtpDateTimePickerCalendar, Date>(this, button.value));
  }

  dayOfWeekButtonNames: string[];

  datesEquals(date1: Date, date2: Date): boolean {
    return date1.getFullYear() == date2.getFullYear() && date1.getMonth() == date2.getMonth() && date1.getDate() == date2.getDate();
  }

  setButtons() {
    this.dayOfWeekButtonNames = [];
    for (let i = this.initData.firstDayOfWeek; i < 7; i++) {
      this.dayOfWeekButtonNames.push(this.dateTimeService.getDayShortName(i));
    }

    for (let i = 0; i < this.initData.firstDayOfWeek; i++) {
      this.dayOfWeekButtonNames.push(this.dateTimeService.getDayShortName(i));
    }

    const date = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    const now = new Date(Date.now());
    this.buttons = [];
    const lastDay = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate();
    const dateFirstDayOfWeek = date.getDay();
    date.setDate(0);
    const prevMonthLastDay = date.getDate();

    for (let i = prevMonthLastDay + ((prevMonthLastDay - dateFirstDayOfWeek + 1 - (7 - this.initData.firstDayOfWeek)) - prevMonthLastDay) % 7; i <= prevMonthLastDay; i++) {
      this.buttons.push(new AtpDateTimePickerButtonViewModel(new Date(date.setDate(i)), 'Day', this.datesEquals(date, this.initData.startDate) ? 'Active' : this.datesEquals(date, now) ? 'Now' : 'Prev'));
    }

    date.setDate(1);
    date.setMonth(this.date.getMonth());
    date.setFullYear(this.date.getFullYear())

    for (let i = 1; i <= lastDay; i++) {
      this.buttons.push(new AtpDateTimePickerButtonViewModel(new Date(date.setDate(i)), 'Day', this.datesEquals(date, this.initData.startDate) ? 'Active' : this.datesEquals(date, now) ? 'Now' : 'Current'));
    }

    const nextMonthDaysCount = 42 - this.buttons.length;

    date.setDate(1);
    date.setMonth(this.date.getMonth() + 1);
    for (let i = 1; i <= nextMonthDaysCount; i++) {
      this.buttons.push(new AtpDateTimePickerButtonViewModel(new Date(date.setDate(i)), 'Day', this.datesEquals(date, this.initData.startDate) ? 'Active' : this.datesEquals(date, now) ? 'Now' : 'Next'));
    }
  }

  ngOnDestroy() {
    this.selectedEvent.unsubscribe();
  }

}

@Component({
  selector: 'atp-date-time-picker-month-calendar',
  template: `
    <button mat-stroked-button *ngFor="let button of buttons; let i = index" [color]="button.color"
      type="button" (click)="buttonClick(button)">{{dateTimeService.getMonthName(button.value)}}</button>
  `,
  host: {
    class: 'atp-date-time-picker__calendar'
  }
})
export class AtpDateTimePickerMonthCalendar implements AtpDateTimePickerCalendar {

  constructor(public dateTimeService: AtpDateTimeService, @Inject(ATP_DATE_TIME_PICKER_CALENDAR_INIT_DATA) public initData: AtpDateTimePickerCalendarInitData) {
    this.date = initData.startDate;
    this.setButtons();
  }

  get typeIndex(): number {
    return 1;
  }

  get title(): string {
    return this.date.getFullYear().toString();
  }

  back(): void {
    this.date = new Date(this.date.getFullYear() - 1, this.date.getMonth() + 1, 0).getDate() >= this.date.getDate() ?
      new Date(this.date.getFullYear() - 1, this.date.getMonth(), this.date.getDate()) : new Date(this.date.getFullYear() - 1, this.date.getMonth() + 1, 0);
    this.setButtons();
  }

  next(): void {
    this.date = new Date(this.date.getFullYear() + 1, this.date.getMonth() + 1, 0).getDate() >= this.date.getDate() ?
      new Date(this.date.getFullYear() + 1, this.date.getMonth(), this.date.getDate()) : new Date(this.date.getFullYear() + 1, this.date.getMonth() + 1, 0);
    this.setButtons();
  }

  date: Date = new Date(Date.now());
  buttons: AtpDateTimePickerButtonViewModel[] = [];
  selectedEvent = new EventEmitter<EventArgs<AtpDateTimePickerCalendar, Date>>();

  buttonClick(button: AtpDateTimePickerButtonViewModel) {
    this.selectedEvent.next(new EventArgs<AtpDateTimePickerCalendar, Date>(
      this, new Date(
        button.value.getFullYear(), button.value.getMonth() + 1, 0).getDate() >= this.date.getDate() ?
      new Date(button.value.getFullYear(), button.value.getMonth(), this.date.getDate()) :
      new Date(button.value.getFullYear(), button.value.getMonth() + 1, 0)
    ));
  }

  setButtons() {
    const date = new Date(this.date.getFullYear(), 0, 1);
    const now = new Date(Date.now());
    this.buttons = [];
    for (let i = 0; i < 12; i++) {
      this.buttons.push(new AtpDateTimePickerButtonViewModel(new Date(date.setMonth(i)), 'Month', this.initData.startDate.getFullYear() == date.getFullYear() && this.initData.startDate.getMonth() == i ? 'Active' : now.getFullYear() == date.getFullYear() && now.getMonth() == i ? 'Now' : 'Current'));
    }
  }

  ngOnDestroy() {
    this.selectedEvent.unsubscribe();
  }

}

@Component({
  selector: 'atp-date-time-picker-year-calendar',
  template: `
  <button mat-stroked-button *ngFor="let button of buttons; let i = index" [color]="button.color"
    type="button" (click)="buttonClick(button)">{{button.value.getFullYear()}}</button>
  `,
  host: {
    class: 'atp-date-time-picker__calendar'
  }
})
export class AtpDateTimePickerYearCalendar implements OnDestroy, AtpDateTimePickerCalendar {

  constructor(@Inject(ATP_DATE_TIME_PICKER_CALENDAR_INIT_DATA) public initData: AtpDateTimePickerCalendarInitData) {
    this.date = initData.startDate;
    this.setButtons();
  }

  get typeIndex(): number {
    return 2;
  }

  get title(): string {
    return this.buttons.length ? this.buttons[0].value.getFullYear().toString() + ' - ' + this.buttons[this.buttons.length - 1].value.getFullYear().toString() : null;
  }

  back(): void {
    this.date = new Date(this.date.getFullYear() - 8, this.date.getMonth() + 1, 0).getDate() >= this.date.getDate() ?
      new Date(this.date.getFullYear() - 8, this.date.getMonth(), this.date.getDate()) : new Date(this.date.getFullYear() - 8, this.date.getMonth() + 1, 0);
    this.setButtons();
  }

  next(): void {
    this.date = new Date(this.date.getFullYear() + 8, this.date.getMonth() + 1, 0).getDate() >= this.date.getDate() ?
      new Date(this.date.getFullYear() + 8, this.date.getMonth(), this.date.getDate()) : new Date(this.date.getFullYear() + 8, this.date.getMonth() + 1, 0);
    this.setButtons();
  }

  date: Date = new Date(Date.now());
  buttons: AtpDateTimePickerButtonViewModel[] = [];
  selectedEvent = new EventEmitter<EventArgs<AtpDateTimePickerCalendar, Date>>();

  buttonClick(button: AtpDateTimePickerButtonViewModel) {
    this.selectedEvent.next(new EventArgs<AtpDateTimePickerCalendar, Date>(
      this, new Date(
        button.value.getFullYear(), this.date.getMonth() + 1, 0).getDate() >= this.date.getDate() ?
      new Date(button.value.getFullYear(), this.date.getMonth(), this.date.getDate()) : new Date(button.value.getFullYear(), this.date.getMonth() + 1, 0)
    ));
  }

  setButtons() {
    const date = new Date(this.date.getFullYear(), 0, 1);
    const nowYear = new Date(Date.now()).getFullYear();
    this.buttons = [];
    for (let i = this.date.getFullYear() - 8; i < this.date.getFullYear() + 8; i++) {
      this.buttons.push(new AtpDateTimePickerButtonViewModel(new Date(date.setFullYear(i)), 'Year', this.initData.startDate.getFullYear() == i ? 'Active' : nowYear == i ? 'Now' : 'Current'));
    }
  }

  ngOnDestroy() {
    this.selectedEvent.unsubscribe();
  }

}
