import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subscribable, Subscription} from 'rxjs';

@Injectable()
export class InitService {

  private settings: any;

  private apiUrl = '/api/init';

  constructor(private http: HttpClient){}

  load(): Promise<any> {
    return this.http.get(this.apiUrl).pipe().toPromise().then( (result) => {
      this.settings = result;
    }).catch(error => {
    });
  }

  getSettings(): any{
    return this.settings;
  }
}
