import { Injectable, ComponentRef } from '@angular/core';
import { AtpNotificationsNewComponent } from './atp-notifications-new.component';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

export interface IAtpNotification<TData extends IAtpNotificationData> {
  data: TData;
  detach?: () => void;
}

export class AtpNotificationsSettings {
  constructor(public messageType: ComponentType<any>, public time?: number) { }
}

export interface IAtpNotificationData {
  id: string;
  title: string;
  message: string;
  date?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AtpNotificationsNewService {
  constructor(private _overlay: Overlay) { }

  private _overlayRef: OverlayRef;
  private _componentRef: ComponentRef<AtpNotificationsNewComponent>;

  public notViewsMessages: IAtpNotification<any>[] = [];

  push<TData extends IAtpNotificationData>(settings: AtpNotificationsSettings, data: IAtpNotification<TData>) {
    if (!this._overlayRef) {
      this._overlayRef = this._overlay.create();
      this._overlayRef.updatePositionStrategy(this._overlay.position().global().bottom('0').right('0'));
      const notificationsPortal = new ComponentPortal(AtpNotificationsNewComponent);
      this._componentRef = this._overlayRef.attach(notificationsPortal);
    }

    this.notViewsMessages.push(data);

    this._componentRef.instance.addMessage(settings, data);
  }
}
