<mat-tree *ngIf="dataSource.data?.length" [dataSource]="dataSource" [treeControl]="treeControl"
  class="sections-tree__mat">
  <!-- Без дочерних элементов -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="38">
    <button mat-icon-button type="button" class="w-40"></button>
    <mat-checkbox *ngIf="multiple; else link;" [checked]="checklistSelection.isSelected(node)" color="primary"
      (change)="todoLeafItemSelectionToggle(node)">
      {{node.name}}
    </mat-checkbox>
    <ng-template #link>
      <a mat-button color="primary" (click)="click(node)">
        {{node.name}}
      </a>
    </ng-template>
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="38">
    <button type="button" mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox *ngIf="multiple; else link;" [checked]="descendantsAllSelected(node)" color="primary"
      [indeterminate]="descendantsPartiallySelected(node)" (change)="todoItemSelectionToggle(node)">{{node.name}}
    </mat-checkbox>
    <ng-template #link>
      <a mat-button color="primary" (click)="click(node)">
        {{node.name}}
      </a>
    </ng-template>
  </mat-tree-node>
</mat-tree>

<div #bottomSection atpSection class="atp-dynamic-form__actions">
  <button mat-flat-button class="atp-dynamic-form__actions-button atp-dynamic-form__actions-button_submit"
    color="primary" type="submit" (click)="change()">
    Применить
  </button>
  <button mat-stroked-button class="atp-dynamic-form__actions-button atp-dynamic-form__actions-button_close"
    type="button" (click)="close()">
    Отмена
  </button>
</div>
