<!-- <div class="app-header app-container-shadow">
  <div class="app-header__container">
    <div class="app-header__item">
      <a class="app-header__logo" routerLink="/">
        <img src="/assets/img/logo.jpg" />
      </a>
      <div class="tag">Тендерная площадка</div>
    </div>
    <div class="app-header__item">
    </div>
    <div *ngIf="api.token else elseBlockAuth" class="app-header__item">
      <span class="app-header__fio">{{api.fio}}</span>
      <button mat-icon-button class="app-header__exit" (click)="logout()">
        <mat-icon matSuffix>exit_to_app</mat-icon>
      </button>
    </div>
    <ng-template #elseBlockAuth>
      <div class="app-header__item">
        <a class="app-header__link" routerLink="/auth">Войти</a>&nbsp;|&nbsp;<a routerLink="/registration"
          class="app-header__link">Регистрация</a>
      </div>
    </ng-template>
  </div>
</div> -->

<!-- <nav-menu [parent]="this" *ngIf="menuManager.leftMenu"></nav-menu>

<div class="app__body">
  <div class="app__content">
    <header *ngIf="menuManager.topMenu" class="app__header">
      <h2>{{title.getTitle()}}</h2>
      <div class="app__header-info">
        <div class="app__header-messages"></div>
        <div class="app__header-person">
          <h6 class="app__header-person-fio">{{api.fio}}</h6>
          <div class="app__header-person-email">{{api.email}}</div>
        </div>
        <button mat-icon-button class="app__header-logout" (click)="logout()">
          <mat-icon matSuffix>exit_to_app</mat-icon>
        </button>

      </div>
    </header> -->

<router-outlet></router-outlet>
<!-- </div>
</div> -->

<!-- <mat-sidenav-container class="app__body">
  <mat-sidenav mode="side" opened>
    <nav-menu [parent]="this"></nav-menu>
  </mat-sidenav>

  <mat-sidenav-content class="app__content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container> -->