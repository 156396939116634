import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AtpDialogService } from '../atp-dialog/atp-dialog.service';
import { AtpTreeComponent } from './atp-tree.component';

@Component({
  selector: 'atp-btn-sections',
  templateUrl: './atp-btn-sections.component.html',
  styles: [':host{padding-top: 7px;}'],

})
export class AtpBtnSectionsComponent {

  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() value: Array<object>;
  @Input() source: any;
  @Input() tree: any;
  @Input() multiple: boolean;

  constructor(protected atpDialog: AtpDialogService) { }

  clickOpen(e): void {
    this.atpDialog.open(AtpTreeComponent, {
      data: {
        model: this.value && this.multiple ? this.value.map(el => el["id"]) : [],
        params: [this.tree],
        multiple: this.multiple ? true : false
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        if (this.multiple){
          const data = result.map(id => {
            return this.source.find(el => el.id === id);
          });
          this.change.emit(data);
        }
        else{
          this.change.emit(result);
        }
      }
    });
  }
}
