import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { SubscriptionSectionProductsComponent } from '../../../subscription-section-products/subscription-section-products.component';
import { ATP_DIALOG_DATA, AtpDialogService } from '../atp-dialog/atp-dialog.service';
import { SectionsTreeComponent } from '../../../subscription-section-products/sections-tree/sections-tree.component';

import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { AtpDialogRef } from '../atp-dialog/atp-dialog-container.component';

class AccessNode {
  constructor(
    public id: any,
    public name: string,
    public parentId: any,
    public sort: number,
    public children: AccessNode[],
    public isChecked?: boolean
  ) { }
}

class AccessFlatNode {
  id: any;
  name: string;
  level: number;
  expandable: boolean;
  isChecked: boolean;
}

@Component({
  selector: 'atp-tree',
  templateUrl: './atp-tree.component.html',
  styleUrls: ['./atp-tree.component.css'],
  host: {
    class: 'sections-tree'
  },
  encapsulation: ViewEncapsulation.None
})

export class AtpTreeComponent {

  multiple: boolean = false;

  constructor(
    @Inject(ATP_DIALOG_DATA) public data,
    @Optional() protected dialogRef: AtpDialogRef<AtpTreeComponent>
  ) {

    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<AccessFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.dataSource.data = [];

    this.multiple = data.multiple;

    const func = (item: AccessNode, isCheckedRoot: boolean = false): AccessNode => {
      if (!isCheckedRoot) {
        item.isChecked = !!data.model.find(x => x === item.id);
      }
      else {
        item.isChecked = true;
      }
      if (item.children && item.children.length) {
        item.children = item.children.map(x => func(x, item.isChecked)); // .sort((x, y) => x.sort > y.sort ? 1 : x.sort < y.sort ? -1 : 0);
      }
      return item;
    };

    if (this.data.params && this.data.params.length) {
      this.dataSource.data = this.data.params[0].map((x: AccessNode) => func(x)); // .sort((x: AccessNode, y: AccessNode) => x.sort > y.sort ? 1 : x.sort < y.sort ? -1 : 0);
    }
  }

  flatNodeMap = new Map<AccessFlatNode, AccessNode>();

  nestedNodeMap = new Map<AccessNode, AccessFlatNode>();

  treeControl: FlatTreeControl<AccessFlatNode>;

  treeFlattener: MatTreeFlattener<AccessNode, AccessFlatNode>;

  dataSource: MatTreeFlatDataSource<AccessNode, AccessFlatNode>;

  checklistSelection = new SelectionModel<AccessFlatNode>(true);

  getLevel = (node: AccessFlatNode) => node.level;

  isExpandable = (node: AccessFlatNode) => node.expandable;

  getChildren = (node: AccessNode): AccessNode[] => node.children;

  hasChild = (_: number, _nodeData: AccessFlatNode) => _nodeData.expandable;

  transformer = (node: AccessNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.id === node.id ? existingNode : new AccessFlatNode();
    flatNode.id = node.id;
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.children && !!node.children.length;
    flatNode.isChecked = node.isChecked;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    if (node.isChecked) {
      setTimeout(() => {
        this.todoLeafItemSelectionToggle(flatNode);
      }, 0);
    }

    return flatNode;
  }

  descendantsAllSelected(node: AccessFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  descendantsPartiallySelected(node: AccessFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  todoItemSelectionToggle(node: AccessFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node) ? this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);

    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  todoLeafItemSelectionToggle(node: AccessFlatNode): void {
    node.isChecked = !node.isChecked;
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  checkAllParentsSelection(node: AccessFlatNode): void {
    let parent: AccessFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: AccessFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: AccessFlatNode): AccessFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  change(): void {

    console.log(this.checklistSelection);

    const result = this.checklistSelection.selected.map(x => x.id);
    // result.push(...this.dataSource.data.filter(x => x.children && !this.descendantsAllSelected(this.nestedNodeMap.get(x)) && this.descendantsPartiallySelected(this.nestedNodeMap.get(x))).map(x => x.id));
    this.dialogRef.close(result);
  }

  click(node: AccessFlatNode): void{
    this.dialogRef.close(node.id);
  }
}
