import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AtpSignalRHubConnection, AtpSignalRHubEvent } from '../atp-core/atp-core';
import { HttpErrorsService } from './http-errors.service';
import { DevService } from './dev.service';

export class OffersConnection extends AtpSignalRHubConnection {
  constructor(api: HttpService, httpErrors: HttpErrorsService) {
    super('Offers', api, httpErrors, [new AtpSignalRHubEvent<any>('BestSuggestionAndEndDate'), new AtpSignalRHubEvent<any>('ManagementInfo'), new AtpSignalRHubEvent<any>('Blocked'), new AtpSignalRHubEvent<any>('Reinit')], 100);
  }

  // sendMessage(name: string, message: string) {
  //   if (this.isConnection) {
  //     this.connection.invoke('TestMessage', 'test');
  //   }
  // }
}

export class MessagesConnection extends AtpSignalRHubConnection {
  constructor(api: HttpService, httpErrors: HttpErrorsService) {
    super('Messages', api, httpErrors, [new AtpSignalRHubEvent<any>('SubscriptionMessage')]);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  constructor(private _api: HttpService, private _httpErrors: HttpErrorsService, private _devService: DevService) {
    _api.authEvent.subscribe(
      (data) => {
        if (!this._devService.isDevMode || this._devService.isSocketsOn) {
          this.messages.start();
        }
      }
    );
  }

  public offers = new OffersConnection(this._api, this._httpErrors);
  public messages = new MessagesConnection(this._api, this._httpErrors);
}
