<div style="display: flex; align-items: center;">
  <mat-select
    [disabled]="disabled"
    style="width: 44px"
    [(ngModel)]="selectedMask"
    (selectionChange)="changeCountry($event)"
    [tabIndex]="-1"
  >
    <mat-select-trigger>
      <img  style="width: 22px; height: 100%; margin-right: 10px" [src]="selectedMask.icon">
    </mat-select-trigger>
    <mat-option *ngFor="let option of masks" [value]="option">
      <div>
      <img  style="width: 22px; height: 100%; margin-right: 10px" [src]="option.icon">
      {{option.label}}
      </div>
    </mat-option>
  </mat-select>
  <input
    #el
    matInput
    [textMask]="{mask: selectedMask.mask}"
    [formControl]="formControl"
    [placeholder]="placeholder"
  >
</div>
