import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'atp-title',
  template: `
    <mat-icon *ngIf="beforeIcon" class="atp-title__before-icon"
      [svgIcon]="beforeIcon.split(':').length == 2 && beforeIcon.split(':')[0] == 'custom' ? beforeIcon.split(':')[1] : ''">
      {{beforeIcon.split(':').length == 1 ? beforeIcon : ''}}</mat-icon>
    <h1 class="atp-title__text">{{text}}</h1>
    <mat-icon *ngIf="afterIcon" class="atp-title__after-icon"
      [svgIcon]="afterIcon.split(':').length == 2 ? afterIcon.split(':')[1] : ''">
      {{afterIcon.split(':').length == 1 ? afterIcon : ''}}</mat-icon>
  `
})
export class AtpTitleComponent {

  constructor(private _titleService: Title) { }

  private _notSetInHeader: boolean = true;
  @Input() get notSetInHeader(): boolean {
    return this._notSetInHeader;
  }
  set notSetInHeader(value: boolean) {
    this._notSetInHeader = value.toString() == 'true';
  }

  private _beforeIcon: string;
  @Input() get beforeIcon(): string {
    return this._beforeIcon;
  }
  set beforeIcon(value: string) {
    this._beforeIcon = value;
  }

  private _afterIcon: string;
  @Input() get afterIcon(): string {
    return this._afterIcon;
  }
  set afterIcon(value: string) {
    this._afterIcon = value;
  }

  private _text: string;
  @Input() get text(): string {
    return this._text;
  }
  set text(value: string) {
    if (!this.notSetInHeader) {
      this._titleService.setTitle(value);
    }
    this._text = value;
  }

}