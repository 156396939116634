import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { AtpDialogService } from 'src/app/atp-core/components/atp-dialog/atp-dialog.service';
import { AtpHttpErrorsService, AtpHttpErrorHandler } from 'src/app/atp-core/services/atp-http-errors.service';
import { IAtpPagination, IAtpHistory, AtpHistoryData, IAtpSelectItem } from 'src/app/atp-core/atp-core';
import { AtpHistoryComponent } from 'src/app/atp-core/atp-dynamic-table-full-crud/atp-history/atp-history.component';
import { AtpBasePageComponent } from 'src/app/atp-core/atp-base-page-component';
import { DevService } from 'src/app/services/dev.service';
import {InitService} from '../../services/init.service';

@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html',
  host: {
    class: 'nav-menu'
  }
})
export class NavMenuComponent implements OnInit {

  constructor(
    public api: HttpService,
    protected httpErrors: AtpHttpErrorsService,
    protected atpDialog: AtpDialogService,
    public devService: DevService,
    private initService: InitService
  ) { }

  settings: any = {};

  ngOnInit() {
    setTimeout(() => {
      this.canAnimate = true;
    });

    if (this.initService){
      this.settings = this.initService.getSettings();
    }
  }

  canAnimate: boolean;

  @Input() isNotDesktop: boolean;
  @Input() parent: AtpBasePageComponent;
  @Output() closeEvent = new EventEmitter<void>();

  private _isFullPanel: boolean;
  get isFullPanel(): boolean {
    if (this._isFullPanel === undefined) {
      this._isFullPanel = !!localStorage.getItem('isFullPanel');
    }
    return this._isFullPanel;
  }
  set isFullPanel(val: boolean) {
    localStorage.setItem('isFullPanel', val ? 'true' : '');
    this._isFullPanel = val;
  }

  close() {
    this.closeEvent.emit();
  }
}
