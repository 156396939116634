import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AtpNotificationComponent } from '../atp-core/atp-notifications/atp-notification.component';
import { AtpNotificationsService, AtpNotificationsSettings, IAtpNotificationData } from '../atp-core/atp-notifications/atp-notifications.service';
import { AtpActionsPopup, AtpActionsPopupData } from '../atp-core/components/atp-actions-popup/atp-actions-popup.component';
import { AtpDialogService } from '../atp-core/components/atp-dialog/atp-dialog.service';
import { AtpTitleService } from '../atp-core/services/atp-title.service';
import { DevService } from '../services/dev.service';
import { HttpService } from '../services/http.service';
import { SignalrService } from '../services/signalr.service';
import { NotificationsPopupComponent } from './notifications-popup/notifications-popup.component';

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  host: {
    class: 'main'
  }
})
export class MainComponent implements OnInit, OnDestroy {

  constructor(public title: AtpTitleService, public api: HttpService, public dialog: MatDialog, private _atpDialog: AtpDialogService, private _signalr: SignalrService,
    public notifications: AtpNotificationsService, private _devService: DevService, private _router: Router, breakpointObserver: BreakpointObserver) {
    const layoutChanges = breakpointObserver.observe([
      '(orientation: portrait)',
      '(orientation: landscape)',
    ]);

    this.isNotDesktop = breakpointObserver.isMatched('(max-width: 1024px)');

    this._layoutChangesSubscription = layoutChanges.subscribe(result => {
      this.isNotDesktop = !breakpointObserver.isMatched('(max-width: 1024px)');
    });
  }

  isNotDesktop: boolean;

  private _layoutChangesSubscription = Subscription.EMPTY;
  private _signalrMessagesSubscription = Subscription.EMPTY;
  ngOnInit(): void {
    if (this.api.user) {
      if (!this._devService.isDevMode || this._devService.isSocketsOn) {
        const settings = new AtpNotificationsSettings(AtpNotificationComponent);

        this._signalr.messages.start();
        this._signalrMessagesSubscription = this._signalr.messages.getEvent('SubscriptionMessage').subscribe(
          (data) => {
            this.notifications.push(settings, { data: <IAtpNotificationData>data });
          }
        );
      }
    }

  }

  logout() {
    const dialogRef = this.dialog.open(AtpActionsPopup, {
      width: '450px',
      data: new AtpActionsPopupData('Выход', 'Вы действительно хотите выйти?', 'Да', 'Нет')
    });

    dialogRef.beforeClosed().subscribe((result: boolean) => {
      if (result) {
        if (!this._devService.isDevMode || this._devService.isSocketsOn) {
          this._signalr.messages.stop();
        }
        this.api.logout();
        this._router.navigate(['/login/authorization']);
      }
    });
  }

  openMessages() {
    this._atpDialog.open(NotificationsPopupComponent, { maxWidth: '486px' });
  }

  ngOnDestroy(): void {
    if (!this._devService.isDevMode || this._devService.isSocketsOn) {
      this._signalr.messages.stop();
    }

    this._layoutChangesSubscription.unsubscribe();
    this._signalrMessagesSubscription.unsubscribe();
  }

}
