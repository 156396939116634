import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtpInputPhoneComponent } from './atp-input-phone.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {MatInputModule} from '@angular/material/input';


@NgModule({
  declarations: [AtpInputPhoneComponent],
  exports: [
    AtpInputPhoneComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    TextMaskModule,
    FormsModule,
    MatInputModule
  ]
})
export class AtpInputPhoneModule { }
