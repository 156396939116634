<div *ngIf="isBlockedPage" class="atp-preloader-container"
  [class.atp-preloader-container_transparent]="!isPreloaderShow">
  <mat-spinner *ngIf="isPreloaderShow"></mat-spinner>
</div>

<div class="atp-dynamic-form__container">
<ng-container *ngIf="data">
  <div #topSection atpSection [ngClass]="['atp-dynamic-table-full-crud__control-panel']">

    <atp-title class="atp-dynamic-form__title" text="История изменений" notSetInHeader="true">
    </atp-title>

    <div class="atp-dynamic-table-full-crud__control-panel-line">
      <!-- <button mat-button class="atp-dynamic-table-full-crud__back-button"
        *ngIf="annotations?.rootBackUrl || tableData.breadCrumbs?.length" [routerLink]="backRouterLink"
        queryParamsHandling="merge" matTooltipPosition="below">
        <mat-icon>arrow_back</mat-icon>
        Назад
      </button> -->

      <!-- <atp-filters-panel *ngIf="filtersPanelItems.length > 0" class="atp-dynamic-table-full-crud__filters-panel"
        [prefix]="baseGroupName + '-' + type" [items]="filtersPanelItems" (filtrationClick)="filtrationClick($event)"
        (resetClick)="resetClick($event)" [name]="annotations.baseGroupName + '.' + type">
      </atp-filters-panel> -->

      <div class="atp-dynamic-table-full-crud__action-buttons">

      </div>

    </div>

  </div>
  <div [ngClass]="['atp-dynamic-table-full-crud__table-container']">
    <div class="atp-dynamic-table-full-crud__table-scroll-container">
      <!--[style.maxHeight]="platform.TRIDENT || platform.SAFARI ? 'auto' : tableMaxHeight"-->
      <table class="atp-dynamic-table-full-crud__table" #tableElement mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef
            class="atp-history__table-column-header atp-history__table-column-header_action"></th>
          <td mat-cell *matCellDef="let row">
            <div class="atp-history__table-column atp-history__table-column_action">
              <mat-icon>{{row.actionIcon}}</mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef class="atp-history__table-column-header">Id события</th>
          <td mat-cell *matCellDef="let row">
            <div class="atp-history__table-column atp-history__table-column_id atp-dynamic-table-full-crud-tree-link">
              <button class="atp-dynamic-table-full-crud__link" mat-button color="primary" type="button"
                (click)="getItemDetail(row)" [disabled]="!row.haveJson">{{row.id}}</button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="entityId">
          <th mat-header-cell *matHeaderCellDef class="atp-history__table-column-header">Id</th>
          <td mat-cell *matCellDef="let row">
            <div class="atp-history__table-column atp-history__table-column_entity-id">
              {{row.entityId}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="atp-history__table-column-header">Дата</th>
          <td mat-cell *matCellDef="let row">
            <div class="atp-history__table-column atp-history__table-column_date">
              {{row.date | format : 'DateFullTime'}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef class="atp-history__table-column-header">Пользователь</th>
          <td mat-cell *matCellDef="let row">
            <div class="atp-history__table-column atp-history__table-column_username">
              {{row.userName}}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="roleName">
          <th mat-header-cell *matHeaderCellDef class="atp-history__table-column-header">Роль</th>
          <td mat-cell *matCellDef="let row">
            <div class="atp-history__table-column atp-history__table-column_role-name">
              {{row.roleName}}
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="data.isShared" matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef class="atp-history__table-column-header">Тип</th>
          <td mat-cell *matCellDef="let row">
            <div class="atp-history__table-column atp-history__table-column_type">{{row.type}}</div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="disclaimer">
          <td mat-footer-cell *matFooterCellDef [colSpan]="7">
            Нет данных для отображения
          </td>
        </ng-container>
        <tr mat-footer-row *matFooterRowDef="['disclaimer']" [style.display]="!isNotData ? 'none' : 'table-row'"></tr>
      </table>
    </div>

    <div #bottomSection atpSection class="atp-dynamic-table-full-crud__actions">

      <button *ngIf="data.type == 'history'; else elseCsvBlock" mat-raised-button color="primary" type="button"
        (click)="exportInCsv()">
        Экспорт в CSV
      </button>

      <ng-template #elseCsvBlock>
        <div *ngIf="data.type != 'history'"></div>
      </ng-template>

      <mat-paginator class="paginator" [length]="tableData.allCount" [pageSize]="tableData.pageSize"
        [pageSizeOptions]="pageSizeOptions" [pageIndex]="tableData.pageNumber - 1" [showFirstLastButtons]="'true'"
        (page)="pagination($event)">
      </mat-paginator>

    </div>
  </div>
</ng-container>
</div>
