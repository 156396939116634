<div class="atp-filters-panel-presets__header">
  <div class="atp-filters-panel-presets__header-text">Фильтры</div>
  <button mat-icon-button class="atp-filters-panel-presets__edit-mode-button" color="primary" type="button"
    (click)="editModeClick()" [disabled]="isEditMode">
    <mat-icon class="atp-filters-panel-presets__edit-mode-button-icon">settings</mat-icon>
  </button>
</div>

<div cdkDropList class="atp-filters-panel-presets__items" (cdkDropListDropped)="drop($event)">
  <div cdkDrag [cdkDragDisabled]="!isEditMode" cdkDragBoundary=".atp-filters-panel-presets__items"
    *ngFor="let item of items" class="atp-filters-panel-presets__item">
    <ng-container *ngIf="!item.isChanging; else changingBlock">
      <button mat-button color="primary" type="button" class="atp-filters-panel-presets__item-button"
        (click)="itemButtonClick(item)">
        {{item.name}}
      </button>
      <div class="atp-filters-panel-presets__item-actions" *ngIf="isEditMode">
        <button mat-icon-button type="button" class="atp-filters-panel-presets__item-actions-button"
          (click)="editItem(item)" [disabled]="isEditItem">
          <mat-icon class="atp-filters-panel-presets__item-actions-button-icon">edit</mat-icon>
        </button>
        <button mat-icon-button type="button" class="atp-filters-panel-presets__item-actions-button"
          (click)="remove(item.id)">
          <mat-icon class="atp-filters-panel-presets__item-actions-button-icon">clear</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-template #changingBlock>
      <mat-form-field appearance="outline" class="atp-filters-panel-presets__item-field">
        <input matInput [formControl]="formControl" autocomplete="off">
      </mat-form-field>
      <div class="atp-filters-panel-presets__item-actions" *ngIf="isEditMode">
        <button mat-icon-button type="button" class="atp-filters-panel-presets__item-actions-button"
          (click)="saveItem(item)" [disabled]="formControl.invalid">
          <mat-icon class="atp-filters-panel-presets__item-actions-button-icon">save</mat-icon>
        </button>
        <button mat-icon-button type="button" class="atp-filters-panel-presets__item-actions-button"
          (click)="saveItem(item, true)">
          <mat-icon class="atp-filters-panel-presets__item-actions-button-icon">cancel</mat-icon>
        </button>
      </div>
    </ng-template>
  </div>
</div>
<div class="atp-filters-panel-presets__buttons">
  <div class="atp-filters-panel-presets__button" *ngIf="!isAddMode && !isEditMode" (click)="addModeClick()">
    Сохранить фильтр
  </div>
</div>