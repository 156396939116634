import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DevService {

  constructor() { }

  get isDevMode(): boolean {
    return !environment.production;
  }

  get isSocketsOn(): boolean {
    return !localStorage.getItem('isSocketsOn');
  }

  socketsToggleEvent = new Subject();

  socketsToggle(): void {
    if (this.isSocketsOn) {
      localStorage.setItem('isSocketsOn', 'off');
    }
    else {
      localStorage.removeItem('isSocketsOn');
    }

    this.socketsToggleEvent.next();
  }

}
