import { Component, Input, OnInit, forwardRef, Optional, Self } from '@angular/core';
import { atpAnimations } from 'src/app/atp-core/atp-animations';
import { AtpValidators, IAtpSelectItem } from 'src/app/atp-core/atp-core';
import { AtpDateTimeService } from 'src/app/atp-core/components/atp-range-date-time-picker/atp-date-time.service';
import {AbstractControl, FormArray, FormControl, FormGroup, NgControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

export interface IWorkingDays {
  daysOfWeek: IAtpSelectItem[];
  weekendIds: number[];
  workTime: Date[];
  exceptionDayTypes: IAtpSelectItem[];
  exceptions: IWorkingException[];
}

export interface IWorkingException {
  day: Date;
  workTime: Date[];
  typeId: number;
  internalId?: number;
}

@Component({
  selector: 'atp-schedule',
  templateUrl: './atp-schedule.component.html',
  styleUrls: ['./atp-schedule.component.scss'],
  host: {
    'class': 'working-days',
  },
  animations: [
    atpAnimations.OpenClose
  ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AtpScheduleComponent
    }
  ],
})
export class AtpScheduleComponent implements OnInit {

  constructor(private _dateTimeService: AtpDateTimeService, @Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  daysOfWeek: IAtpSelectItem[] = [
    {id: 0, value: 'понедельник'},
    {id: 1, value: 'вторник'},
    {id: 2, value: 'среда'},
    {id: 3, value: 'четверг'},
    {id: 4, value: 'пятница'},
    {id: 5, value: 'суббота'},
    {id: 6, value: 'воскресенье'}
    ];

  exceptionDayTypes: IAtpSelectItem[] = [
    { id: 0, value: 'Рабочий день'},
    { id: 1, value: 'Выходной'}
  ];

  data: IWorkingDays;
  formGroup: FormGroup;
  exceptionsFormArray: FormArray;
  isDisabled: boolean = true;
  onTouched: any = (e) => { };

  onChange: any = (e) => { };

  writeValue(obj: any): void {
    this.data = obj;

    if (!this.data){
      this.data = {
        weekendIds: [5, 6],
        workTime: [ new Date('2000-01-01T05:00:00Z'), new Date('2000-01-01T14:00:00Z')],
        exceptions: [],
        daysOfWeek: this.daysOfWeek,
        exceptionDayTypes: this.exceptionDayTypes
      };
    }
    else{
      this.data.daysOfWeek = this.daysOfWeek;
      this.data.exceptionDayTypes = this.exceptionDayTypes;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private getExceptionGroup(id: number, model: IWorkingException): FormGroup {

    const result = new FormGroup({
      typeId: new FormControl(model.typeId ?? this.data?.exceptionDayTypes[0].id),
      day: new FormControl(new Date(model.day), this.exceptionDayValidator(id.toString())),
      workTime: new FormControl(model.workTime.map(x => new Date(x)), AtpValidators.rangeDateTime(this._dateTimeService, 'RangeTime'))
    });

    return result;
  }

  ngOnInit(): void {
   this.initData();
  }

  initData(): void {

    const exceptions = [];
    for (let i = 0; i < this.data?.exceptions.length; i++) {
      exceptions[i] = this.getExceptionGroup(i, this.data.exceptions[i]);
    }

    this.exceptionsFormArray = new FormArray(exceptions);

    this.formGroup = new FormGroup({
      weekendIds: new FormControl(this.data?.weekendIds, AtpValidators.required),
      workTime: new FormControl(this.data?.workTime.map(x => new Date(x)), AtpValidators.rangeDateTime(this._dateTimeService, 'RangeTime')),
      exceptions: this.exceptionsFormArray
    });

    this.formGroup.valueChanges.subscribe(e => {
      this.onChange(e);
    });
  }

  add(): void {
    const newException: IWorkingException = {
      day: new Date(Date.now()),
      workTime: [new Date(Date.now()), new Date(Date.now())],
      typeId: this.data?.exceptionDayTypes[0].id,
    };
    this.exceptionsFormArray.push(this.getExceptionGroup(this.exceptionsFormArray.length, newException));
  }

  remove(index: number): void {
    this.exceptionsFormArray.removeAt(index);
    this.data?.exceptions.splice(index, 1);
  }

  get errorMessageWeekendIds(): string {
    let result = '';

    if (this.formGroup.controls.weekendIds.errors?.required) {
      result = 'поле обязательно для заполнения';
    }

    return result;
  }

  getErrorMessageWorkTime(id?: string): string {
    let result = '';

    if (id ? (<FormGroup>this.exceptionsFormArray.controls[id]).controls.workTime.errors?.rangedatetime : this.formGroup.controls.workTime.errors?.rangedatetime) {
      result = 'введено неверно.';
    }

    return result;
  }

  getErrorMessageExceptionDay(id?: string): string {
    let result = '';

    // if ((<FormGroup>this.exceptionsFormArray.controls[id.toString()]).controls.day.errors?.exceptionday) {
    //   result = 'должна быть уникальной.';
    // }

    return result;
  }

  exceptionDayValidator(id: string): (fieldControl: AbstractControl) => { [key: string]: boolean } {
    return (fieldControl: AbstractControl): { [s: string]: boolean } => {
      if (!this.exceptionsFormArray?.controls[id]) {
        return null;
      }

      const value = this._dateTimeService.dateToFormatString(<Date>(<FormGroup>this.exceptionsFormArray.controls[id]).controls.day.value, "Date")
      const ids = Object.keys(this.exceptionsFormArray.controls).filter(x => x != id);

      let result = false;
      for (const i of ids) {
        if (this._dateTimeService.dateToFormatString(<Date>(<FormGroup>this.exceptionsFormArray.controls[i]).controls.day.value, "Date") == value) {
          result = true;
          break;
        }
      }
      return result ? { exceptionday: true } : null;
    }
  }
}
