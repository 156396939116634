import { Component, ElementRef, Inject } from '@angular/core';
import { atpAnimations } from '../../atp-animations';
import { AtpDynamicFormComponent } from './atp-dynamic-form.component';
import { AtpAddEditData, IAtpAddEditInfo } from '../../atp-core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AtpHttpService, IAtpUser } from '../../services/atp-http.service';
import { Router } from '@angular/router';
import { AtpHttpErrorsService } from '../../services/atp-http-errors.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'atp-add-edit-mat-popup-dynamic-table-full-crud',
  templateUrl: './atp-dynamic-form.component.html',
  animations: [
    atpAnimations.OpenClose
  ]
})
export class AtpAddEditPopupDynamicTableFullCrud extends AtpDynamicFormComponent<AtpAddEditData<any, IAtpAddEditInfo>> {

  constructor(protected dialogRef: MatDialogRef<AtpAddEditPopupDynamicTableFullCrud>, @Inject(MAT_DIALOG_DATA) data: AtpAddEditData<any, IAtpAddEditInfo>,
    api: AtpHttpService<IAtpUser>, httpErrors: AtpHttpErrorsService, dialog: MatDialog, router: Router, elementRef: ElementRef<HTMLElement>,
    platform: Platform) {
    super(data, api, httpErrors, dialog, router, elementRef, platform);
  }

  confirm(result: any) {
    this.dialogRef.close(result);
  }

  close() {
    this.dialogRef.close(false);
  }

}