import { Component, Input, Optional, Self, ElementRef, ChangeDetectionStrategy, OnDestroy, Output, EventEmitter, Injectable, DoCheck } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AtpHttpService, IAtpUser } from '../../services/atp-http.service';
import { HttpResponse } from '@angular/common/http';
import { AtpHttpErrorsService } from '../../services/atp-http-errors.service';
import { AtpDateTimeService } from '../atp-range-date-time-picker/atp-date-time.service';
import { MatFormFieldControl } from '@angular/material/form-field';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Observable, Subject, Subscription } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Injectable()
export class AtpUploadFileErrorsService {
  sizeError = 'Размер файла превышает допустимый.';
  formatError = 'Неверный формат файла.';
  uploadError = 'При загрузке файла произошла ошибка.';
}

export interface IAtpSelectedFile {
  id?: string;
  name: string;
  size: number;
  date?: Date;
}

interface IAtpSelectedFileInternal {
  id?: string;
  file?: File;
  name?: string;
  size?: number;
  date?: Date;
  errorName?: 'size' | 'format' | 'upload';
  isUploaded?: boolean;
}

@Component({
  selector: 'atp-upload-file',
  templateUrl: './atp-upload-file.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AtpUploadFileComponent
    }
  ],
  host: {
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
    'class': 'atp-upload-file'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtpUploadFileComponent implements OnDestroy, DoCheck {

  constructor(protected api: AtpHttpService<IAtpUser>, protected httpErrors: AtpHttpErrorsService, protected datetimeService: AtpDateTimeService, public focusMonitor: FocusMonitor, private _elementRef: ElementRef<HTMLElement>, @Optional() @Self() public ngControl: NgControl) {
    focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      setTimeout(() => {
        if (this.focused && !origin) {
          this.onTouched();
          this.stateChanges.next();
        }
        this.focused = !!origin;
      }, 0);
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.stateChanges.subscribe(
      (data) => {
        if (this.ngControl && this.ngControl.control) {
          if (this._isTouched) {
            this.errorState = !!this.ngControl.errors;
          }
        }
      }
    );
  }

  private _isTouched = false;
  ngDoCheck(): void {
    if (!this._isTouched && this.ngControl.touched) {
      this._isTouched = true;
      this.value = this.value;
    }
  }

  static nextId = 0;
  stateChanges: Subject<void> = new Subject<void>();
  id: string = `atp-upload-file-${AtpUploadFileComponent.nextId++}`;

  onChange = (_: any) => { };
  onTouched: any = () => { };

  private _placeholder: string;
  @Input() get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  focused: boolean;
  empty: boolean;

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  private _required = false;
  @Input() get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input() get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  errorState: boolean;
  controlType: string = 'atp-upload-file';
  autofilled?: boolean;
  describedBy = '';

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  writeValue(value: any): void {
    if (value && value.id) {
      if (value.date && typeof (value.date) == 'string') {
        value.date = new Date(value.date);
      }
      this.value = [value];
    }
    else {
      if (value && value.length) {
        for (const item of value) {
          if (item.date && typeof (item.date) == 'string') {
            item.date = new Date(item.date);
          }
        }
      }
      this.value = value;
    }
    if (value && typeof (value) != 'string' && this.uploadedFiles.length == 0 && this.value) {
      for (const item of (<IAtpSelectedFile[]>this.value)) {
        this.uploadedFiles.push(item);
        this.selectedFiles.push(item);
      }
    }
  }

  @Input() display: string;

  private _value: IAtpSelectedFile[] | IAtpSelectedFile;
  get value(): IAtpSelectedFile[] | IAtpSelectedFile {
    return this._value;
  }
  set value(val: IAtpSelectedFile[] | IAtpSelectedFile) {
    if (!val || !(<IAtpSelectedFile[]>val).length) {
      this._value = null;
    }
    else {
      this._value = val;
    }

    if (this.selectedFiles.find(x => x.errorName)) {
      (<any>this._value) = 'Invalid';
    }

    this.onChange(this._value);
    this.onTouched();
    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @Input() multiple: boolean;
  @Input() maxSize: number;
  @Input() formatsAllowed: string;
  @Input() maxCount: number = 1;

  uploadedFiles: IAtpSelectedFileInternal[] = [];
  selectedFiles: IAtpSelectedFileInternal[] = [];

  haveSizeError = false;
  haveFormatError = false;

  onValueChange(event: any) {
    if (!this.selectedFiles || !this.multiple) {
      this.selectedFiles = [];
    }
    if (this.selectedFiles.length == this.maxCount) {
      return;
    }

    const formats = this.formatsAllowed?.replace('.', '').split(', ');

    let files: FileList;
    if (event.type == 'drop') {
      files = event.dataTransfer.files;
    } else {
      files = event.target.files || event.srcElement.files;
    }

    this.maxCount = this.multiple ? this.maxCount : 1;

    for (let i = 0; i < files.length; i++) {
      if (i == this.maxCount - this.selectedFiles.length + i) {
        return;
      }

      let formatAllowed = !formats?.length || formats[0] == '*';

      if (!formatAllowed) {
        for (const format of formats) {
          if (files[i].name.substr(files[i].name.length - format.length - 1) == '.' + format) {
            formatAllowed = true;
            break;
          }
        }
      }

      if (formatAllowed) {
        if (files[i].size > this.maxSize * 1024000) {
          this.selectedFiles.push({
            name: files[i].name,
            size: files[i].size,
            errorName: 'size'
          });
          this.haveSizeError = true;
          this.value = [];
        }
        else {
          let oldFile = this.uploadedFiles.find(x => x.file && x.file.name == files[i].name && x.file.lastModified == files[i].lastModified && x.file.size == files[i].size);
          let oldSelectedFile = this.selectedFiles.find(x => x.file && x.file.name == files[i].name && x.file.lastModified == files[i].lastModified && x.file.size == files[i].size);
          if (oldSelectedFile) {
            continue;
          }
          else if (oldFile) {
            oldFile.isUploaded = true;
            this.selectedFiles.push(oldFile);
            if (this.selectedFiles.filter(x => !x.id).length == 0) {
              this.value = this.selectedFiles.map(x => { return { id: x.id, name: x.name ? x.name : x.file.name, size: x.size || x.size === 0 ? x.size : x.file.size, date: x.date } });
            }
          }
          else {
            let newFile: IAtpSelectedFileInternal = { file: files[i] };
            this.selectedFiles.push(newFile);
            this.uploadedFiles.push(newFile);
          }
        }
      }
      else {
        this.selectedFiles.push({ name: files[i].name, size: files[i].size, errorName: 'format' });
        this.haveFormatError = true;
        this.value = [];
        continue;
      }
    }

    event.target.value = null;
  }

  removeFile(index: number) {
    this.selectedFiles.splice(index, 1);

    if (this.selectedFiles.filter(x => !x.id).length == 0) {
      this.value = this.selectedFiles.map(x => { return { id: x.id, name: x.name ? x.name : x.file ? x.file.name : '', size: x.size ? x.size : x.file ? x.file.size : 0, date: x.date } });
    }

    this.haveSizeError = !!this.selectedFiles.find(x => x.errorName == 'size');
    this.haveFormatError = !!this.selectedFiles.find(x => x.errorName == 'format');
  }

  drop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.onValueChange(event);
  }

  allowDrop(event: any) {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  }

  ngOnDestroy() {
    this.stateChanges.unsubscribe();
    this.stateChanges = null;
  }

  itemChange() {
    this.value = this.selectedFiles.map(x => { return { id: x.id, name: x.name ? x.name : x.file.name, size: x.size || x.size === 0 ? x.size : x.file.size, date: x.date } });
  }
}

@Component({
  selector: 'atp-upload-file-item',
  templateUrl: './atp-upload-file-item.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AtpUploadFileComponent
    }
  ],
  host: {
    'class': 'atp-upload-file-item'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtpUploadFileItemComponent implements OnDestroy {

  constructor(protected api: AtpHttpService<IAtpUser>, protected httpErrors: AtpHttpErrorsService, protected datetimeService: AtpDateTimeService, public errors: AtpUploadFileErrorsService) {
    this.dataChange = new EventEmitter<IAtpSelectedFileInternal>();
    this.remove = new EventEmitter<IAtpSelectedFileInternal>();
  }

  private _data: IAtpSelectedFileInternal;
  @Input() get data(): IAtpSelectedFileInternal {
    return this._data;
  }
  set data(val: IAtpSelectedFileInternal) {
    this._data = val;
    if (!this.data.date && !this.data.errorName && !this.data.isUploaded) {
      this.uploadFile();
    }
  }
  @Output() dataChange: EventEmitter<IAtpSelectedFileInternal>;
  @Input() disabled: boolean;
  @Output() remove: EventEmitter<IAtpSelectedFileInternal>;

  private _progressBarValue: Subject<number>;
  get progressBarValue(): Observable<number> {
    return this._progressBarValue as Observable<number>;
  }

  private _uploadSubscription: Subscription;
  uploadFile() {
    this._progressBarValue = new Subject<number>();

    this._uploadSubscription = this.api.uploadFile(this.data.file).subscribe(
      (event: any) => {
        let percent = Math.round(100 * event.loaded / event.total);
        this._progressBarValue.next(isNaN(percent) ? 0 : percent);

        if (event instanceof HttpResponse) {
          if (event.ok) {
            this._progressBarValue.next(100);
            this._progressBarValue.complete();

            this.data.id = event.body.toString();
            this.data.isUploaded = true;
            this.dataChange.emit(this.data);
            this._progressBarValue.unsubscribe();
            this._uploadSubscription.unsubscribe();
          }
        }
      },
      (err) => {
        if (err.status == 401) {
          this.httpErrors.process(err.status, () => { this.uploadFile(); }, null);
        }
        else {
          this.data.errorName = 'upload';
          this.dataChange.emit(this.data);
        }
        this._progressBarValue.unsubscribe();
        this._uploadSubscription.unsubscribe();
      }
    );
  }

  convertSize(fileSize: number) {
    return fileSize < 1024000 ? (fileSize / 1024).toFixed(2) + ' KB' : (fileSize / 1024000).toFixed(2) + ' MB';
  }

  private _openFileSubscription: Subscription;
  openFile() {
    if (!this.data.id) {
      return;
    }
    this._openFileSubscription = this.api.getFile(this.data.id).subscribe(
      (data) => {
        this._openFileSubscription.unsubscribe();
      },
      () => {
        this.httpErrors.process(401, () => { this.openFile(); }, null);
        this._uploadSubscription.unsubscribe();
      }
    );
  }

  ngOnDestroy() {
    if (this._progressBarValue) {
      this._progressBarValue.unsubscribe();
      this._progressBarValue = null;
    }
    if (this._uploadSubscription) {
      this._uploadSubscription.unsubscribe();
      this._uploadSubscription = null;
    }
    if (this._openFileSubscription) {
      this._openFileSubscription.unsubscribe();
      this._openFileSubscription = null;
    }
  }
}