import { NgModule, Injectable } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatStepperModule } from '@angular/material/stepper';
import { TextMaskModule } from 'angular2-text-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { AtpDynamicTableFullCrudModule } from '../atp-core/atp-dynamic-table-full-crud/atp-dynamic-table-full-crud.module';
// import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { AtpDialogModule } from '../atp-core/components/atp-dialog/atp-dialog.module';
import { PlatformModule } from '@angular/cdk/platform';
import { DecimalPipe } from '../pipes/decimal.pipe';

@Injectable()
export class MatPaginatorIntlRussian extends MatPaginatorIntl {
  firstPageLabel = 'Первая страница';
  lastPageLabel = 'Последняя страница';
  nextPageLabel = 'Следующая страница';
  previousPageLabel = 'Предыдущая страница';
  itemsPerPageLabel = 'Элементов на странице:';
  getRangeLabel = function (page: number, pageSize: number, length: number) {
    if (length == 0 || pageSize == 0) {
      return `0 из ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize; return `${startIndex + 1} - ${endIndex} из ${length}`;
  }
}

@NgModule({
  declarations: [
    DecimalPipe
  ],
  exports: [
    ReactiveFormsModule,
    // ScrollDispatchModule,
    CdkTableModule,
    PlatformModule,

    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTooltipModule,
    MatTreeModule,
    MatProgressBarModule,
    MatTabsModule,
    MatCardModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatCardModule,
    MatBadgeModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatChipsModule,
    MatSliderModule,
    MatIconModule,
    MatStepperModule,

    TextMaskModule,

    AtpDynamicTableFullCrudModule,
    AtpDialogModule,

    DecimalPipe
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlRussian },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
  ]
})
export class SharedModulesModule { }
