<div class="atp-date-time-picker-popup__container">
  <ng-template #headerTemplate>
    <div class="atp-date-time-picker__top-actions">
      <button mat-raised-button type="button" (click)="calendarComponent.back()">Назад</button>
      <button mat-stroked-button color="primary" type="button"
        (click)="titleClick()">{{calendarComponent?.title}}</button>
      <button mat-raised-button type="button" (click)="calendarComponent.next()">Вперед</button>
    </div>
  </ng-template>

  <ng-template #headerPortalOutlet [cdkPortalOutlet]="headerPortal"></ng-template>

  <ng-template #calendarPortalOutlet cdkPortalOutlet></ng-template>

  <ng-template #timePickerPortalOutlet [cdkPortalOutlet]="timePickerPortal"></ng-template>

  <ng-template #timePickerTemplate>
    <div [formGroup]="timeForm" class="atp-date-time-picker__time">
      <div class="atp-date-time-picker__time-item">
        <mat-form-field floatLabel="always">
          <mat-label>Часы</mat-label>
          <input #hoursInputElement matInput autocomplete="off" [textMask]="{mask: masks.hours}"
            formControlName="hours">
        </mat-form-field>
        <div class="atp-date-time-picker__time-item-actions">
          <button mat-icon-button [disabled]="hoursInputElement.value == '23'"
            (click)="timeForm.controls.hours.setValue(+timeForm.controls.hours.value + 1);">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button mat-icon-button [disabled]="hoursInputElement.value == '00'"
            (click)="timeForm.controls.hours.setValue(+timeForm.controls.hours.value - 1);">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
      </div>
      <div class="atp-date-time-picker__time-item">
        <mat-form-field floatLabel="always">
          <mat-label>Минуты</mat-label>
          <input #minutesInputElement matInput autocomplete="off" [textMask]="{mask: masks.minutes}"
            formControlName="minutes">
        </mat-form-field>
        <div class="atp-date-time-picker__time-item-actions">
          <button mat-icon-button [disabled]="minutesInputElement.value == '59'"
            (click)="timeForm.controls.minutes.setValue(+timeForm.controls.minutes.value + 1);">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button mat-icon-button [disabled]="minutesInputElement.value == '00'"
            (click)="timeForm.controls.minutes.setValue(+timeForm.controls.minutes.value - 1);">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="type?.includes('Full')" class="atp-date-time-picker__time-item">
        <mat-form-field floatLabel="always">
          <mat-label>Секунды</mat-label>
          <input #secondsInputElement matInput autocomplete="off" [textMask]="{mask: masks.seconds}"
            formControlName="seconds">
        </mat-form-field>
        <div class="atp-date-time-picker__time-item-actions">
          <button mat-icon-button [disabled]="secondsInputElement.value == '59'"
            (click)="timeForm.controls.seconds.setValue(+timeForm.controls.seconds.value + 1);">
            <mat-icon>keyboard_arrow_up</mat-icon>
          </button>
          <button mat-icon-button [disabled]="secondsInputElement.value == '00'"
            (click)="timeForm.controls.seconds.setValue(+timeForm.controls.seconds.value - 1);">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-template>

</div>

<div *ngIf="close" class="atp-date-time-picker__close-container" (click)="close()">
</div>