import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtpDialogContainerComponent } from './atp-dialog-container.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatCommonModule } from '@angular/material/core';
import { AtpDialogService } from './atp-dialog.service';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        PortalModule,
        MatCommonModule,
        MatIconModule,
    ],
  exports: [
    AtpDialogContainerComponent
  ],
  declarations: [
    AtpDialogContainerComponent
  ],
  providers: [
    AtpDialogService
  ]
})
export class AtpDialogModule { }
