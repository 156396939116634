import { Directive, ElementRef, EventEmitter, AfterContentChecked, OnDestroy } from '@angular/core';
import { EventArgs } from '../../atp-core';

@Directive({
  selector: '[atpSection]'
})
export class AtpSectionDirective implements AfterContentChecked, OnDestroy {

  constructor(private _elementRef: ElementRef<HTMLElement>) {
    this.height = this._elementRef.nativeElement.offsetHeight;
  }

  height = 0;
  heightChanged = new EventEmitter<EventArgs<AtpSectionDirective, number>>();

  width = 0;
  widhtChanged = new EventEmitter<EventArgs<AtpSectionDirective, number>>();

  get htmlElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  ngAfterContentChecked(): void {
    if (this.height != this._elementRef.nativeElement.offsetHeight) {
      this.height = this._elementRef.nativeElement.offsetHeight;
      this.heightChanged.next(new EventArgs(this, this.height));
    }
    if (this.width != this._elementRef.nativeElement.offsetWidth) {
      this.width = this._elementRef.nativeElement.offsetWidth;
      this.widhtChanged.next(new EventArgs(this, this.width));
    }
  }

  ngOnDestroy() {
    this.heightChanged.unsubscribe();
    this.widhtChanged.unsubscribe();
  }

}
