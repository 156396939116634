<form [formGroup]="form" (ngSubmit)="filtrationClick()" novalidate>
  <div #searchElement class="atp-filters-panel__search">
    <div class="atp-filters-panel__search-icon-container">
      <mat-icon>search</mat-icon>
    </div>
    <div class="atp-filters-panel__search-input-container" *ngIf="search">
      <mat-chip-list class="atp-filters-panel__search-chips" *ngIf="chips?.length">
        <mat-chip class="atp-filters-panel__search-chip" *ngFor="let chip of chips">
          <span class="atp-filters-panel__search-chip-text">
            {{chip.display}}
          </span>
          <mat-icon matChipRemove (click)="chipRemoveClick(chip)">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <input class="atp-filters-panel__search-input" placeholder="Поиск" *ngIf="search.mask; else masksElseBlock"
        [textMask]="{mask: search.mask, guide: false, keepCharPositions: true}" [formControlName]="search.name"
        [name]="search.name">
      <ng-template #masksElseBlock>
        <input class="atp-filters-panel__search-input" placeholder="Поиск" [formControlName]="search.name"
          [name]="search.name">
      </ng-template>
      <div class="atp-filters-panel__search-input-clear" *ngIf="form.controls[search.name].value"
        (click)="form.controls[search.name].setValue('')">Сбросить
      </div>
    </div>
    <button mat-button color="primary" type="button" class="atp-filters-panel__search-button"
      (click)="filtersPopupOpen(searchElement)">
      <mat-icon>filter_list</mat-icon>
      Фильтр
    </button>
  </div>
</form>

<ng-template #filtersPopupTemplate>
  <div class="atp-filters-panel__filters-close-container" (click)="filtersPopupOverlayRef.detach()"></div>
  <div class="atp-filters-panel__filters">
    <atp-filters-panel-presets [name]="name" [formGroup]="form" [(isEditMode)]="isPresetsEditMode"
      [(isAddMode)]="isPresetsAddMode" (itemClick)="fillValues($event, true)" [controls]="items">
    </atp-filters-panel-presets>

    <div class="atp-filters-panel__filters-body">
      <atp-filters-panel-controls
        [controls]="controls"
        [formGroup]="form"
        [autocompletes]="autocompletes"
      >
      </atp-filters-panel-controls>

      <div class="atp-filters-panel__filters-actions">
        <div class="atp-filters-panel__filters-actions-line atp-filters-panel__filters-actions-line_fields">
          <div #addFieldElement
            class="atp-filters-panel__filters-actions-button atp-filters-panel__filters-actions-button_add"
            (click)="addFields(addFieldElement)">
            Добавить поле</div>
          <div class="atp-filters-panel__filters-actions-button atp-filters-panel__filters-actions-button_clear">
            Вернуть поля по умолчанию</div>
        </div>
        <div class="atp-filters-panel__filters-actions-line"
          *ngIf="!isPresetsEditMode && !isPresetsAddMode; else elseModesBlock">
          <button mat-button type="button" class="atp-filters-panel__filters-actions-button"
            (click)="resetClick()">Сбросить</button>
          <button mat-raised-button color="primary" type="button" class="atp-filters-panel__filters-actions-button"
            (click)="filtrationClick()">Отфильтровать</button>
        </div>
        <ng-template #elseModesBlock>
          <div class="atp-filters-panel__filters-actions-line">
            <button mat-button type="button" class="atp-filters-panel__filters-actions-button"
              (click)="presetsModeCancel()">Отменить</button>
            <button mat-raised-button color="primary" type="button" class="atp-filters-panel__filters-actions-button"
              (click)="presetsModeConfirm()">Сохранить</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addFieldPopupTemplate>
  <div class="atp-filters-panel__add-fields-close-container" (click)="addFieldPopupOverlayRef.detach()"></div>
  <div class="atp-filters-panel__add-fields-popup">
    <div class="atp-filters-panel__add-fields-popup-container" [style]="addFieldsColumns">
      <ng-container *ngFor="let item of items">
        <mat-checkbox color="primary" *ngIf="!item.isSearch" [checked]="item.isVisible"
          (change)="addFieldCheckedChange(item, $event.checked)">
          {{item.display}}</mat-checkbox>
      </ng-container>
    </div>
  </div>
</ng-template>
