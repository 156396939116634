import { OverlayRef, GlobalPositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal, BasePortalOutlet, CdkPortalOutlet } from '@angular/cdk/portal';
import { Location, DOCUMENT } from '@angular/common';
import { Inject, Component, ComponentRef, ElementRef, EmbeddedViewRef, EventEmitter, ChangeDetectorRef, ViewChild, ViewEncapsulation, ChangeDetectionStrategy, Optional } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ESCAPE } from '@angular/cdk/keycodes';
import { filter, take } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';
import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import { DialogPosition } from '@angular/material/dialog';
import { AtpDialogConfig } from './atp-dialog-config';
import { atpDialogAnimations } from '../../atp-animations';

let uniqueId = 0;

export class AtpDialogRef<T, R = any> {
  componentInstance: T;
  disableClose: boolean | undefined = this._containerInstance._config.disableClose;
  private readonly _afterOpened = new Subject<void>();
  private readonly _afterClosed = new Subject<R | undefined>();
  private readonly _beforeClosed = new Subject<R | undefined>();
  private _result: R | undefined;

  constructor(
    private _overlayRef: OverlayRef,
    public _containerInstance: AtpDialogContainerComponent,
    _location?: Location,
    readonly id: string = `mat-dialog-${uniqueId++}`) {

    _containerInstance.onClose.subscribe(()=>{
      this.close();
    });

    _containerInstance._id = id;

    _containerInstance._animationStateChanged.pipe(
      filter(event => event.phaseName === 'done' && event.toState === 'enter'),
      take(1)
    )
      .subscribe(() => {
        this._afterOpened.next();
        this._afterOpened.complete();
      });

    _containerInstance._animationStateChanged.pipe(
      filter(event => event.phaseName === 'done' && event.toState === 'exit'),
      take(1)
    ).subscribe(() => this._overlayRef.dispose());

    _overlayRef.detachments().subscribe(() => {
      this._beforeClosed.next(this._result);
      this._beforeClosed.complete();
      this._afterClosed.next(this._result);
      this._afterClosed.complete();
      this.componentInstance = null!;
      this._overlayRef.dispose();
    });

    _overlayRef.keydownEvents()
      .pipe(filter(event => event.keyCode === ESCAPE && !this.disableClose))
      .subscribe(() => this.close());
  }

  close(dialogResult?: R): void {
    this._result = dialogResult;

    this._containerInstance._animationStateChanged.pipe(
      filter(event => event.phaseName === 'start'),
      take(1)
    )
      .subscribe(() => {
        this._beforeClosed.next(dialogResult);
        this._beforeClosed.complete();
        this._overlayRef.detachBackdrop();
      });

    this._containerInstance._startExitAnimation();
  }

  afterOpened(): Observable<void> {
    return this._afterOpened.asObservable();
  }

  afterClosed(): Observable<R | undefined> {
    return this._afterClosed.asObservable();
  }

  beforeClosed(): Observable<R | undefined> {
    return this._beforeClosed.asObservable();
  }

  backdropClick(): Observable<MouseEvent> {
    return this._overlayRef.backdropClick();
  }

  keydownEvents(): Observable<KeyboardEvent> {
    return this._overlayRef.keydownEvents();
  }

  updatePosition(position?: DialogPosition): this {
    let strategy = this._getPositionStrategy();

    if (position && (position.left || position.right)) {
      position.left ? strategy.left(position.left) : strategy.right(position.right);
    } else {
      strategy.centerHorizontally();
    }

    if (position && (position.top || position.bottom)) {
      position.top ? strategy.top(position.top) : strategy.bottom(position.bottom);
    } else {
      strategy.centerVertically();
    }

    this._overlayRef.updatePosition();

    return this;
  }

  updateSize(width: string = '', height: string = ''): this {
    this._getPositionStrategy().width(width).height(height);
    this._overlayRef.updatePosition();
    return this;
  }

  addPanelClass(classes: string | string[]): this {
    this._overlayRef.addPanelClass(classes);
    return this;
  }

  removePanelClass(classes: string | string[]): this {
    this._overlayRef.removePanelClass(classes);
    return this;
  }

  afterOpen(): Observable<void> {
    return this.afterOpened();
  }

  beforeClose(): Observable<R | undefined> {
    return this.beforeClosed();
  }

  private _getPositionStrategy(): GlobalPositionStrategy {
    return this._overlayRef.getConfig().positionStrategy as GlobalPositionStrategy;
  }
}

export function throwMatDialogContentAlreadyAttachedError() {
  throw Error('Attempting to attach dialog content after content is already attached');
}

@Component({
  selector: 'atp-dialog-container',
  template: '<div ><div class="atp-dynamic-form__shit" (click)="close()">Закрыть<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">close</mat-icon></div><ng-template cdkPortalOutlet></ng-template></div>',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [atpDialogAnimations.dialogContainer],
  host: {
    'class': 'atp-dialog-container',
    'tabindex': '-1',
    'aria-modal': 'true',
    '[attr.id]': '_id',
    '[attr.role]': '_config.role',
    '[attr.aria-labelledby]': '_config.ariaLabel ? null : _ariaLabelledBy',
    '[attr.aria-label]': '_config.ariaLabel',
    '[attr.aria-describedby]': '_config.ariaDescribedBy || null',
    '[@dialogContainer]': '_state',
    '(@dialogContainer.start)': '_onAnimationStart($event)',
    '(@dialogContainer.done)': '_onAnimationDone($event)',
  },
})
export class AtpDialogContainerComponent extends BasePortalOutlet {

  // @ViewChild(CdkPortalOutlet, (/* TODO: add static flag */ <any>{ static: true })) _portalOutlet: CdkPortalOutlet;
  @ViewChild(CdkPortalOutlet, { static: true }) _portalOutlet: CdkPortalOutlet;

  private _focusTrap: FocusTrap;
  private _elementFocusedBeforeDialogWasOpened: HTMLElement | null = null;
  _state: 'void' | 'enter' | 'exit' = 'enter';
  _animationStateChanged = new EventEmitter<AnimationEvent>();
  _ariaLabelledBy: string | null;
  _id: string;
  onClose = new EventEmitter<boolean>();

  constructor(
    private _elementRef: ElementRef,
    private _focusTrapFactory: FocusTrapFactory,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(DOCUMENT) private _document: any,
    public _config: AtpDialogConfig
    ) {

    super();
    this._ariaLabelledBy = _config.ariaLabelledBy || null;

  }

  close(){
    this.onClose.emit(true);
  }

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    if (this._portalOutlet.hasAttached()) {
      throwMatDialogContentAlreadyAttachedError();
    }

    this._savePreviouslyFocusedElement();
    return this._portalOutlet.attachComponentPortal(portal);
  }

  attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
    if (this._portalOutlet.hasAttached()) {
      throwMatDialogContentAlreadyAttachedError();
    }

    this._savePreviouslyFocusedElement();
    return this._portalOutlet.attachTemplatePortal(portal);
  }

  private _trapFocus() {
    if (!this._focusTrap) {
      this._focusTrap = this._focusTrapFactory.create(this._elementRef.nativeElement);
    }

    if (this._config.autoFocus) {
      this._focusTrap.focusInitialElementWhenReady();
    }
  }

  private _restoreFocus() {
    const toFocus = this._elementFocusedBeforeDialogWasOpened;

    if (this._config.restoreFocus && toFocus && typeof toFocus.focus === 'function') {
      toFocus.focus();
    }

    if (this._focusTrap) {
      this._focusTrap.destroy();
    }
  }

  private _savePreviouslyFocusedElement() {
    if (this._document) {
      this._elementFocusedBeforeDialogWasOpened = this._document.activeElement as HTMLElement;

      if (this._elementRef.nativeElement.focus) {
        Promise.resolve().then(() => this._elementRef.nativeElement.focus());
      }
    }
  }

  _onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'enter') {
      this._trapFocus();
    } else if (event.toState === 'exit') {
      this._restoreFocus();
    }

    this._animationStateChanged.emit(event);
  }

  _onAnimationStart(event: AnimationEvent) {
    this._animationStateChanged.emit(event);
  }

  _startExitAnimation(): void {
    this._state = 'exit';
    this._changeDetectorRef.markForCheck();
  }

}
