import { Type } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AtpHttpService, IAtpUser } from './atp-http.service';
import { ComponentType } from '@angular/cdk/portal';
import { IAtpBasePageComponent } from '../atp-base-page-component';
import { AppInjector } from '../atp-core';
import { AtpActionsPopup, AtpActionsPopupData } from '../components/atp-actions-popup/atp-actions-popup.component';
import { Observable } from 'rxjs';

export function AtpHttpErrorsServicePopupType(actionPopupType: ComponentType<{}>): any {
  return (target: any): any => {
    target.__atpannotations__ = {};
    target.__atpannotations__.actionPopupType = actionPopupType;
    return target;
  }
}

export class AtpHttpErrorHandler {
  constructor(public statusCode: number, public message: string | string[], public repeatBtnTitle: string = 'Повторить', public closeBtnTitle: string = 'Закрыть') { }
}

export abstract class AtpHttpErrorsService {
  protected dialog: MatDialog = AppInjector.get(MatDialog);
  protected router: Router = AppInjector.get(Router);

  constructor(protected api: AtpHttpService<IAtpUser>, protected location: Location) {
  }

  handler0 = new AtpHttpErrorHandler(0, 'Сервер не отвечает, проверьте подключение к интернету.');

  abstract moveToAuth(): void;

  // private _reauthObservable: Observable<IAtpUser>;
  // public reauth(repeatFunc: () => void, component: IAtpBasePageComponent) {
  //   if (this._reauthObservable) {
  //     const subscription = this._reauthObservable.subscribe(() => {
  //       repeatFunc();
  //       subscription.unsubscribe();
  //     });

  //     return;
  //   }

  //   if (component) {
  //     component.isBlockedPage = true;
  //   }

  //   this._reauthObservable = this.api.reauth();

  //   if (!this._reauthObservable) {
  //     if (component) {
  //       component.isBlockedPage = false;
  //     }
  //     this.moveToAuth();
  //   }
  //   else {
  //     const subscription = this._reauthObservable.subscribe(
  //       (result: any) => {
  //         this.api.user = result;

  //         if (component) {
  //           component.isBlockedPage = false;
  //         }

  //         if (repeatFunc) {
  //           repeatFunc();
  //         }

  //         subscription.unsubscribe();
  //         this._reauthObservable = null;
  //       },
  //       (err) => {
  //         if (component) {
  //           component.isBlockedPage = false;
  //         }

  //         if (err.error == 'move to auth') {
  //           this.api.logout();
  //           this.moveToAuth();
  //           return;
  //         }

  //         subscription.unsubscribe();
  //         this._reauthObservable = null;
  //         this.process(err.status, () => { this.reauth(repeatFunc, component); }, component, [new AtpHttpErrorHandler(400, err.error)]);
  //       }
  //     );
  //   }
  // }

  private _isReauthProcces = false;
  public reauth(repeatFunc: () => void, component: IAtpBasePageComponent) {
    if (this._isReauthProcces) {
      setTimeout(() => {
        repeatFunc();
      }, 100);

      return;
    }
    this._isReauthProcces = true;
    if (component) component.isBlockedPage = true;
    let observable = this.api.reauth();
    if (!observable) {
      this._isReauthProcces = false;
      if (component) component.isBlockedPage = false;
      this.moveToAuth();
    }
    else {
      observable.subscribe(
        (result: any) => {
          this.api.user = result;

          this._isReauthProcces = false;
          if (component) component.isBlockedPage = false;

          if (repeatFunc) repeatFunc();
        },
        err => {
          this._isReauthProcces = false;
          if (component) component.isBlockedPage = false;
          if (err.error == 'move to auth') {
            this.api.logout();
            this.moveToAuth();
            return;
          }

          this.process(err.status, () => { this.reauth(repeatFunc, component); }, component, [new AtpHttpErrorHandler(400, err.error)]);
        }
      );
    }
  }

  protected getEntryComponent(): Type<{}> | ComponentType<{}> {
    let result: ComponentType<{}> = (<any>this.constructor).__atpannotations__ ? (<any>this.constructor).__atpannotations__.actionPopupType : null;
    result = !result ? AtpActionsPopup : result;
    return result;
  }

  protected dialogActionData: MatDialogConfig<any> = { width: '450px' };

  process(statusCode: number, repeatFunc?: () => void, component?: IAtpBasePageComponent, handlers: AtpHttpErrorHandler[] = [], prevUrlFor404?: string) {
    if (statusCode == 401) {
      if (component) {
        (<any>component)._countStarted--;
      }
      this.reauth(repeatFunc, component);

      return;
    }
    else if (statusCode == 404 || statusCode == 200) {
      // Подмена текущего роута
      if (prevUrlFor404 !== undefined && prevUrlFor404 !== null) {
        this.location.replaceState(prevUrlFor404);
        this.router.navigate(['error']);

        return;
      }
    }

    if (!handlers) handlers = [];
    handlers.push(this.handler0);

    let error = handlers.find(x => x.statusCode == statusCode);
    let data: AtpActionsPopupData;
    if (error) {
      data = new AtpActionsPopupData(typeof (error.message) == 'string' ? 'Ошибка' : 'Ошибки', error.message, error.repeatBtnTitle, error.closeBtnTitle);
    }
    else {
      data = new AtpActionsPopupData('Ошибка', 'Неизвестная ошибка', null);
    }

    if (component) {
      component.isBlockedPage = false;
    }

    this.dialogActionData.data = data;

    this.dialog.open(this.getEntryComponent(), this.dialogActionData).afterClosed().subscribe(
      (result: boolean) => {
        if (result && data.successBtnTitle) {
          if (repeatFunc) repeatFunc();
        };
      }
    );
  }
}