<div class="atp-notification__data" (click)="action()">
  <div class="atp-notification__header">
    <div class="atp-notification__header-text">{{data.data.title}}</div>
  </div>
  <div *ngIf="data">
    <ng-container *ngFor="let span of spans; let i = index">
      <span [innerText]="span"></span>
      <a *ngIf="links.length > i" [routerLink]="links[i]">{{linkNames[i]}}</a>
    </ng-container>
  </div>
  <div class="atp-notification__date" *ngIf="data.data.date">
    {{data.data.date | format: 'DateTime'}}
  </div>
</div>
