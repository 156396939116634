<div class="atp-upload-file-item__main">
  <div class="atp-upload-file-item__link" [style.cursor]="data.date ? 'pointer' : 'default'" (click)="openFile()">
    <span class="atp-upload-file-item__name">{{data.file ? data.file.name : data.name}}</span>
    <span class="atp-upload-file-item__size">
      {{convertSize(data.file ? data.file.size : data.size)}}
    </span>
  </div>
  <button *ngIf="!disabled" mat-icon-button type="button" color="warn" class="atp-upload-file-item__remove-button"
    (click)="remove.next(data)">
    <mat-icon>clear</mat-icon>
  </button>
</div>
<ng-container *ngIf="!data.errorName; else elseErrorMessage">
  <div class="atp-upload-file-item__progress-bar" *ngIf="progressBarValue || data.isUploaded; else elseDate">
    <mat-progress-bar mode="determinate" [value]="progressBarValue? (progressBarValue | async) : 100">
    </mat-progress-bar>
  </div>
  <ng-template #elseDate>
    <div class="atp-upload-file-item__other-info">
      <span class="atp-upload-file-item__date">
        {{datetimeService.dateToFormatString(data.date, 'DateTime')}}
      </span>
    </div>
  </ng-template>
</ng-container>
<ng-template #elseErrorMessage>
  <div class="atp-upload-file-item__other-info">
    <span class="atp-upload-file-item__other-info">
      {{errors[data.errorName + 'Error']}}
    </span>
  </div>
</ng-template>