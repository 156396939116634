import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtpRangeDateTimePickerComponent, AtpDateTimePickerDayCalendar, AtpDateTimePickerMonthCalendar, AtpDateTimePickerYearCalendar, AtpDateTimePickerPopup } from './atp-range-date-time-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PortalModule } from '@angular/cdk/portal';
import { AtpDateTimeService } from './atp-date-time.service';
import { TextMaskModule } from 'angular2-text-mask';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AtpDateTimePickerDayCalendar,
    AtpDateTimePickerMonthCalendar,
    AtpDateTimePickerYearCalendar,
    AtpDateTimePickerPopup,
    AtpRangeDateTimePickerComponent
  ],
  exports: [
    AtpRangeDateTimePickerComponent,
  ],
  imports: [
    CommonModule,
    PortalModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    TextMaskModule
  ],
  providers: [
    AtpDateTimeService
  ]
})
export class AtpRangeDateTimePickerModule { }
