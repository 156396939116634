<ng-container [formGroup]="formGroup">
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_multiple-select"
    appearance="outline">
    <mat-label>Выходные дни</mat-label>
    <mat-select multiple formControlName="weekendIds" required>
      <mat-option *ngFor="let option of data?.daysOfWeek" [value]="option.id">
        {{option.value}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="errorMessageWeekendIds">{{errorMessageWeekendIds}}</mat-error>
  </mat-form-field>
  <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_range-date-time"
    appearance="outline">
    <mat-label>Рабочее время</mat-label>
    <atp-range-date-time-picker type="RangeTime" formControlName="workTime"></atp-range-date-time-picker>
    <mat-error *ngIf="getErrorMessageWorkTime()">{{getErrorMessageWorkTime()}}</mat-error>
  </mat-form-field>
  <div *ngFor="let exception of exceptionsFormArray.controls; let i = index" [formGroup]="exception">
    <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_select" appearance="outline">
      <mat-label>Тип</mat-label>
      <mat-select formControlName="typeId">
        <mat-option *ngFor="let option of this.exceptionDayTypes" [value]="option.id">
          {{option.value}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="atp-dynamic-form__form-field atp-dynamic-form__form-field_range-date-time" appearance="outline">
      <mat-label>Дата</mat-label>
      <atp-range-date-time-picker type="Date" formControlName="day"></atp-range-date-time-picker>
    </mat-form-field>
    <mat-form-field
      *ngIf="exception['controls']['typeId'].value == 0"
      class="atp-dynamic-form__form-field atp-dynamic-form__form-field_range-date-time" appearance="outline">
      <mat-label>Рабочее время</mat-label>
      <atp-range-date-time-picker type="RangeTime" formControlName="workTime"></atp-range-date-time-picker>
    </mat-form-field>
    <button mat-raised-button class="working-days__button" type="button" color="warn"
            (click)="remove(i)">Удалить</button>
  </div>
</ng-container>

<button *ngIf="this.data?.exceptionDayTypes" mat-stroked-button class="working-days__button" type="button"
  color="primary" (click)="add()">
  <mat-icon>add</mat-icon>
  Добавить исключение
</button>
